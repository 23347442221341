import { COOKIE_ACTION, LOGIN_SUCCESS, LOGOUT_SUCCESS } from "../ActionTypes";

const auth = (state, { type, payload }) => {
    console.log("updatingLocalStorage")
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                isLogin: true,
            };
        case COOKIE_ACTION:
            return {
                ...state,
                ...payload,
            };
    }
};

export default auth;
