import { Table } from "react-bootstrap"

export const CookiePolicyEN = () => {
    return (
        <>
            <section className="privacy-policy pb-5 mb-3">
                <div className="container">
                    <h2 className="term-heading text-center mt-lg-4 mt-2 text-capitalize">
                        LYBER COOKIES POLICY
                    </h2>
                    <br />
                    <br />
                    <br />
                    <p>
                        Lyber is committed to providing you with the best possible service, and we aim to continuously enhance your user experience by improving your browsing experience and making the site more user-friendly and attractive.
                    </p>
                    <p>
                        Our site may use and allow third parties to place cookies on our site that may collect and store certain information about you.
                    </p>
                    <p>
                        Some of these cookies are necessary to provide, secure, and maintain the basic functions of the site, such as keeping you logged in during your visit to our site, and especially cookies used to offer you a better user experience.
                    </p>
                    <br />
                    <br />
                    <br />
                    <h3 className="mt-lg-4 mt-2 text-capitalize">
                        DEFINITION
                    </h3>
                    <br />
                    <p>
                        The CNIL (National Commission on Informatics and Liberty) defines a cookie as "a small file stored by a server on the user's terminal (computer, phone, etc.) and associated with a web domain (i.e., in most cases, to all pages of the same website). This file is automatically sent back during subsequent contacts with the same domain."
                    </p>
                    <br />
                    <br />
                    <br />
                    <h3 className="mt-lg-4 mt-2 text-capitalize">
                        TYPES OF COOKIES USED BY THE WEBSITE LYBER
                    </h3>
                    <br />
                    <ul>
                        <li>
                            <span style={{ textDecoration: "underline" }}>Strictly Necessary Cookies:</span> These are trackers strictly necessary for providing an online communication service expressly requested by the user or trackers that aim to enable or facilitate the transmission of communication by electronic means.
                        </li>
                        <br />
                        <li>
                            <span style={{ textDecoration: "underline" }}>Performance Cookies:</span> Collect information on how the site is used so that we can improve it.
                        </li>
                        <br />
                        <li>
                            <span style={{ textDecoration: "underline" }}>Functionality Cookies:</span> Allow us to remember the choices you make (such as your preferred language, country, or other online settings) and provide you with personalised or enhanced features of your choice.
                        </li>
                        <br />
                        <li>
                            <span style={{ textDecoration: "underline" }}>Social Media Cookies:</span> Provide social media sharing modules.
                        </li>
                    </ul>
                    <br />
                    <br />
                    <br />
                    <Table hover striped className="roundedTable mb-5">
                        <thead>
                            <tr>
                                <th width="25%" className="purple-light">Name</th>
                                <th width="25%" className="purple-light">Purpose</th>
                                <th width="25%" className="purple-light">Usage</th>
                                <th width="25%" className="purple-light">Storage Duration</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            <tr>
                                <td className="light-black"><b>_gcl_au</b></td>
                                <td className="light-black">User activity tracking on the website</td>
                                <td className="light-black">Distinguishes unique users and tracks interactions</td>
                                <td className="light-black">90 days</td>
                            </tr>
                            <tr>
                                <td className="light-black"><b>Appsflyer SDK (not a cookie)</b></td>
                                <td className="light-black">User activity tracking on the application</td>
                                <td className="light-black">Differentiates unique users and tracks interactions</td>
                                <td className="light-black">-</td>
                            </tr>
                            <tr>
                                <td className="light-black"><b>_fbp</b></td>
                                <td className="light-black">User activity tracking on the website</td>
                                <td className="light-black">Differentiates unique users and tracks interactions</td>
                                <td className="light-black">3 months</td>
                            </tr>
                            <tr>
                                <td className="light-black"><b>cf_clearance</b></td>
                                <td className="light-black">Strictly necessary cookies</td>
                                <td className="light-black">Stores proof that the challenge has been met</td>
                                <td className="light-black">12 months</td>
                            </tr>
                        </tbody>
                    </Table>
                    <br />
                    <br />
                    <br />
                    <h3 className="mt-lg-4 mt-2 text-capitalize">
                        CONSENT
                    </h3>
                    <br />
                    <p>You have the option to accept or refuse LYBER's cookie management policy through cookie management settings at any time during your navigation on the lyber.com website.</p>
                    <p>Upon your first visit, an informative banner will prompt you to "accept," "refuse," or "withdraw" the use of certain cookies.</p>
                    <p>By refusing some cookies, we cannot guarantee the best possible user experience.</p>
                    <br />
                    <br />
                    <br />
                    <h3 className="mt-lg-4 mt-2 text-capitalize">
                        HOW TO REMOVE OR MANAGE CERTAIN COOKIES
                    </h3>
                    <br />
                    <p>It is possible to remove or manage cookies through browser settings. However, note that some features may not work optimally.</p>
                    <p>Each browser's configuration is different:</p>
                    <ul>
                        <li>Safari: <a href="https://support.apple.com/fr-fr/safari">https://support.apple.com/fr-fr/safari</a></li>
                        <li>Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en">https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en</a></li>
                        <li>Mozilla: <a href="https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent">https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent</a></li>
                        <li>Microsoft Edge: <a href="https://support.microsoft.com/fr-fr/topic/afficher-les-cookies-dans-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879">https://support.microsoft.com/fr-fr/topic/afficher-les-cookies-dans-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879</a></li>
                        <li>Opera: <a href="https://help.opera.com/en/latest/web-preferences/#cookies">https://help.opera.com/en/latest/web-preferences/#cookies</a></li>
                        <li>Brave: <a href="https://brave.com/fr/privacy-updates/21-blocking-cookie-notices/">https://brave.com/fr/privacy-updates/21-blocking-cookie-notices/</a></li>
                    </ul>
                    <br />
                    <br />
                    <br />
                    <h3 className="mt-lg-4 mt-2 text-capitalize">
                        FURTHER INFORMATION
                    </h3>
                    <br />
                    <p>We encourage you to review the CNIL's advice on browser control <a href="https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur">here.</a></p>
                    <p>Additionally, please familiarize yourself with your rights regarding data protection in our <a href="https://www.lyber.com/privacy"></a>privacy policy.</p>
                    <p>LYBER SAS has appointed a Data Protection Officer (DPO). If you have questions regarding LYBER's use of your personal data as described in this privacy policy or if you wish to exercise your rights or inquire about cookie management, please contact our DPO at the email address: <a href="mailto:dpo@lyber.com">dpo@lyber.com</a></p>
                    <br />
                    <br />
                    <br />
                    <h3 className="mt-lg-4 mt-2 text-capitalize">
                        MODIFICATION OF COOKIE MANAGEMENT POLICY
                    </h3>
                    <br />
                    <p>These conditions replace any information previously provided regarding our cookie management practices. They are not intended to replace any other agreement or conditions you may have with us as part of a specific agreement.</p>
                    <p>We reserve the right to modify these conditions at any time.</p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                </div>
            </section>
        </>
    );
};