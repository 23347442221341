// src/pages/WalletConnectPage.js

import React, { useState, useContext, useEffect } from 'react';
import { BrowserProvider, Contract, parseEther } from 'ethers';
import contractJSON from "./contract/CliffAndVesting.json";
import language_contant from "../../utils/language_contant";
import { GlobalContext } from "../../context/Provider";

const WalletConnectPage = () => {
    const { authState } = useContext(GlobalContext);
    language_contant.setLang(authState.lang);

    const metamaskInstallURL = "https://metamask.io/download.html";
    const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    const contractABI = contractJSON.abi;

    const [isConnected, setIsConnected] = useState(false);
    const [connectedAddress, setConnectedAddress] = useState("");
    const [releasableTokens, setReleasableTokens] = useState([0, 0]);
    const [vestingScheduleIds, setVestingScheduleIds] = useState(["", ""]);
    const [loading, setLoading] = useState(false);

    const [checkboxes, setCheckboxes] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
    });

    const specialAddresses = [
        "0x0E121e38C7454079075cDa7998b3695414373809",
        "0x6D15eBe2f500c6e35C5bc584c4B6331462852da7"
    ];

    useEffect(() => {
        const storedAddress = localStorage.getItem('connectedAddress');
        if (storedAddress) {
            setConnectedAddress(storedAddress);
            setIsConnected(true);
            connectWallet(true);
        }
    }, []);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxes(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const handleContractFunction = async (index) => {
        if (!window.ethereum) {
            setIsConnected(false);
            alert('Please connect to MetaMask first.');
            return;
        }

        const provider = new BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const contract = new Contract(contractAddress, contractABI, signer);

        try {
            if (releasableTokens[index] <= 0) {
                alert("No tokens available for release.");
                return;
            }
            setLoading(true);

            const tx = await contract.release(
                vestingScheduleIds[index],
                parseEther(releasableTokens[index].toString()),
                { gasLimit: 1000000 }
            );
            await tx.wait();

            const releasable = await contract.computeReleasableAmount(vestingScheduleIds[index]);
            setReleasableTokens(prevTokens => {
                const newTokens = [...prevTokens];
                newTokens[index] = Number(releasable.toString()) / (10 ** 18);
                return newTokens;
            });
        } catch (error) {
            console.error("Error calling contract function", error);
            alert(`Error calling contract function: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const fetchVestingDetails = async (address, signer) => {
        const contract = new Contract(contractAddress, contractABI, signer);
        try {
            const scheduleIds = await Promise.all([0, 1].map(async index => {
                return await contract.computeVestingScheduleIdForAddressAndIndex(address, index);
            }));
            setVestingScheduleIds(scheduleIds);

            const releasableAmounts = await Promise.all(scheduleIds.map(async id => {
                const releasable = await contract.computeReleasableAmount(id);
                return Number(releasable.toString()) / (10 ** 18);
            }));
            setReleasableTokens(releasableAmounts);
        } catch (error) {
            console.error("Error computing vesting schedule or releasable amount", error);
            alert(`Error fetching vesting schedule or releasable amount: ${error.message}`);
        }
    };

    const connectWallet = async (fromStorage = false) => {
        if (!window.ethereum) {
            alert('Please install MetaMask!');
            window.location.href = metamaskInstallURL;
            return;
        }

        try {
            const provider = new BrowserProvider(window.ethereum);
            if (!fromStorage) {
                await provider.send("eth_requestAccounts", []);
                console.log('Wallet connected!');
            }

            setIsConnected(true);
            const signer = await provider.getSigner();
            const address = await signer.getAddress();
            setConnectedAddress(address);
            localStorage.setItem('connectedAddress', address);

            setLoading(true);
            await fetchVestingDetails(address, signer);
        } catch (error) {
            console.error("Failed to connect wallet", error);
            setIsConnected(false);
            alert(`Failed to connect wallet: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const disconnectWallet = () => {
        localStorage.removeItem('connectedAddress');
        setIsConnected(false);
        setConnectedAddress("");
        setReleasableTokens([0, 0]);
        setVestingScheduleIds(["", ""]);
        // Force MetaMask to forget the connected account
        window.location.reload();
    };

    const renderButtons = () => {
        if (specialAddresses.includes(connectedAddress)) {
            return (
                <>
                    <p>{language_contant.Lyb.claimableToken()} {releasableTokens[0]} LYB</p>
                    <button className="btn btn-primary text-nowrap" onClick={() => handleContractFunction(0)} disabled={loading}>
                        {loading ? 'Processing...' : language_contant.Lyb.claimMyTokens()} 1
                    </button>
                    <p className='mt-3'>{language_contant.Lyb.claimableToken()} {releasableTokens[1]} LYB</p>
                    <button className="btn btn-primary text-nowrap" onClick={() => handleContractFunction(1)} disabled={loading}>
                        {loading ? 'Processing...' : language_contant.Lyb.claimMyTokens()} 2
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <p>{language_contant.Lyb.claimableToken()} {releasableTokens[0]} LYB</p>
                    <button className="btn btn-primary text-nowrap" onClick={() => handleContractFunction(0)} disabled={loading}>
                        {loading ? 'Processing...' : language_contant.Lyb.claimMyTokens()}
                    </button>
                </>
            );
        }
    };

    const allCheckboxesChecked = Object.values(checkboxes).every(Boolean);

    return (
        <section className="section-spacing home-banner lyb-claim">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <h1>{language_contant.Lyb.lyberClaimToken()}</h1>
                            <div>
                                {!isConnected ? (
                                    <>
                                        <p>{language_contant.Lyb.connectYourEthereumWallet()}</p>

                                        <button className="btn btn-primary text-nowrap mt-2" onClick={connectWallet} disabled={loading || !allCheckboxesChecked}>
                                            {loading ? 'Connecting...' : language_contant.Lyb.connectWallet()}
                                        </button>
                                        <div className='mt-4 d-flex row gap-4'>
                                            <div className='d-flex gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name="checkbox1"
                                                    checked={checkboxes.checkbox1}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label>{language_contant.Lyb.checkboxLabel1()}</label>
                                            </div>
                                            <div className='d-flex gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name="checkbox2"
                                                    checked={checkboxes.checkbox2}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label>{language_contant.Lyb.checkboxLabel2()}</label>
                                            </div>
                                            <div className='d-flex gap-2'>
                                                <input
                                                    type="checkbox"
                                                    name="checkbox3"
                                                    checked={checkboxes.checkbox3}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label>{language_contant.Lyb.checkboxLabel3()}</label>
                                            </div>
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        <p>{language_contant.Lyb.connectedAddress()} {connectedAddress}</p>
                                        {renderButtons()}
                                        <button className="btn btn-secondary text-nowrap mt-3" onClick={disconnectWallet} disabled={loading}>
                                            {loading ? 'Processing...' : language_contant.Lyb.disconnectWallet()}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WalletConnectPage;
