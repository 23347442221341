import Email from "../../assets/images/email.svg";
import { useContext } from "react";
import { GlobalContext } from "../../context/Provider";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Spinner } from "../../components/BootstrapCompo";
import ReactPixel from 'react-facebook-pixel';

export default () => {
    const { authState, authDispatch } = useContext(GlobalContext);

    if (authState.areCookiesAccepted === true) {
        if (authState.metaSliderState === true)
            ReactPixel.pageView()
        if (authState.googleSliderState === true)
            window.gtag('event', 'page_view');
    }

    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const newParam = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const handleUnsubscribe = async () => {
        try {
            throw "not implemented"
            // setLoading(true);
            // const res = await henceforthApi.Unsubscribe.preRegisterDelete(
            //     newParam.get("email"),
            //     newParam.get("language"),
            // );
            // setLoading(false);
            // navigate("/");
            // console.log("Unsubscribed", res);
        } catch (error) {
            setLoading(false);
            console.log("Getting Error While Unsubscribing", error);
        }
    };
    return (
        <div className="card-wrapper">
            <div className="emails-box">
                <img src={Email} className="img-fluid mb-3" />
                <h3>
                    {newParam.get("language") == "FRENCH"
                        ? "Nous sommes désolés de vous voir partir!"
                        : "We’re sad to see you go!"}
                </h3>
                <p>
                    {newParam.get("language") == "FRENCH"
                        ? "Cliquez sur le bouton en dessous pour confirmer votre désinscription. "
                        : " Click on the button below to confirm your unsubscription."}
                </p>
                <button
                    className="btn btn-primary mt-3 px-4"
                    onClick={handleUnsubscribe}
                // disabled={loading}
                >
                    {loading ? <Spinner /> : newParam.get("language") == "FRENCH" ? "Se désinscrire" : "Unsubscribe"}
                </button>
            </div>
        </div>
    );
};
