let lang = null;

const Home = {
    comingsoon: () => {
        switch (lang) {
            case "other":
                return "Bientôt disponible...";
            default:
                return "Coming soon...";
        }
    },
    eventialgif: () => {
        switch (lang) {
            case "other":
                return <h4 className="common-hfour">Un éventail de crypto-actifs</h4>;
            default:
                return <h1 className="common-hfour">A variety of digital assets</h1>;
        }
    },
    gifperra: () => {
        switch (lang) {
            case "other":
                return <p className="common-peraghar pt-sm-2">Échange tes crypto-actifs favoris en un seul clic.</p>;
            default:
                return <p className="common-peraghar pt-sm-2">Exchange your favorite cryptoassets in one click</p>;
        }
    },
    primeryTitles: () => {
        switch (lang) {
            case "other":
                return (
                    <h1 style={{ paddingBottom: "23px" }}>
                        La crypto à <br />portée de main.{" "}
                    </h1>
                );
            default:
                return (
                    <h1 style={{ paddingBottom: "23px" }}>Crypto at your fingertips. </h1>
                );
        }
    },
    commonPeraghar: () => {
        switch (lang) {
            case "other":
                return "Investissements en crypto simples, sécurisés et diversifiés.";

            default:
                return "Simple, secure and diversified crypto investments.";
        }
    },
    preRegister: () => {
        switch (lang) {
            case "other":
                return "Je m’inscris";

            default:
                return "Subscribe";
        }
    },
    preRegisters: () => {
        switch (lang) {
            case "other":
                return "Je m’inscris*";

            default:
                return "Subscribe*";
        }
    },
    download: () => {
        switch (lang) {
            case "other":
                return "Télécharger Lyber";

            default:
                return "Download Lyber";
        }
    },
    subscribeText: () => {
        switch (lang) {
            case "other":
                return "*Suivez-nous et inscrivez-vous à notre newsletter mensuelle.";

            default:
                return "*Join us and subscribe to our monthly newsletter.";
        }
    },
    emailIsRequired: () => {
        switch (lang) {
            case "other":
                return "L'adresse email est requise";

            default:
                return "Email is required";
        }
    },
    haveQuestion: () => {
        switch (lang) {
            case "other":
                return "Une question ?";

            default:
                return "Have a question?";
        }
    },
    mostAsked: () => {
        switch (lang) {
            case "other":
                return "Voici les plus fréquentes";

            default:
                return " Here are the most asked ones.";
        }
    },

    homeUpperSlider: () => {
        switch (lang) {
            case "other":
                return "Comment investir simplement avec l’application Lyber ?";

            default:
                return "How easy it is to start investing with the Lyber app?";
        }
    },
    cardText: () => {
        switch (lang) {
            case "other":
                return "Ils nous font confiance";

            default:
                return "They trust us";
        }
    },
    cardText2: () => {
        switch (lang) {
            case "other":
                return "Lyber, enregistré PSAN";

            default:
                return "Lyber is PSAN registered";
        }
    },
    cardTextPara: () => {
        switch (lang) {
            case "other":
                return (
                    <p>
                        Lyber est régulé en France sous le numéro PSAN{" "}
                        <a
                            href="https://www.amf-france.org/en/professionals/fintech/my-relations-amf/obtain-dasp-authorisation
                "
                            target="_blank"
                            className=""
                        >
                            #E2022-039
                        </a>
                        .
                    </p>
                );

            default:
                return (
                    <p>
                        Lyber is regulated in France under PSAN{" "}
                        <a
                            href="https://www.amf-france.org/en/professionals/fintech/my-relations-amf/obtain-dasp-authorisation
                "
                            target="_blank"
                            className=""
                        >
                            #E2022-039
                        </a>
                        .
                    </p>
                );
        }
    },
    wantMore: () => {
        switch (lang) {
            case "other":
                return (
                    <h2 className="main-title">
                        Vous en voulez plus ?<br />{" "}
                        <span className="small-text" style={{ color: "#cfcbd5" }}>
                            Lyber a ce qu'il vous faut.
                        </span>
                    </h2>
                );

            default:
                return (
                    <h2 className="main-title">
                        Want some more?
                        <br />
                        <span className="small-text" style={{ color: "#cfcbd5" }}>
                            {" "}
                            Lyber has what you want.
                        </span>
                    </h2>
                );
        }
    },

    startToInvest: () => {
        switch (lang) {
            case "other":
                return (
                    <h2 className="white-title newsletter-text">
                        Ne manquez pas <br />nos actualités.
                        <br />
                        <span className="text-light-purple">Inscrivez-vous à notre <br />newsletter !</span>
                    </h2>
                );

            default:
                return (
                    <h2 className="white-title newsletter-text">
                        Don't miss our <br />
                        latest news.{" "}<br />
                        <span className="text-light-purple">
                            Subscribe <br />
                            to our newsletter!
                        </span>{" "}
                    </h2>
                );
        }
    },
    buyUniqueToken: () => {
        switch (lang) {
            case "other":
                return (
                    <span>
                        Achetez des tokens
                        <br />
                        uniques
                    </span>
                );

            default:
                return "Buy unique tokens ";
        }
    },
    buyUniqueTokenPara: () => {
        switch (lang) {
            case "other":
                return (
                    <p>
                        Choisissez parmi plus de 20 actifs et
                        <br /> obtenez des tokens LYB afin de <br />
                        débloquer de nombreux{" "}
                        <a href="https://www.lyber.com/lyb" className="text-decoration-underline">
                            avantages
                        </a>
                        .
                    </p>
                );

            default:
                return (
                    <p>
                        Choose among 20 hand-picked assets
                        <br /> and/or stake LYB token to unlock{" "}
                        <a href="https://www.lyber.com/lyb" className="text-decoration-underline">
                            perks
                        </a>
                        .
                    </p>
                );
        }
    },
    tradeAsset: () => {
        switch (lang) {
            case "other":
                return "Échangez vos actifs préférés";

            default:
                return "Trade assests";
        }
    },
    tradeAssetPara: () => {
        switch (lang) {
            case "other":
                return <p>Crypto à Crypto ou Crypto à Fiat. </p>;

            default:
                return <p>From one to another, crypto to crypto or crypto to FIAT.</p>;
        }
    },
    buildOwnStrategy: () => {
        switch (lang) {
            case "other":
                return "Construisez votre propre stratégie";

            default:
                return "Build your own strategy";
        }
    },
    buildOwnStrategyPara: () => {
        switch (lang) {
            case "other":
                return (
                    <p>
                        Choisissez des actifs sur lesquels investir et ajustez leur
                        <br /> répartition pour répondre à vos besoins.
                    </p>
                );

            default:
                return (
                    <p>
                        Define which assets set you want to invest on and fine tune
                        <br /> each of their allocation.
                    </p>
                );
        }
    },
    topNotchSupport: () => {
        switch (lang) {
            case "other":
                return (
                    <span>
                        Support client
                        <br /> d’excellence
                    </span>
                );

            default:
                return "Top notch support";
        }
    },
    topNotchSupportPara: () => {
        switch (lang) {
            case "other":
                return (
                    <p>
                        Notre équipe est disponible 7 jours/7,
                        <br /> 24h/24 pour vous aider.
                    </p>
                );

            default:
                return (
                    <p>
                        Our support team is available 24/7 to <br /> provide you a no-hassle experience.
                    </p>
                );
        }
    },

    whatIsLyber: () => {
        switch (lang) {
            case "other":
                return "Qu’est-ce que Lyber ?";

            default:
                return "What is Lyber?";
        }
    },
    whatIsLyberAns: () => {
        switch (lang) {
            case "other":
                return "Lyber est la première application Web 3.0 française spécialisée dans les actifs numériques, permettant à ses utilisateurs d'investir, d'envoyer et de dépenser leurs actifs facilement.";

            default:
                return "Lyber is the first French Web 3.0 App specializing in digital assets, allowing its users to invest, send and spend their assets easily.";
        }
    },
    isLyberRegulated: () => {
        switch (lang) {
            case "other":
                return "Lyber est-elle réglementée ?";

            default:
                return "Is Lyber regulated?";
        }
    },
    isLyberRegulatedAns: () => {
        switch (lang) {
            case "other":
                return "Lyber se conforme aux lois et réglementations applicables sur les crypto-monnaies sur le territoire français.";

            default:
                return "Lyber complies with the laws and regulations applicable to crypto-currencies on French territory.";
        }
    },
    whyShouUseLyber: () => {
        switch (lang) {
            case "other":
                return "Pourquoi utiliser Lyber ?";

            default:
                return "Why should I use Lyber?";
        }
    },
    whyShouUseLyberAns: () => {
        switch (lang) {
            case "other":
                return "Lyber est le moyen le plus simple d’acquérir des actifs numériques en France. Étant réglementé par les autorités compétentes françaises, Lyber vous propose le niveau de sécurité le plus élevé du marché.";

            default:
                return "Lyber is the easiest way to acquire digital assets in France. Regulated by the competent French authorities, Lyber offers you the highest level of security on the market.";
        }
    },
    // whereLyberAvailable: () => {
    //     switch (lang) {
    //         case "other":
    //             return "Où puis-je utiliser Lyber ?";

    //         default:
    //             return "Where is Lyber available?";
    //     }
    // },
    // whereLyberAvailableAns: () => {
    //     switch (lang) {
    //         case "other":
    //             return "Nous prenons actuellement en charge les pays de l'Union européenne et du monde entier. Cependant, Lyber n’accepte pas et ne fournit pas de services aux comptes personnels des résidents actuels de Cuba, de Crimée et Sébastopol, d'Iran, d'Afghanistan, de Syrie, de Corée du Nord ou d'Antigua-et-Barbuda.";

    //         default:
    //             return "We currently support countries from the European Union and worldwide. However, Lyber does not onboard or provide services to personal accounts of current residents of Cuba, Crimea and Sevastopol, Iran, Afghanistan, Syria, North Korea, or Antigua and Barbuda.";
    //     }
    // },

    checkoutFaqPage: () => {
        switch (lang) {
            case "other":
                return (
                    <p>
                        Vous avez encore des questions ?{" "}
                        <span class="check-text">
                            Rendez-vous sur{" "}
                            <a href="/faq" className="text-theme text-underline">
                                {" "}
                                la page FAQ complète <i class="fa-solid fa-arrow-right-long ms-1"></i>
                            </a>
                        </span>
                    </p>
                );

            default:
                return (
                    <p>
                        Still have one?{" "}
                        <span class="check-text">
                            You can{" "}
                            <a href="/faq" className="text-theme check-faq-link">
                                <span>check our full FAQ page</span> <i class="fa-solid fa-arrow-right-long ms-1"></i>
                            </a>
                        </span>
                    </p>
                );
        }
    },

    step01: () => {
        switch (lang) {
            case "other":
                return "Etape 01";
            default:
                return "Step 01";
        }
    },

    step01Heading: () => {
        switch (lang) {
            case "other":
                return "Choisissez une stratégie.";
            default:
                return "Pick a strategy ";
        }
    },
    step01Para: () => {
        switch (lang) {
            case "other":
                return "En fonction de votre niveau de risque et de la répartition des actifs.";
            default:
                return "Depending on risk level and assets allocation.";
        }
    },
    step02: () => {
        switch (lang) {
            case "other":
                return "Etape 02";
            default:
                return "Step 02";
        }
    },

    step02Heading: () => {
        switch (lang) {
            case "other":
                return "Investir régulièrement";
            default:
                return "Invest frequently ";
        }
    },
    step02Para: () => {
        switch (lang) {
            case "other":
                return "Chaque jour, chaque semaine ou chaque mois, vous choisissez la fréquence.";
            default:
                return "Daily, weekly or monthly, you define the rythm.";
        }
    },

    step03: () => {
        switch (lang) {
            case "other":
                return "Etape 03";
            default:
                return "Step 03";
        }
    },

    step03Heading: () => {
        switch (lang) {
            case "other":
                return "Regardez votre portfolio évoluer !";
            default:
                return "Let your portfolio grow !";
        }
    },
    step03Para: () => {
        switch (lang) {
            case "other":
                return "Laissez votre argent fructifier pendant que vous profitez de la vie.";
            default:
                return "Let your money grow while you enjoy your life.";
        }
    },
    step04: () => {
        switch (lang) {
            case "other":
                return "Etape 04";
            default:
                return "Step 04";
        }
    },

    step04Heading: () => {
        switch (lang) {
            case "other":
                return "Gardez le contrôle";
            default:
                return "Stay in control";
        }
    },
    step04Para: () => {
        switch (lang) {
            case "other":
                return "Retirez de l’argent à tout moment. Pas de contrainte ou d’engagement annuel.";
            default:
                return "Withdraw money anytime. No lock-ins or annual commitments.";
        }
    },

    mobileeamil: () => {
        switch (lang) {
            case "other":
                return <h5>Se pré-inscrire</h5>;
            default:
                return <h5>Pre-register</h5>;
        }
    },
    sentemail: () => {
        switch (lang) {
            case "other":
                return "Un email vous sera envoyé lors de la sortie de l'application mobile.";
            default:
                return "An email will be sent to you when the mobile app is released.";
        }
    },
    sentemailbtn: () => {
        switch (lang) {
            case "other":
                return "Envoyer";
            default:
                return "Send";
        }
    },
    cryptoaccessible: () => {
        switch (lang) {
            case "other":
                return <h4 className="common-hfour">Rendre la crypto accessible</h4>;
            default:
                return <h4 className="common-hfour">Make crypto accessible in Europe </h4>;
        }
    },
    byusell: () => {
        switch (lang) {
            case "other":
                return <strong className="bold-font">Achat et vente en un clic</strong>;
            default:
                return <strong className="bold-font">Buy and sell in one click</strong>;
        }
    },
    easyaccess: () => {
        switch (lang) {
            case "other":
                return (
                    <p className="common-peraghar">
                        Lyber vous facilite l’accès aux crypto-actifs les plus populaires du marché à travers une
                        interface simple et ergonomique.
                    </p>
                );
            default:
                return (
                    <p className="common-peraghar">
                        Lyber gives you an easy access to the most popular digital assets through a simple and
                        convenient design.
                    </p>
                );
        }
    },
    partner: () => {
        switch (lang) {
            case "other":
                return <h4 className="common-hfour text-center">Votre partenaire européen</h4>;
            default:
                return <h4 className="common-hfour text-center">Your European partner</h4>;
        }
    },
    neobank: () => {
        switch (lang) {
            case "other":
                return <h6 className="crpto-hsix">Votre Application Mobile pour les Crypto-Actifs.</h6>;
            default:
                return <h6 className="crpto-hsix">The 100% European mobile application for digital assets</h6>;
        }
    },
    frenchandregulart: () => {
        switch (lang) {
            case "other":
                return (
                    <p className="common-peraghar">
                        Lyber est fier de vous présenter un service français et régulé, vous permettant d'investir de
                        manière fiable et sécurisée.
                    </p>
                );
            default:
                return (
                    <p className="common-peraghar">
                        Lyber is proud to propose a French and regulated service that allows you to invest in a safe and
                        reliable way.
                    </p>
                );
        }
    },
    ivestment: () => {
        switch (lang) {
            case "other":
                return <h6 className="crpto-hsix">Un système d’investissement innovant</h6>;
            default:
                return <h6 className="crpto-hsix">An innovative investment system</h6>;
        }
    },
    ivestmentpergharaph: () => {
        switch (lang) {
            case "other":
                return (
                    <p className="common-peraghar">
                        Lyber automatise l’investissement, en le rendant ludique et accessible à tous, investisseurs
                        novices comme chevronnés.
                    </p>
                );
            default:
                return (
                    <p className="common-peraghar">
                        Lyber automates investment, making it fun and accessible to everyone, novice and experienced
                        investors alike.
                    </p>
                );
        }
    },
    techpoint: () => {
        switch (lang) {
            case "other":
                return <h6 className="crpto-hsix">Une technologie de pointe</h6>;
            default:
                return <h6 className="crpto-hsix">State-of-the-art technology</h6>;
        }
    },
    development: () => {
        switch (lang) {
            case "other":
                return (
                    <p className="common-peraghar">
                        {" "}
                        <strong>
                            Lyber s’est concentré sur le développement de plusieurs technologies innovantes :{" "}
                        </strong>
                    </p>
                );
            default:
                return (
                    <p className="common-peraghar">
                        {" "}
                        <strong>Lyber has focused on the development of several innovative technologies : </strong>
                    </p>
                );
        }
    },
    cryptoassiset: () => {
        switch (lang) {
            case "other":
                return (
                    <ul className="technologies-ul">
                        {" "}
                        <li>Un agrégateur de liquidité</li>{" "}
                        <li>Une simplification de l’envoi de crypto-actifs à ses proches</li>{" "}
                        <li>
                            L’association d’une carte de paiement à son compte Lyber afin de dépenser ses crypto-actifs
                            simplement
                        </li>
                    </ul>
                );
            default:
                return (
                    <ul className="technologies-ul">
                        {" "}
                        <li>A liquidity aggregator</li>{" "}
                        <li>A simple way to send digital assets to your friends and family</li>{" "}
                        <li>A payment card linked to your Lyber account to easily spend your digital assets</li>
                    </ul>
                );
        }
    },
    otcheding: () => {
        switch (lang) {
            case "other":
                return <h6 className="crpto-hsix">Exécution OTC B2B</h6>;
            default:
                return <h6 className="crpto-hsix">OTC and B2B service</h6>;
        }
    },
    professional: () => {
        switch (lang) {
            case "other":
                return (
                    <p className="common-peraghar mb-2">
                        {" "}
                        <strong>Un service d’achat et de vente de crypto-actifs pour les professionnels</strong>
                    </p>
                );
            default:
                return (
                    <p className="common-peraghar mb-2">
                        {" "}
                        <strong>A service for buying and selling digital assets for professionals</strong>
                    </p>
                );
        }
    },
    sellinginclude: () => {
        switch (lang) {
            case "other":
                return <p className="common-peraghar">Ce service comporte :</p>;
            default:
                return <p className="common-peraghar">It includes :</p>;
        }
    },
    securebusness: () => {
        switch (lang) {
            case "other":
                return (
                    <ul className="technologies-ul">
                        {" "}
                        <li>Création d’un compte professionnel sécurisé sur Lyber au nom de l’entreprise</li>{" "}
                        <li>Exécution (OTC) Over The Counter disponible 24h/24</li> <li>Assistance et conseil 7j/7</li>
                    </ul>
                );
            default:
                return (
                    <ul className="technologies-ul">
                        {" "}
                        <li>Create a secure business account on Lyber in the company's name</li>{" "}
                        <li>OTC execution available 24h/24</li> <li>Support and advice 7/7</li>
                    </ul>
                );
        }
    },
    facture: () => {
        switch (lang) {
            case "other":
                return (
                    <p className="common-peraghar">
                        Lyber facture une commission variable en fonction du volume pour chaque transaction d’un compte
                        professionnel.
                    </p>
                );
            default:
                return (
                    <p className="common-peraghar">
                        Lyber charges a variable commission based on volume for each transaction from a business account
                    </p>
                );
        }
    },
    qustion: () => {
        switch (lang) {
            case "other":
                return <h6 className="crpto-hsix">Une interrogation ?</h6>;
            default:
                return <h6 className="crpto-hsix">Have a question?</h6>;
        }
    },
    aloneteams: () => {
        switch (lang) {
            case "other":
                return (
                    <p className="common-peraghar">
                        Vous n'êtes pas seul ! Nos équipes sont à votre écoute 7j/7, 24h/24 pour vous assister et
                        répondre à toutes vos questions !
                    </p>
                );
            default:
                return (
                    <p className="common-peraghar">
                        You are not alone! Our team is available 24/7 to assist you and answer all of your questions!
                    </p>
                );
        }
    },
    preregister: () => {
        switch (lang) {
            case "other":
                return <h5>Se pré-inscrire</h5>;
            default:
                return <h5>Pre-register</h5>;
        }
    },
    mobileapp: () => {
        switch (lang) {
            case "other":
                return (
                    <p className="common-peraghar mb-3">
                        Un email vous sera envoyé lors de la sortie de l'application mobile.
                    </p>
                );
            default:
                return (
                    <p className="common-peraghar mb-3">
                        An email will be sent to you when the mobile app is released.
                    </p>
                );
        }
    },
    easyform: () => {
        switch (lang) {
            case "other":
                return <h4 className="common-hfour">Faciliter les transactions</h4>;
            default:
                return <h4 className="common-hfour">Ease transactions</h4>;
        }
    },
    recivedcrypto: () => {
        switch (lang) {
            case "other":
                return <strong className="bold-font">Recevez et envoyez vos crypto-actifs </strong>;
            default:
                return <strong className="bold-font">Receive and send your digital assets</strong>;
        }
    },
    emailaddress: () => {
        switch (lang) {
            case "other":
                return "Avec une adresse e-mail ou un numéro de téléphone, en toute simplicité.";
            default:
                return "With your e-mail address or your phone number.";
        }
    },
    everyasset: () => {
        switch (lang) {
            case "other":
                return <h4 className="common-hfour">En profiter partout</h4>;
            default:
                return <h4 className="common-hfour">Use your assets everywhere</h4>;
        }
    },
    lybercardss: () => {
        switch (lang) {
            case "other":
                return <strong className="bold-font">La Lyber card</strong>;
            default:
                return <strong className="bold-font">The Lyber card</strong>;
        }
    },
    wantscard: () => {
        switch (lang) {
            case "other":
                return "La Lyber card vous permet de dépenser facilement vos crypto-actifs où que vous soyez et quand vous le souhaitez.";
            default:
                return "The Lyber card allows you to easily spend your digital assets, wherever you are and whenever you want.";
        }
    },
    gurantedcovering: () => {
        switch (lang) {
            case "other":
                return <h4 className="common-hfour">Une protection assurée</h4>;
            default:
                return <h4 className="common-hfour">Guaranteed coverage</h4>;
        }
    },
    clintsecurity: () => {
        switch (lang) {
            case "other":
                return "Chez Lyber la sécurité des actifs de nos clients est une priorité. Notre plateforme permet de stocker et transférer des actifs numériques tout en assurant leur protection contre les cyberattaques, les collusions internes et les erreurs humaines. ";
            default:
                return "At Lyber, the security of our clients' assets is a priority.  Our platform enables the storage and transfer of digital assets while protecting them from cyberattacks, internal collusion and human error. ";
        }
    },
};

const About = {
    OurMission: () => {
        switch (lang) {
            case "other":
                return "Notre mission";
            default:
                return "Our mission";
        }
    },
    lyberperagharph: () => {
        switch (lang) {
            case "other":
                return (
                    <p class="common-peraghar">
                        Lyber a comme objectif principal de démocratiser l’accès aux crypto-actifs et de proposer à nos
                        utilisateurs les dernières <span>innovations</span> du Web 3.0 tout en suivant scrupuleusement
                        la <span>réglementation française.</span>
                    </p>
                );
            default:
                return (
                    <p class="common-peraghar">
                        Lyber's main objective is to democratize access to digital assets and to offer our users the
                        latest Web 3.0 <span>innovations.</span>
                    </p>
                );
        }
    },
    memberteam: () => {
        switch (lang) {
            case "other":
                return <h4 class="common-hfour text-center pb-3 pt-2">L'équipe</h4>;
            default:
                return <h4 class="common-hfour text-center pb-3 pt-2">The team</h4>;
        }
    },
    spanishperaghraph: () => {
        switch (lang) {
            case "other":
                return "Fondateur d’Everlens et Ex-Courtier sur les futures et options de taux d’intérêt / Bitcoin chez Tradition Genève.";
            default:
                return "Founder of Everlens and former broker in interest rate futures and options / Bitcoin at Tradition Geneve.";
        }
    },

    busnesdvlp: () => {
        switch (lang) {
            case "other":
                return "Business Développement Europe chez FTX (Deuxième plus importante plateforme de trading de crypto-actifs)";
            default:
                return "Business Development Europe at FTX (Second largest crypto asset trading platform)";
        }
    },
    parisschol: () => {
        switch (lang) {
            case "other":
                return "Diplômé de l’école 42 Paris 2 ans d’expérience dans l’implémentation blockchain et la personnalisation des smart-contracts.";
            default:
                return "Graduate of the 42 Paris school 2 years of experience in blockchain implementation and smart-contract customization.";
        }
    },
    cmo: () => {
        switch (lang) {
            case "other":
                return "CMO d'Everlens et Ex-Sustainability Data Analyst au Luxembourg Green Exchange (Première plateforme mondiale dédiée à la finance durable).";
            default:
                return "CMO of Everlens and Ex-Sustainability Data Analyst at the Luxembourg Green Exchange (First global platform dedicated to sustainable finance).";
        }
    },
    exbusness: () => {
        switch (lang) {
            case "other":
                return "Ex business developer chez Ledger, le fabricant du hardware wallet le plus populaire au monde.";
            default:
                return "Ex-business developer at Ledger Live (the companion software of Ledger hardware wallets)";
        }
    },
};
const Lyb = {
    lyberHeading: () => {
        switch (lang) {
            case "other":
                return "LYB: le meilleur de Lyber";
            default:
                return "LYB: get the best out of Lyber";
        }
    },

    lyberPara: () => {
        switch (lang) {
            case "other":
                return "Pour son écosystème, Lyber émettra un token natif appelé LYB avec une quantité limitée à 500,000,000 unités.";
            default:
                return "Lyber will issue a native token called LYB for its ecosystem with a fixed supply of 500,000,000 units.";
        }
    },

    lyberClaimToken: () => {
        switch (lang) {
            case "other":
                return "LYB: Réclamez vos jetons";
            default:
                return "LYB: Claim your tokens";
        }
    },
    readLitePaper: () => {
        switch (lang) {
            case "other":
                return "Lire le Litepaper";
            default:
                return "Read the Litepaper";
        }
    },
    downloadWhitePaper: () => {
        switch (lang) {
            case "other":
                return "Télécharger le Whitepaper";
            default:
                return "Download the Whitepaper";
        }
    },
    smartContractsAudit: () => {
        switch (lang) {
            case "other":
                return "Audit des contrats intelligents";
            default:
                return "Smart contracts Audit";
        }
    },
    legalOpinion: () => {
        switch (lang) {
            case "other":
                return "Avis juridique";
            default:
                return "Legal opinion";
        }
    },
    advanceTier: () => {
        switch (lang) {
            case "other":
                return (
                    <h2 className="main-title">
                        Plusieurs tiers permettant d’accéder{" "}
                        <span className="text-gray"> à de meilleurs avantages.</span>
                    </h2>
                );
            default:
                return (
                    <h2 className="main-title mt-5">
                        The more advanced the tier, <span className="text-gray">the better the advantages.</span>
                    </h2>
                );
        }
    },
    comingsoonlyb: () => {
        switch (lang) {
            case "other":
                return "Bientôt disponible.";
            default:
                return "Coming soon.";
        }
    },
    connectYourEthereumWallet: () => {
        switch (lang) {
            case "other":
                return "Connectez votre portefeuille Ethereum";
            default:
                return "Connect your Ethereum Wallet";
        }
    },
    connectedAddress: () => {
        switch (lang) {
            case "other":
                return "Adresse connectée:";
            default:
                return "Connected address:";
        }
    },
    claimableToken: () => {
        switch (lang) {
            case "other":
                return "Jetons réclamable:";
            default:
                return "Claimable tokens:";
        }
    },
    connectWallet: () => {
        switch (lang) {
            case "other":
                return "Connecter le portefeuille";
            default:
                return "Connect wallet";
        }
    },
    claimMyTokens: () => {
        switch (lang) {
            case "other":
                return "Réclamer mes jetons";
            default:
                return "Claim my tokens";
        }
    },
    disconnectWallet: () => {
        switch (lang) {
            case "other":
                return "Déconnexion";
            default:
                return "Logout";
        }
    },
    checkboxLabel1: () => {
        switch (lang) {
            case "other":
                return "J'accepte les conditions générales de vente et la politique de confidentialité de Lyber";
            default:
                return "I accept Lyber's terms and conditions and privacy policy";
        }
    },
    checkboxLabel2: () => {
        switch (lang) {
            case "other":
                return "Je certifie avoir pris connaissance du white paper lié au token LYB";
            default:
                return "I certify that I have read the white paper related to the LYB token.";
        }
    },
    checkboxLabel3: () => {
        switch (lang) {
            case "other":
                return "J'accepte d'envoyer les tokens LYB vers un wallet enregistré à mon propre prénom et nom";
            default:
                return "I agree to send LYB tokens to a wallet registered to my own first and last name";
        }
    },


};
const Header = {
    aboutpurpose: () => {
        switch (lang) {
            case "other":
                return "À Propos ";
            default:
                return "About";
        }
    },
    news: () => {
        switch (lang) {
            case "other":
                return "Actualités";
            default:
                return "News";
        }
    },
};
const FaqPage = {
    fullFaq: () => {
        switch (lang) {
            case "other":
                return " Voici notre FAQ complète";

            default:
                return "Here is the full FAQ";
        }
    },
};
const Footer = {
    aboutfooter: () => {
        switch (lang) {
            case "other":
                return "À propos";
            default:
                return "About us";
        }
    },
    termcondition: () => {
        switch (lang) {
            case "other":
                return "Conditions générales d’utilisation";
            default:
                return "Terms and conditions";
        }
    },
    termconditionofsale: () => {
        switch (lang) {
            case "other":
                return "Conditions générales de vente";
            default:
                return "Terms and conditions of sale";
        }
    },
    legalnotices: () => {
        switch (lang) {
            case "other":
                return "Mentions légales";
            default:
                return "Legal notices";
        }
    },
    privacy: () => {
        switch (lang) {
            case "other":
                return "Politique de confidentialité";
            default:
                return "Privacy policy";
        }
    },
    lybToken: () => {
        switch (lang) {
            case "other":
                return "Token LYB";
            default:
                return "LYB token";
        }
    },
    mediumBlog: () => {
        switch (lang) {
            case "other":
                return "Blog Medium";
            default:
                return "Medium Blog";
        }
    },
    contact: () => {
        switch (lang) {
            case "other":
                return "Nous contacter";
            default:
                return "Contact us";
        }
    },
    joinFollow: () => {
        switch (lang) {
            case "other":
                return "Suivez-nous";
            default:
                return "Follow us";
        }
    },
    lyberPara: () => {
        switch (lang) {
            case "other":
                return "Vos investissements en crypto, diversifiés, simples et réguliers.";
            default:
                return "Your crypto investment, diversified, simple and recurring.";
        }
    },
    footertwo: () => {
        switch (lang) {
            case "other":
                return "Lyber SAS, société immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 903 241 925. Lyber SAS est enregistrée sous l’Autorité des Marchés Financiers (AMF) en tant que Prestataire de Services sur Actifs Numériques (PSAN) sous le numéro E2022-039.";
            default:
                return "Lyber SAS is registered with the Paris Trade and Companies Registry under number 903 241 925. Lyber SAS is registered with the Autorité des Marchés Financiers (AMF) as a Prestataire de Services sur Actifs Numériques (PSAN) under number E2022-039.";
        }
    },
    byEmail: () => {
        switch (lang) {
            case "other":
                return "Par email";
            default:
                return "By email";
        }
    },
    contactForm: () => {
        switch (lang) {
            case "other":
                return "Formulaire de contact";
            default:
                return "Contact form";
        }
    },

};
export default {
    Home,
    About,
    Lyb,
    FaqPage,
    Header,
    Footer,
    setLang: (_lang) => {
        lang = _lang;
    },
};
