const JobOffer = () => {
    return (
        <>
            <section className="privacy-policy pb-5 mb-3">
                <div className="container">
                    <h2 className="term-heading text-center mt-lg-4 mt-2">
                        Offre d'emploi
                    </h2>
                    <br />
                    <br />
                    <br />

                    <p>
                        Fondée en 2021, Lyber est une application mobile française permettant d’investir, envoyer et dépenser des crypto-actifs simplement. Si vous êtes passionné par l’univers de la blockchain et des crypto-actifs alors Lyber est une entreprise pour vous. Vous travaillerez aux côtés d’une équipe jeune et dynamique, prête à en découdre.
                    </p>

                    <br />
                    <h3>Objectif principal du poste</h3>
                    <br />
                    <p>
                        Vous allez de façon autonome relever le défi de faire gagner à Lyber un maximum d’utilisateurs. Vous allez développer et mettre à exécution des stratégies marketing pour augmenter le nombre d'utilisateurs de l’application Lyber et augmenter la notoriété de la société.
                    </p>
                    <br />
                    <h3>Description du poste</h3>
                    <br />
                    <ul>
                        <li>Élaborer et mettre à exécution un plan marketing</li>
                        <li>Mettre en oeuvre des stratégies de communication</li>
                        <li>Préparer et gérer les contenus pour les réseaux à l’aide de notre prestataire</li>
                        <li>Être prêt à relever beaucoup de défis dont notamment celui de lancer une application mobile</li>
                        <li>Développer et promouvoir l’image de marque de Lyber</li>
                        <li>Réalisation de tableaux de suivis pour toutes les campagnes de marketing et communication (argent dépensé, nombre d’utilisateurs convertis, portée obtenue, etc.)</li>
                        <li>Mise en place d’objectifs mensuels</li>
                        <li>Détecter les nouvelles tendances en terme de marketing et communication</li>
                        <li>Gérer un budget attribué</li>
                        <li>Participer, organiser et animer des cryptos meetups</li>
                    </ul>

                    <br />
                    <h3>Expérience</h3>
                    <br />
                    <ul>
                        <li>Bac+5, école de marketing/communication</li>
                        <li>Stages en communication/marketing pendant le cursus scolaire</li>
                        <li>Maîtrise de la langue anglaise</li>
                    </ul>
                    <br />
                    <h3>Soft skills</h3>
                    <br />
                    <ul>
                        <li>Autonomie</li>
                        <li>Être sociable</li>
                        <li>Créativité</li>
                        <li>Être organisé</li>
                        <li>Rigueur</li>
                        <li>Sens du détail</li>
                        <li>Travail en équipe</li>
                        <li>Connaissance de l’écosystème crypto</li>
                        <li>Forte aptitude à communiquer et à écouter</li>
                        <li>Force de proposition</li>
                    </ul>
                    <br />
                    <h3>Conditions de travail</h3>
                    <br />
                    <ul>
                        <li>Poste basé à Paris</li>
                        <li>Travail en majorité en télétravail</li>
                        <li>Flexibilité horaires</li>
                        <li>Du Lundi au Vendredi</li>
                        <li>Rémunération selon le profil</li>
                    </ul>
                    <p>
                        Envoyez votre candidature à l’adresse suivante si le poste vous intéresse : <span className="light-purple">team@lyber.com</span>.
                    </p>
                </div>
            </section>
        </>
    );
};

export default JobOffer;
