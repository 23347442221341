import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./assets/styles/main.scss";
import Layout from "./components/layout";
import Home from "./components/home";
import Lyb from "./components/lyb";
import FAQ from "./components/faq";
import KYC from "./components/kyc";
import VerifyEmail from "./pages/verifyEmail";
import PrivacyPolicy from "./components/privacyPolicy";
import TermsAndCondition from "./components/termsAndCondition";
import Unsubscribe from "./pages/Unsubscribe";
import Articles from "./components/articles";
import PricingPolicy from "./components/pricingPolicy"
import LegalNotices from "./components/legalNotices"
import JobOffer from "./components/jobOffer"
// import Contact from "./components/contact"
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Contact from "./components/contactForm"
import CookiePolicy from "./components/cookiePolicy"
import WalletConnectPage from "./components/claimToken"

export default () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Layout>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="lyb" element={<Lyb />} />
                                <Route path="/faq" element={<FAQ />} />
                                <Route path="/kyc" element={<KYC />} />
                                <Route path="/privacy" element={<PrivacyPolicy />} />
                                <Route path="/pricing" element={<PricingPolicy />} />
                                <Route path="/legal" element={<LegalNotices />} />
                                <Route path="/article" element={<Articles />} />
                                <Route path="/terms-conditions" element={<TermsAndCondition />} />
                                <Route path="/job-offer" element={<JobOffer />} />
                                {/* <Route path="/contact" element={<Contact />} /> */}
                                <Route path="/reset" element={<ResetPassword />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/cookie" element={<CookiePolicy />} />
                                <Route path="/claim-token" element={<WalletConnectPage />} />
                            </Routes>
                        </Layout>
                    }
                >
                    <Route path="/article" />
                    <Route path="/" />
                    <Route path="/lyb" />
                    <Route path="/faq" />
                    <Route path="/kyc" />
                    <Route path="/verify-email" />
                    <Route path="/privacy" />
                    <Route path="/pricing" />
                    <Route path="/legal" />
                    <Route path="/terms-conditions" />
                    <Route path="/job-offer" />
                    {/* <Route path="/contact" /> */}
                    <Route path="/reset" />
                    <Route path="/contact" />
                    <Route path="/cookie" />
                    <Route path="/claim-token" />
                </Route>
                <Route path="/emailVerify" element={<VerifyEmail />} />
                <Route path="/unsubscribe" element={<Unsubscribe />} />
            </Routes>
        </BrowserRouter>
    );
};
