import HomeImageFrench from "../../assets/images/banner_landing_fr.svg";
import HomeImageEnglish from "../../assets/images/banner_landing_en.svg";
import Trusted from "../../assets/images/home/trusted.svg";
import Certified from "../../assets/images/home/certified.svg";

import UniqueToken from "../../assets/images/home/unique-token.svg";
import NotchSupport from "../../assets/images/home/notch-support.svg";
import OwnStrategy from "../../assets/images/home/own-strategy.svg";
import TradeAsset from "../../assets/images/home/trade-asset.svg";

import UniqueTokenFrench from "../../assets/images/french/unique-token-french.svg";
import NotchSupportFrench from "../../assets/images/french/notch-french.svg";
import OwnStrategyFrench from "../../assets/images/french/own-strategy-french.svg";
import TradeAssetFrench from "../../assets/images/french/trade-french.svg";

import Orwl from "../../assets/images/home/orwl.svg";
import Lco from "../../assets/images/home/logo-lco-big.png";
import Regsharp from "../../assets/images/home/Regsharp_Logo.png";
import StrategyMobile from "../../assets/images/strategy-mobile-new.png";
import InvestFreqMobile from "../../assets/images/invest-freq-mobile-new.png";
import PortfolioMobile from "../../assets/images/portfolio-mobile-new.png";
import StayControlMobile from "../../assets/images/stay-control-mobile-new.png";
import StrategyDesktop from "../../assets/images/home/strategy-desktop.png";
import InvestFreqDesktop from "../../assets/images/home/invest-freq-desktop.png";
import PortfolioDesktop from "../../assets/images/home/portfolio-desktop.png";
import StayControlDesktop from "../../assets/images/home/stay-control-desktop.png";
import css from "../home/slider.css";

import DownloadAppleEN from "../../assets/images/Download_on_the_App_Store_Badge_EN.svg";
import DownloadAppleFR from "../../assets/images/Download_on_the_App_Store_Badge_FR.svg";
import DownloadAndroidEN from "../../assets/images/Google_Play_Store_badge_EN.svg";
import DownloadAndroidFR from "../../assets/images/Google_Play_Store_badge_FR.svg";


import ScoreChain from "../../assets/images/home/Scorechain.png";
import ComplyAdvantage from "../../assets/images/home/complyadvantage.svg";

import StrategyDesktopFrench from "../../assets/images/french/strategy-french.svg";
import InvestFreqDesktopFrench from "../../assets/images/french/invest-frequently-french.svg";
import PortfolioDesktopFrench from "../../assets/images/french/portfolio-french.svg";
import StayControlDesktopFrench from "../../assets/images/french/stay-control-french.svg";

import { API_ROOT } from "../../utils/henceforthApi";
import language_contant from "../../utils/language_contant";
import { useContext, useState } from "react";
import { GlobalContext } from "../../context/Provider";
import { validateEmail } from "../../utils/validation_regex";
import UniqueTokenMobile from "../../assets/images/Secondary_features--unique_assets.png";
import NotchSupportMobile from "../../assets/images/Secondary_features--support.png";
import OwnStrategyMobile from "../../assets/images/Secondary_features--ow_strategy.png";
import TradeAssetMobile from "../../assets/images/Secondary_features--trade.png";
import React from "react";
import Slider from "react-slick";
import axios from "axios";

import { Button, Modal } from "react-bootstrap";
import QuestionMark from "../../assets/images/Subtract.svg";
import "react-toastify/dist/ReactToastify.css";
import TickImage from "../../assets/images/successful.svg";
import { Spinner } from "../BootstrapCompo";
import ReactPixel from 'react-facebook-pixel';


const Home = () => {
    const { authState, authDispatch } = useContext(GlobalContext);

    if (authState.areCookiesAccepted === true) {
        if (authState.metaSliderState === true)
            ReactPixel.pageView()
        if (authState.googleSliderState === true)
            window.gtag('event', 'page_view');
    }

    language_contant.setLang(authState.lang);
    const lang = authState.lang;

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        centerMode: true,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        focusOnSelect: true,
        className: "center",
        autoplay: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        // accessibility: false,
        draggable: false,
        pauseOnDotsHover: false,
        focusOnChange: false,
    };

    var settings2 = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        draggable: false,
        pauseOnDotsHover: false,
        accessibility: false,
    };

    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showSuccessful, setShowSuccessful] = useState(false);
    const [showInvalid, setShowInvalid] = useState(false);
    const [showAlreadyId, setShowAlreadyId] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageFoot, setErrorMessageFoot] = useState("");

    const handleClose = () => {
        setShowConfirm(false);
        setShowSuccessful(false);
    };
    const handleCloseAdreadyId = () => setShowAlreadyId(false);
    const handleCloseInvalid = () => setShowInvalid(false);
    const handleShow = () => setShowConfirm(true);

    const [state, setState] = useState({
        email: "",
        language: "ENGLISH",
        loading: false,
    });

    const checkEmail = async () => {
        const { email, loading } = state;
        if (validateEmail(email)) {
            handleShow(true);
        } else {
            setShowInvalid(true);
        }
    };

    const handleConfirm = async () => {
        handleShow(true);
        await preRegister();
    };
    const preRegister = async () => {
        const { email, loading } = state;
        if (validateEmail(email)) {
            setState({
                ...state,
                loading: true,
            });
            try {
                setLoading(true);
                let items = {
                    email: state.email,
                    language: authState.lang === "other" ? "FRENCH" : "ENGLISH",
                };
                // debugger;
                let res = await axios.post(`${API_ROOT}/api/email`, { email: state.email });
                setState({
                    ...state,
                    loading: false,
                    email: "",
                    language: "",
                    res,
                });
                setShowSuccessful(true);
                // alert(res.data.message);
            } catch (error) {
                setShowConfirm(false);
                setShowAlreadyId(true);

                // alert(error.response.body.message);
                setState({
                    ...state,
                    loading: false,
                });
            } finally {
                setLoading(false);
            }
        } else {
            setShowInvalid(true);
            // alert("Invalid email ID");
        }
    };

    const handleDownloadButtonClickAdroid = () => {
        // Redirect to Google Play Store for Android users
        //window.location.href = "your_play_store_link";
        window.gtag_report_conversion('https://play.google.com/store/apps/details?id=com.Lyber')
        //alert(language_contant.Home.comingsoon())
    };

    const handleDownloadButtonClickIOS = () => {
        window.gtag_report_conversion('https://apps.apple.com/fr/app/lyber/id1609050369')
        // Redirect to Apple App Store for iOS users
        //window.location.href = "https://apps.apple.com/fr/app/lyber/id1609050369";
    };

    return (
        <>
            <section className="section-spacing home-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="banner-content">
                                <h1>{language_contant.Home.primeryTitles()}</h1>
                                <p className="mt-4 sub-text">{language_contant.Home.commonPeraghar()}</p>
                                <div className="d-flex flex-row gap-4">
                                    <img className="download-badge" src={lang === "other" ? DownloadAppleFR : DownloadAppleEN} onClick={handleDownloadButtonClickIOS} />
                                    <img className="download-badge" src={lang === "other" ? DownloadAndroidFR : DownloadAndroidEN} onClick={handleDownloadButtonClickAdroid} />
                                </div>
                                {/* <button className="btn btn-primary text-nowrap">
                                    <b>{language_contant.Home.download()}</b>
                                </button> */}
                                {/* <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (!state.email) {
                                            setErrorMessage(language_contant.Home.emailIsRequired());
                                        } else {
                                            setErrorMessage("");
                                            checkEmail();
                                        }
                                        // handleConfirm();
                                    }}
                                >
                                    <div className="d-flex align-items-center mt-sm-5 mt-4 register-input flex-sm-row flex-column">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={lang === "other" ? "Email" : "Enter your email"}
                                            onChange={(e) => setState({ ...state, email: e.target.value })}
                                            value={state.email}
                                            disabled={state.loading}

                                        />

                                        <button className="btn btn-primary ms-sm-3 text-nowrap">
                                            {language_contant.Home.preRegisters()}
                                        </button>
                                    </div>
                                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                                    <p className="mt-1 subscribe-text">{language_contant.Home.subscribeText()}</p>
                                </form> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text-end">
                                {lang === "other" ? (
                                    <img
                                        src={HomeImageFrench}
                                        className="img-fluid banner-img d-sm-block d-none ms-auto"
                                    />
                                ) : (
                                    <img src={HomeImageEnglish} className="img-fluid banner-img d-sm-block d-none ms-auto" />
                                )}
                            </div>
                            <div className="text-center">
                                {lang === "other" ? (
                                    <img
                                        src={HomeImageFrench}
                                        className="img-fluid banner-img d-sm-none ms-auto"
                                        alt="banner-img"
                                    />
                                ) : (
                                    <img
                                        src={HomeImageEnglish
                                        }
                                        className="img-fluid banner-img d-sm-none ms-auto"
                                        alt="banner-img"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {/* Confimed And Success Modal */}
                    <Modal
                        show={showConfirm}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {showSuccessful
                                    ? lang == "other"
                                        ? "Merci de nous avoir rejoint !"
                                        : "Welcome at Lyber!"
                                    : lang == "other"
                                        ? "Confirmez votre inscription"
                                        : "Confirm your subscription"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="d-flex align-items-center  my-3 px-4">
                                <div>
                                    {showSuccessful ? (
                                        <img style={{ height: "50px", width: "50px" }} src={TickImage} alt="logo" />
                                    ) : (
                                        <img style={{ height: "50px", width: "50px" }} src={QuestionMark} alt="logo" />
                                    )}
                                </div>
                                <div>
                                    {showSuccessful ? (
                                        lang == "other" ? (
                                            <div className="ms-3">
                                                Vous êtes maintenant inscrit à la newsletter de Lyber. Vous allez
                                                bientôt recevoir un email de confirmation.
                                            </div>
                                        ) : (
                                            <div className="ms-3">
                                                You are now registered on Lyber’s newsletter. You should receive a
                                                confirmation email soon.
                                            </div>
                                        )
                                    ) : lang == "other" ? (
                                        <div className="ms-3">
                                            En cliquant sur
                                            <span className="text-theme"> “Oui” </span> vous acceptez de recevoir des
                                            communiqués de la part de Lyber.
                                        </div>
                                    ) : (
                                        <div className="ms-3">
                                            By clicking on <span className="text-theme">“Yes”</span> you agree to
                                            receive communications from Lyber.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {showSuccessful ? (
                                <div>
                                    <Button onClick={handleClose} className="px-6 confirm-btn">
                                        Ok
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <Button onClick={handleClose} className="px-6 reject-btn">
                                        {lang == "other" ? "Non" : "No"}
                                    </Button>
                                    <Button
                                        onClick={handleConfirm}
                                        className="px-6 confirm-btn ms-3"
                                        disabled={loading}
                                    >
                                        {loading ? <Spinner /> : lang == "other" ? "Oui" : "Yes"}
                                    </Button>
                                </div>
                            )}
                        </Modal.Footer>
                    </Modal>
                    {/* Already Registerd Modal */}
                    <Modal
                        show={showAlreadyId}
                        onHide={handleCloseAdreadyId}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {lang == "other" ? "Vous nous suivez déjà !" : "You’re already registered!"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="text-center my-3 px-4">
                                <div>
                                    {lang == "other"
                                        ? "L'adresse e-mail est déjà enregistrée. Veuillez utiliser une autre adresse e-mail."
                                        : "The email address is already registered. Please use another email address."}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseAdreadyId} className="px-6 confirm-btn">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* Invalid Modal */}
                    <Modal
                        show={showInvalid}
                        onHide={handleCloseAdreadyId}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header>
                            <Modal.Title> {lang == "other" ? "Non valide" : "Invalid"} </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-3 text-center">
                                <div>
                                    {lang == "other" ? "Identifiant de messagerie invalide" : "Invalid Email Id."}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseInvalid} className="px-6 confirm-btn">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </section>
            <section className="section-spacing pb-0">
                <div className="container">
                    <div className=" steps-content">
                        <h2 className="main-title steps-title mt-1">
                            {language_contant.Home.homeUpperSlider()}
                            {/* How easy it is to start investing <span className="text-gray">with the Lyber app?</span> */}
                        </h2>
                        <div className="steps-container d-lg-block d-none">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <Slider ref={slider => setNav1(slider)} asNavFor={nav2} {...settings} className="slider-text">
                                        <div class="item">
                                            <h6 className="steps mb-0">{language_contant.Home.step01()}</h6>
                                            <h3 className="ff-atyp fw-semi-bold">
                                                {language_contant.Home.step01Heading()}{" "}
                                            </h3>
                                            <p>{language_contant.Home.step01Para()}</p>
                                        </div>
                                        <div class="item">
                                            <h6 className="steps mb-0">{language_contant.Home.step02()}</h6>
                                            <h3 className="ff-atyp fw-semi-bold">
                                                {language_contant.Home.step02Heading()}{" "}
                                            </h3>
                                            <p>{language_contant.Home.step02Para()}</p>
                                        </div>
                                        <div class="item">
                                            <h6 className="steps mb-0">{language_contant.Home.step03()}</h6>
                                            <h3 className="ff-atyp fw-semi-bold">
                                                {language_contant.Home.step03Heading()}{" "}
                                            </h3>
                                            <p>{language_contant.Home.step03Para()}</p>
                                        </div>
                                        <div class="item">
                                            <h6 className="steps mb-0">{language_contant.Home.step04()}</h6>
                                            <h3 className="ff-atyp fw-semi-bold">
                                                {language_contant.Home.step04Heading()}{" "}
                                            </h3>
                                            <p>{language_contant.Home.step04Para()}</p>
                                        </div>
                                    </Slider>
                                </div>
                                <div className="col-lg-6">
                                    <div className="text-end">
                                        {lang == "other" ? (
                                            <Slider ref={slider => setNav2(slider)} asNavFor={nav1} {...settings2}>
                                                <div className="strategy-french">
                                                    <img src={StrategyDesktopFrench} className="img-fluid step-img" />
                                                </div>
                                                <div className="invest-french">
                                                    <img src={InvestFreqDesktopFrench} className="img-fluid step-img" />
                                                </div>
                                                <div className="portfolio-french">
                                                    <img src={PortfolioDesktopFrench} className="img-fluid step-img" />
                                                </div>
                                                <div className="stay-french">
                                                    <img
                                                        src={StayControlDesktopFrench}
                                                        className="img-fluid step-img"
                                                    />
                                                </div>
                                            </Slider>
                                        ) : (
                                            <Slider ref={slider => setNav2(slider)} asNavFor={nav1} {...settings2}>
                                                <img src={StrategyDesktop} className="img-fluid step-img" />
                                                <img src={InvestFreqDesktop} className="img-fluid step-img" />
                                                <img src={PortfolioDesktop} className="img-fluid step-img" />
                                                <img src={StayControlDesktop} className="img-fluid step-img" />
                                            </Slider>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="steps-container d-lg-none">
                            <div className="row">
                                <div className="col-lg-6 top-space">
                                    <div className="steps-info active">
                                        <h6 className="steps mb-0">{language_contant.Home.step01()}</h6>
                                        <h3 className="ff-atyp fw-semi-bold">
                                            {language_contant.Home.step01Heading()}
                                        </h3>
                                        <p>{language_contant.Home.step01Para()}</p>
                                        <div className="mt-4 strategy-mobile mobile-card">
                                            <img
                                                src={StrategyMobile}
                                                className="img-fluid step-img"
                                                alt="strategy-img"
                                            />
                                        </div>
                                    </div>
                                    <div className="steps-info">
                                        <h6 className="steps mb-0">{language_contant.Home.step02()}</h6>
                                        <h3 className="ff-atyp fw-semi-bold">
                                            {language_contant.Home.step02Heading()}
                                        </h3>
                                        <p>{language_contant.Home.step02Para()}</p>
                                        <div className="mt-4 invest-mobile mobile-card">
                                            <img
                                                src={InvestFreqMobile}
                                                className="img-fluid step-img"
                                                alt="invest-img"
                                            />
                                        </div>
                                    </div>
                                    <div className="steps-info">
                                        <h6 className="steps mb-0">{language_contant.Home.step03()}</h6>
                                        <h3 className="ff-atyp fw-semi-bold">
                                            {language_contant.Home.step03Heading()}
                                        </h3>
                                        <p>{language_contant.Home.step03Para()}</p>
                                        <div className="mt-4 portfolio-mobile mobile-card">
                                            <img
                                                src={PortfolioMobile}
                                                className="img-fluid step-img"
                                                alt="portfolio-img"
                                            />
                                        </div>
                                    </div>
                                    <div className="steps-info">
                                        <h6 className="steps mb-0">{language_contant.Home.step04()}</h6>
                                        <h3 className="ff-atyp fw-semi-bold">
                                            {language_contant.Home.step04Heading()}
                                        </h3>
                                        <p>{language_contant.Home.step04Para()}</p>
                                        <div className="mt-4 stay-mobile mobile-card">
                                            <img
                                                src={StayControlMobile}
                                                className="img-fluid step-img"
                                                alt="stay-control-img"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-spacing pt-5 mb-4 trustworthy-wrapper-main">
                <div className="container">
                    <div className="">
                        <div className="row trustworthy overridebsGutterX trustworthy-wrapper">
                            <div className="col-lg-7">
                                <div
                                    className="d-flex align-items-start mb-lg-0 flex-sm-row flex-column"
                                    style={{ padding: "40px 0" }}
                                >
                                    <div>
                                        <img src={Trusted} className="img-fluid me-sm-4 mb-sm-0 mb-3 trust-img" />
                                    </div>
                                    <div>
                                        <h2 className="mb-sm-3 mb-2">{language_contant.Home.cardText()}</h2>
                                        <div className="row mt-sm-5 mt-4 ps-md-3 trusted-img">
                                            {/* <div className="col-sm-6 col-xs-6">
                        <img
                          src={Treezor}
                          alt="Treezor"
                          className="img-fluid mb-4 pb-md-2 treezor"
                        />
                      </div> */}
                                            <div className="col-sm-6 col-xs-6" style={{ padding: "0px 0px 0px 13px" }}>
                                                <img src={Orwl} alt="Orwl" className="img-fluid mb-4 pb-md-0.5" style={{ height: "200px" }} />
                                            </div>

                                            <div className="col-sm-6 col-xs-6">
                                                <img src={ScoreChain} alt="ScoreChain" className="img-fluid mb-4" />
                                            </div>

                                            <div className="col-sm-6 col-xs-6">
                                                <img src={ComplyAdvantage} alt="ComplyAdvantage" className="img-fluid mb-4" />
                                            </div>

                                            {/* <div className="col-sm-6 col-xs-6">
                                            <img src={Binance} alt="Binance" className="img-fluid mb-4 pb-md-0.5" />
                                        </div> */}

                                            <div className="col-sm-6 col-xs-6">
                                                <img src={Lco} alt="Lco" className="img-fluid mb-4 pb-md-0.001" />
                                            </div>

                                            <div className="col-sm-6 col-xs-6">
                                                <img src={Regsharp} alt="Regsharp" className="img-fluid mb-4 pb-md-0.005" />
                                            </div>

                                            {/* <div className="col-sm-6 col-xs-6" style={{ padding: "0px 3px 3px -1" }}>
                                            <img src={Chainalysis} alt="Chainalysis" className="img-fluid mb-3" />
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div
                                    className="d-flex align-items-start flex-sm-row flex-column"
                                    style={{ padding: "40px 0" }}
                                >
                                    <div>
                                        <img src={Certified} className="img-fluid me-sm-4 mb-sm-0 mb-3 trust-img" />
                                    </div>
                                    <div>
                                        <h2 className="mb-sm-3 mb-2">{language_contant.Home.cardText2()}</h2>
                                        {language_contant.Home.cardTextPara()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-spacing features-wrapper py-5 mt-5">
                <div className="container">
                    <div className="features-content">
                        {language_contant.Home.wantMore()}
                        <div className=" features-boxes">
                            <div className="row">
                                <div className="col-md-5 pe-md-2 mb-3">
                                    <div className="f-box text-center">
                                        <h3 className="ff-atyp fw-semi-bold">
                                            {language_contant.Home.buyUniqueToken()}
                                        </h3>
                                        {language_contant.Home.buyUniqueTokenPara()}
                                        <div className="features-img">
                                            {lang == "other" ? (
                                                <img src={UniqueToken} className="img-fluid d-lg-block d-none" />
                                            ) : (
                                                <img src={UniqueTokenFrench} className="img-fluid d-lg-block d-none" />
                                            )}
                                            <img
                                                src={UniqueTokenMobile}
                                                className="img-fluid d-lg-none mobile-features"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7 ps-md-2 mb-3">
                                    <div className="f-box text-center">
                                        <h3 className="ff-atyp fw-semi-bold">{language_contant.Home.tradeAsset()}</h3>
                                        {language_contant.Home.tradeAssetPara()}
                                        <div className="features-img">
                                            {lang == "other" ? (
                                                <img src={TradeAssetFrench} className="img-fluid d-lg-block d-none" />
                                            ) : (
                                                <img src={TradeAsset} className="img-fluid d-lg-block d-none" />
                                            )}
                                            <img
                                                src={TradeAssetMobile}
                                                className="img-fluid d-lg-none mobile-features"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-7 pe-md-2 mb-3">
                                    <div className="f-box text-center">
                                        <h3 className="ff-atyp fw-semi-bold">
                                            {language_contant.Home.buildOwnStrategy()}
                                        </h3>
                                        {language_contant.Home.buildOwnStrategyPara()}
                                        <div className="features-img">
                                            {lang == "other" ? (
                                                <img src={OwnStrategyFrench} className="img-fluid d-lg-block d-none" />
                                            ) : (
                                                <img src={OwnStrategy} className="img-fluid d-lg-block d-none" />
                                            )}
                                            <img
                                                src={OwnStrategyMobile}
                                                className="img-fluid d-lg-none mobile-features"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 ps-md-2 mb-3">
                                    <div className="f-box text-center">
                                        <h3 className="ff-atyp fw-semi-bold">
                                            {language_contant.Home.topNotchSupport()}
                                        </h3>
                                        {language_contant.Home.topNotchSupportPara()}
                                        <div className="features-img">
                                            {lang == "other" ? (
                                                <img src={NotchSupportFrench} className="img-fluid d-lg-block d-none" />
                                            ) : (
                                                <img src={NotchSupport} className="img-fluid d-lg-block d-none" />
                                            )}
                                            <img
                                                src={NotchSupportMobile}
                                                className="img-fluid d-lg-none mobile-features"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="section-spacing features-wrapper"
                style={{ padding: "81px 0px 81px 0px" }}
                id="pre-register"
            >
                <div className="container">
                    <div className="newsletter newsletter-bg">
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 text-center-991px">
                                <h2 className="white-title newsletter-text">
                                    {language_contant.Home.startToInvest()}{" "}
                                </h2>
                                {/* <h2 className="white-title newsletter-text">Start to invest with Lyber. <span className="text-light-purple">Pre-register or join us on socials</span> </h2> */}
                            </div>
                            <div className="col-lg-6 col-xl-6 d-flex align-items-center justify-center-991px">
                                <div className="pre-register-content">
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            if (!state.email) {
                                                setErrorMessageFoot(language_contant.Home.emailIsRequired());
                                            } else {
                                                setErrorMessageFoot("");
                                                checkEmail();
                                            }
                                        }}
                                    >
                                        <div className="form-group d-flex align-items-center register-input">
                                            <input
                                                type=""
                                                className="form-control me-sm-2"
                                                placeholder={lang == "other" ? "Email" : "Enter your email"}
                                                onChange={(e) => setState({ ...state, email: e.target.value })}
                                                value={state.email}
                                                disabled={state.loading}

                                            />
                                            <button className="btn btn-secondary text-nowrap newclass">
                                                {language_contant.Home.preRegister()}
                                            </button>
                                        </div>
                                        {errorMessageFoot && <div className="error-message-foot">{errorMessageFoot}</div>}
                                    </form>

                                    {/* <p className="or-content my-2 text-center">
                                        <span className="text-or">{lang == "other" ? "OU" : "OR"}</span>
                                    </p>
                                    <div className="d-flex align-items-center flex-sm-row flex-column socials">
                                        <a
                                            href="https://twitter.com/LyberOfficial"
                                            target="_blank"
                                            className="me-sm-1 w-50"
                                        >
                                            <button className="btn btn-light w-100">
                                                <img src={Twitter} className="icon" />
                                                {lang == "other" ? "Twitter" : "Follow us on Twitter"}
                                            </button>
                                        </a>
                                        <a
                                            href="https://t.me/LyberOfficial"
                                            target="_blank"
                                            className="ms-sm-1 w-50 mt-sm-0 mt-2"
                                        >
                                            <button className="btn btn-light w-100">
                                                <img src={Telegram} className="icon" />
                                                {lang == "other" ? "Telegram" : "Join Telegram"}
                                            </button>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-spacing features-wrapper mb-md-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <h2 className="main-title home-faq-title mb-4 pb-2">
                                {language_contant.Home.haveQuestion()}{" "}
                                <span className="text-gray">{language_contant.Home.mostAsked()}</span>
                            </h2>
                            {language_contant.Home.checkoutFaqPage()}
                        </div>
                        <div className="col-md-5">
                            <div className="home-faq">
                                <div className="mb-sm-5 mb-4 pb-md-2">
                                    <h3 className="text-theme medium-title">{language_contant.Home.whatIsLyber()}</h3>
                                    <p>{language_contant.Home.whatIsLyberAns()}</p>
                                </div>
                                <div className="mb-sm-5 mb-4 pb-md-2">
                                    <h3 className="text-theme medium-title">
                                        {language_contant.Home.isLyberRegulated()}
                                    </h3>
                                    <p>{language_contant.Home.isLyberRegulatedAns()}</p>
                                </div>
                                <div className="mb-sm-5 mb-4 pb-md-2">
                                    <h3 className="text-theme medium-title">
                                        {language_contant.Home.whyShouUseLyber()}
                                    </h3>
                                    <p>{language_contant.Home.whyShouUseLyberAns()}</p>
                                </div>
                                {/* <div className="mb-sm-5 mb-4 pb-md-2">
                                    <h3 className="text-theme medium-title">
                                        {language_contant.Home.whereLyberAvailable()}
                                    </h3>
                                    <p>{language_contant.Home.whereLyberAvailableAns()}</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
