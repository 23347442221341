import { API_ROOT } from "../../utils/henceforthApi";
import language_contant from "../../utils/language_contant";
import { useContext, useState } from "react";
import { GlobalContext } from "../../context/Provider";
import { validateEmail } from "../../utils/validation_regex";
import React from "react";
import { useRef } from "react"
import axios from "axios";

import { Button, Modal } from "react-bootstrap";
import QuestionMark from "../../assets/images/Subtract.svg";
import "react-toastify/dist/ReactToastify.css";
import TickImage from "../../assets/images/successful.svg";
import { Spinner } from "../BootstrapCompo";
import mailIcon from "../../assets/images/mailIcon.svg"
import localisationIcon from "../../assets/images/localisationIcon.svg"
import telegramPurple from "../../assets/images/home/telegramPurple.svg"
import earth from "../../assets/images/earth.png"

import ReCAPTCHA from "react-google-recaptcha"

export const ContactFormEN = () => {
    const { authState, authDispatch } = useContext(GlobalContext);
    const captchaRef = useRef(null);
    language_contant.setLang(authState.lang);
    const lang = authState.lang;

    const [loading, setLoading] = useState(false);
    const [showSuccessful, setShowSuccessful] = useState(false);
    const [showInvalid, setShowInvalid] = useState(false);
    const [showMissingField, setShowMissingField] = useState(false);
    const [showMissingCaptcha, setShowMissingCaptcha] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleCloseInvalid = () => setShowInvalid(false);
    const handleCloseMissingField = () => setShowMissingField(false);
    const handleCloseSuccessful = () => setShowSuccessful(false)
    const handleCloseMissingCaptcha = () => setShowMissingCaptcha(false)

    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        language: "ENGLISH",
        loading: false,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        try {
            // const response = await axios.post(`${API_ROOT}/api/recaptcha`, { token: token });
            const { email, firstName, lastName, message, loading } = state;
            if (email === "" || firstName === "" || lastName === "" || message.trim().length === 0) {
                setErrorMessage("Une ou plusieurs informations sont manquante.");
                setShowMissingField(true)
            }
            else if (validateEmail(email) === false) {
                setErrorMessage("The e-mail address is invalid.");
            }
            else if (token === "") {
                setErrorMessage("Vous devez valider le CAPTCHA.");
                setShowMissingCaptcha(true)
            } else {
                setErrorMessage("");
                const response = await axios.post(`${API_ROOT}/api/sendTicket`, { token: token, name: `${firstName} ${lastName}`, email: email, message: message });
                if (response.status === 200) {
                    setShowSuccessful(true)
                    setState({
                        firstName: "",
                        lastName: "",
                        email: "",
                        message: "",
                        language: "ENGLISH",
                        loading: false,
                    })
                }
                else {
                    setShowInvalid(true)
                }
            }
        }
        catch (e) {
            setShowInvalid(true)
        }
    }

    return (
        <>
            <section>
                <div className="container">
                    <img className="img-earth-bottom" src={earth} />
                    <div className="row justify-content-center">
                        <div className="col-lg-6" >
                            <div className="contact-form gap-3">
                                <h1 className="text-center">Contact us</h1>
                                <div className="contact-top-box">
                                    <div className="mail-box-title">
                                        <img src={mailIcon} className="mail-icon" />
                                        <p>Email</p>
                                    </div>

                                    <p className="mt-2 sub-text">Send us an e-mail to <a href="mailto:contact@lyber.com" target="_blank">contact@lyber.com</a> or fill in the contact form below:</p>

                                </div>

                                <div>
                                    <form
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="d-flex register-input flex-column contact-form-content gap-3 ">
                                            <h1 className="header-contact-form text-start">Contact form</h1>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="First name"
                                                onChange={(e) => {
                                                    if (!(e.target.value.length > 30))
                                                        setState({ ...state, firstName: e.target.value })
                                                }}
                                                value={state.firstName}
                                                disabled={state.loading}
                                            />
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Last name"
                                                onChange={(e) => {
                                                    if (!(e.target.value.length > 30))
                                                        setState({ ...state, lastName: e.target.value })
                                                }}
                                                value={state.lastName}
                                                disabled={state.loading}
                                            />
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Email"
                                                onChange={(e) => {
                                                    if (!(e.target.value.length > 320))
                                                        setState({ ...state, email: e.target.value })
                                                }}
                                                value={state.email}
                                                disabled={state.loading}
                                            />
                                            {errorMessage && errorMessage === "The e-mail address is invalid." && validateEmail(state.email) === false && <div className="error-message">{errorMessage}</div>}
                                            <textarea
                                                type="text"
                                                className="form-text-area form-control"
                                                placeholder="Message"
                                                onChange={(e) => setState({ ...state, message: e.target.value })}
                                                value={state.message}
                                                disabled={state.loading}
                                            />
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_SITE_KEY}
                                                ref={captchaRef}
                                                hl="en"
                                            />


                                            <button className="btn btn-primary text-nowrap">
                                                Send
                                            </button>
                                        </div>
                                        {errorMessage && errorMessage !== "The e-mail address is invalid." && <div className="error-message">{errorMessage}</div>}
                                    </form>
                                </div>

                                <div className="contact-top-box">
                                    <div className="mail-box-title">
                                        <img src={localisationIcon} className="mail-icon" />
                                        <p>Address</p>
                                    </div>
                                    <p className="mt-2 sub-text">You can also contact us by mail at the following address <a href="mailto:contact@lyber.com" target="_blank">274 ter/3, Avenue de la Marne, 59700 Marcq-en-Baroeul, Lille, France.</a></p>
                                </div>
                                <p className="text-center contact-bottom-text">The information collected on this form is necessary for LYBER and its service providers to process and follow up on your request. You may exercise your right of access, rectification, and objection on legitimate grounds at any time, in accordance with the regulations governing personal data. To do so, please send your request, along with proof of identity, by mail to LYBER - 274 ter/3, Avenue de la Marne, 59700 Marcq-en-Baroeul, Lille, France, or contact the Data Protection Officer (DPO) by email at the following address: <a href="mailto:dpo@lyber.com" target="_blank">dpo@lyber.com</a>. You may also refer to our privacy policy.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Modal
                        show={showInvalid}
                        onHide={handleCloseInvalid}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header>
                            <Modal.Title>An error has occurred.</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-3 text-center">
                                <div>
                                    Please try again later...
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseInvalid} className="px-6 confirm-btn">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={showMissingField}
                        onHide={handleCloseMissingField}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header>
                            <Modal.Title>Missing information(s).</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-3 text-center">
                                <div>
                                    One or more details are missing. Please complete them before trying again.
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseMissingField} className="px-6 confirm-btn">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={showMissingCaptcha}
                        onHide={handleCloseMissingCaptcha}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header>
                            <Modal.Title>You must validate the CAPTCHA.</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-3 text-center">
                                <div>
                                    To send us a message, you must validate the CAPTCHA to prove you're not a robot.
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseMissingCaptcha} className="px-6 confirm-btn">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={showSuccessful}
                        onHide={handleCloseSuccessful}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header>
                            <Modal.Title>Message sent.</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-3 text-center">
                                <div>
                                    Thank you for contacting support. We'll get back to you within 24 hours.
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseSuccessful} className="px-6 confirm-btn">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </section>
        </>
    );
};
