import { useEffect } from "react";
import { Table } from "react-bootstrap"

export const TermsAndConditionFR = () => {
    useEffect(() => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }, []);
    return (
        <>
            <section className="termconditons pb-5 mb-3">
                <div className="container">
                    <h2 className="term-heading text-center mt-lg-4 mt-2">Conditions Générales de Vente - LYBER</h2>
                    <div className="termcondition-logo">
                        <div className="inner-condition">
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">1.</span>Définitions
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Client </li>
                                <li>
                                    Désigne toute personne physique ou morale ayant un Compte Client enregistré auprès de Lyber.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">AMF </li>
                                <li>
                                    Autorité des Marchés Financiers
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">ACPR </li>
                                <li>
                                    Autorité de Contrôle Prudentiel et de Résolution
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">CMF </li>
                                <li>
                                    Code Monétaire et Financier
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Compte Client</li>
                                <li>
                                    Désigne le compte permettant à un Utilisateur d'utiliser les Services fournis par Lyber et d'ouvrir un Portefeuille Interne.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Conditions Générales de Vente ou CGV</li>
                                <li>
                                    Désigne les présentes Conditions Générales de Vente, lesquelles constituent un accord juridiquement contraignant entre les Utilisateurs/Clients et Lyber, régissant l'accès et l'utilisation par les Utilisateurs/Clients de la Plateforme et de tous les Services associés.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Contrat /<br /> convention client</li>
                                <li>
                                    Désigne le contrat-cadre de services de paiement signé entre les Utilisateurs et Lyber.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Actif numérique</li>
                                <li>
                                    Un actif numérique est un actif constitué par des données numériques, dont la propriété ou le droit d'usage est un élément du patrimoine d'une personne physique ou morale.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Crypto Monnaie / crypto actif</li>
                                <li>
                                    Désigne tout actif numérique conçu pour fonctionner comme un moyen d'échange sur une blockchain et accepté en tant que moyen de paiement ou d’échange dans l'ensemble des Services.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Monnaie FIAT</li>
                                <li>Désigne une monnaie dont la valeur provient essentiellement du fait qu’un gouvernement impose son cours légal sur un territoire donné à travers une banque centrale. Au regard de l'article L111-1 du Code monétaire et financier, « la monnaie de la France est l'euro ». Depuis l’an 2000, l’euro est la monnaie fiat dans la plupart des pays de l’Union Européenne.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Jour Ouvrable</li>
                                <li>
                                    Désigne tous les jours de la semaine, à l'exception du jour de repos hebdomadaire (généralement le dimanche) et des jours fériés habituellement non travaillés.
                                </li>
                            </ul>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Lyber</li>
                                <li>Désigne Lyber, une société par actions simplifiée au capital de 2809,52 €, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 903 241 925, dont le siège social est situé 15 rue des Halles, 75001 Paris.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Ordre</li>
                                <li>
                                    Désigne le consentement d'un client à l’exécution d’une Transaction.
                                </li>
                            </ul>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Partenaires</li>
                                <li>Désigne les prestataires de services accompagnant Lyber dans l’accomplissement de ses obligations en matière de LCB-FT.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Parties</li>
                                <li>
                                    Désigne Lyber et l’Utilisateur ou le Client.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4"> Plateforme</li>
                                <li>
                                    Désigne l’application mobile et le site internet lyber.com y compris tous ses sous-domaines, et toute plateforme sur laquelle les services visés dans les CGV sont disponibles.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Portefeuille Externe</li>

                                <li>
                                    Désigne le portefeuille personnel non custodial de l'Utilisateur contenant les Cryptomonnaies de l'Utilisateur, n’étant pas détenu par Lyber mais identifié comme disponible pour recevoir des dépôts.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Portefeuille Interne</li>

                                <li>
                                    Désigne le portefeuille custodial contenant les Cryptomonnaies de l'Utilisateur, conservé, détenu et géré par Lyber pour le compte de l'Utilisateur, par l'intermédiaire du Prestataire de Conservation.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Prestataire de <br />Conservation</li>
                                <li>
                                    Fournisseur d’une solution technique de conservation des actifs numériques à Lyber.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Prestataire de <br />Liquidité</li>
                                <li>
                                    Fournisseur de liquidité à Lyber.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">PSAN </li>
                                <li>
                                    Désigne le statut réglementaire de Prestataire de Services sur Actifs Numériques.

                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">KYC</li>
                                <li>Désigne la procédure d'identification et de vérification des utilisateurs de LYBER</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">LCB-FT</li>
                                <li>Lutte contre le Blanchiment des capitaux et le Financement du Terrorisme</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Services</li>
                                <li>
                                    Désigne l'ensemble des services accessibles sur la Plateforme avec un Compte Client, tel que défini à la section 5.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Tiers</li>
                                <li>
                                    Désigne toute personne, physique ou morale, en dehors de Lyber ou des Utilisateurs/Clients.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Token LYB</li>
                                <li>
                                    Désigne un jeton numérique émis par Lyber, utilisé pour accéder à certains services ou fonctionnalités spécifiques.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Transactions</li>
                                <li>Désigne l'action de transférer ou de retirer des fonds vers ou depuis un Portefeuille Interne, un Portefeuille Externe et/ou un compte bancaire.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Frais de gaz / fees</li>
                                <li>
                                    Désigne les commissions que l’on verse aux mineurs ou validateurs qui effectuent la validation d'une transaction donnée sur la blockchain.
                                </li>
                            </ul>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Utilisateur</li>
                                <li>Désigne toute personne physique ou morale navigant sur la Plateforme sans être Client.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Virement entre Ami</li>
                                <li>
                                    Désigne les virements réalisés entre Utilisateurs vérifiés par KYC.
                                </li>
                            </ul>



                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">2.</span>Acceptation des risques
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>1.</small>
                                </li>
                                <li>
                                    <b>Acceptation des CGV.</b> Conformément au droit applicable, il est possible de vérifier à tout moment l'enregistrement PSAN de Lyber sur le site <a href="https://www.amf-france.org.">www.amf-france.org.</a>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>2.</small>
                                </li>
                                <li>
                                    Les présentes CGV régissent l'ouverture d'un Compte Client au nom de chaque Utilisateur et s'appliquent, sans restriction ni réserve, à toute utilisation des Services proposés sur la Plateforme. Afin de pouvoir utiliser les Services, les Utilisateurs s'engagent à lire et à accepter, sans condition ni réserve, les présentes CGV.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>3.</small>
                                </li>
                                <li>
                                    Ces CGV sont accessibles à tout moment sur la Plateforme et prévaudront sur toute autre version ou tout autre document contradictoire. Les Utilisateurs sont invités à lire attentivement les présentes CGV avant de les accepter.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>4.</small>
                                </li>
                                <li>
                                    Chaque Utilisateur reconnaît avoir, dans son pays, la capacité de contracter et de s'abonner à la Plateforme et déclare avoir lu et accepté sans restriction ni réserve les CGV en cochant la case correspondante lors de la création d'un Compte Client sur la Plateforme.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>5.</small>
                                </li>
                                <li>
                                    LYBER fournit des services de paiement au nom et pour le compte de Stripe. A cet effet, chaque Utilisateur reconnaît et accepte les présentes CGV.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>6.</small>
                                </li>
                                <li>
                                    <b>Modification des CGV. </b> Les présentes CGV peuvent faire l'objet de modifications ultérieures, selon les modalités décrites à la section 12. La version applicable des CGV est celle en vigueur sur la Plateforme à la date de création du Compte Client.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>7.</small>
                                </li>
                                <li>
                                    <b>Risques associés aux Services.</b> Les Clients reconnaissent avoir effectué des recherches personnelles avant de souscrire aux Services. Les Services fournis par Lyber sont liés aux actifs numériques et à la technologie blockchain, ce qui entraîne plusieurs conséquences.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>8.</small>
                                </li>
                                <li>
                                    D'une part, les Utilisateurs sont conscients des risques économiques liés aux Services. Ils reconnaissent à ce titre que les marchés d’actifs numériques sont décentralisés, que les Services ne conviennent pas à de nombreux investisseurs et représentent un niveau élevé de risque de perte de capital notamment lié à l'extrême volatilité des marchés.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>9.</small>
                                </li>
                                <li>
                                    Par conséquent, bien que le risque de perte ne dépasse pas le montant investi, les Utilisateurs s'engagent à ne pas investir des fonds qu'ils ne peuvent pas se permettre de perdre. Par ailleurs, ils reconnaissent que les performances passées ne sont en aucun cas une indication des performances futures, lesquelles peuvent varier aussi bien à la hausse qu’à la baisse.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>10.</small>
                                </li>
                                <li>
                                    D'autre part, les Utilisateurs sont conscients des risques techniques liés aux Services offerts, y compris, les risques liés à une erreur ou une défaillance de sécurité, associée ou non à la blockchain, pouvant entraîner une perte de données ou d'accès aux actifs numériques conservés.
                                </li>
                            </ul>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>11.</small>
                                </li>
                                <li>
                                    Enfin, les Clients sont conscients que la fourniture des Services ne constitue en aucun cas un conseil ou une recommandation sur l'opportunité, les modalités ou les conséquences d'une Transaction.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>12.</small>
                                </li>
                                <li>
                                    <b>Risques liés à la fraude et à l’escroquerie. </b>Ces risques sont existants tels que le phishing / hameçonnage / filoutage massif, arnaque au faux conseiller, au faux support technique, au faux virement, au faux RIB, etc. Lyber recommande à tous ses Clients de ne jamais divulguer leurs identifiants et leurs mots de passe ainsi que d’effectuer la mise à jour de manière régulière.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>13.</small>
                                </li>
                                <li>
                                    <b>Limitation territoriale. </b>L’accès aux produits et services présentés sur le site peut faire l’objet de restrictions à l’égard de certaines personnes ou dans certains pays. En tout état de cause, aucun des produits et / ou services ne pourra être fourni par LYBER à une personne physique ou morale, si la loi de son pays d’origine ou de tout autre pays ne l’autorise pas ou bien l’interdit.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">3.</span>Navigation sur la Plateforme
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>14.</small>
                                </li>
                                <li>
                                    Le site internet Lyber est disponible gratuitement pour tout Utilisateur sans nécessiter la création d'un Compte Client.
                                </li>
                            </ul>


                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>15.</small>
                                </li>
                                <li>
                                    Sur le site internet, Lyber communique toutes les informations nécessaires à la compréhension du fonctionnement des Services par les Utilisateurs.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>16.</small>
                                </li>
                                <li>Les informations et/ou données fournies sur la Plateforme pour les Utilisateurs et les Clients constituent uniquement des indicateurs et ne doivent en aucun cas être interprétées comme une incitation à prendre une quelconque décision. </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>17.</small>
                                </li>
                                <li>Il est possible de naviguer sur la plateforme de Lyber via les applications mobiles. Pour les mobiles sous IOS, l’application est disponible dans Apple Store. Et concernant les mobiles sous Android, l’application est disponible dans Google Play Store (prochainement).</li>
                            </ul>

                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">4.</span>Comptes
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>18.</small>
                                </li>
                                <li>
                                    Afin de bénéficier des Services fournis par Lyber, les Utilisateurs doivent ouvrir un Compte Client.
                                </li>
                            </ul>


                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>19.</small>
                                </li>
                                <li>
                                    Sauf autorisation spécifique, les Utilisateurs ne peuvent pas créer plusieurs Comptes Clients.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>20.</small>
                                </li>
                                <li>
                                    Les Comptes sont accessibles 7 jours sur 7 et 24 heures sur 24, sauf en cas d'opérations de maintenance ou de mises à jour.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">4.1</span>Création d’un Compte Client
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>21.</small>
                                </li>
                                <li>
                                    <b>Création.</b> Pour utiliser la Plateforme (tel que décrit ci-dessous), l'Utilisateur est invité à créer un Compte Client personnel et unique en:
                                    <ul>
                                        <li>- renseignant son nom d'utilisateur et son adresse e-mail ;  </li>
                                        <li>- choisissant un mot de passe (fort) ;</li>
                                        <li>- se conformant aux exigences de lutte contre le blanchiment des capitaux et le financement du terrorisme (exigences LCB-FT) ; </li>
                                        <li>- acceptant les présentes CGV ainsi que la politique de confidentialité.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>22.</small>
                                </li>
                                <li>
                                    Lyber informe les Utilisateurs que l'ouverture d'un Compte est soumise à des exigences d'identification LCB-FT, éventuellement par l'intermédiaire d'un Partenaire. Lyber peut refuser l'ouverture d’un Compte pour toute raison légale, sans justification. Le cas échéant, ce refus ne donnera lieu à aucun dommages et intérêts.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>23.</small>
                                </li>
                                <li>
                                    Les Utilisateurs s'engagent à fournir des informations exactes et précises s’agissant de leur état civil et de leurs coordonnées.
                                </li>
                            </ul>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>24.</small>
                                </li>
                                <li>
                                    <b>Spécificité citoyen américain (US-PERSON). </b>En application de la Foreign Account Tax Compliance Act (FATCA), toute personne de nationalité américaine résidant en France et dans l’Union Européenne utilisant la présente plateforme se doit d’en informer Lyber.
                                </li>

                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>25.</small>
                                </li>
                                <li>
                                    La vérification d’identité à une durée maximum de 48h sous réserve de la bonne conformité des documents reçus. Toutefois, les utilisateurs ont la possibilité d’explorer l’application Lyber sans pour autant bénéficier des services de LYBER. Pour bénéficier des services de LYBER, l’utilisateur verra apparaître un popup lui demandant de signer électroniquement le contrat de Prestataire de Service sur Actif Numérique.
                                </li>

                            </ul>

                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">4.2</span>Sécurité des Comptes
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>26.</small>
                                </li>
                                <li>
                                    Les Utilisateurs ne doivent en aucun cas divulguer leurs identifiants et mots de passe, lesquels sont strictement personnels et confidentiels. Les Utilisateurs s'engagent à prendre toutes les mesures nécessaires pour sécuriser l'accès à l'identifiant et au mot de passe de leur Compte. Pour accéder aux services, l’Utilisateur est dans l’obligation de respecter l’authentification à deux facteurs (2FA).
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>27.</small>
                                </li>
                                <li>
                                    Chaque Utilisateur est responsable des agissements de toute personne utilisant son Compte, y compris sans accord préalable. Chaque Utilisateur s'engage à signaler toute utilisation frauduleuse de son Compte, de son identifiant ou de son mot de passe à contact@lyber.com dans les meilleurs délais.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>28.</small>
                                </li>
                                <li>
                                    Chaque Utilisateur est entièrement responsable de l'utilisation de ses Comptes et est, jusqu'à preuve du contraire, réputé être l'auteur de toutes les instructions données sur le Compte client, et plus généralement de tous les événements et modifications survenant sur son Compte.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>29.</small>
                                </li>
                                <li>
                                    Chaque Utilisateur déclare expressément avoir la capacité et/ou avoir reçu les autorisations requises pour utiliser les Services fournis par Lyber et garantit Lyber contre toute responsabilité du fait d'une fausse déclaration.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>30.</small>
                                </li>
                                <li>
                                    Lyber ne saurait être responsable des erreurs contenues dans les informations de l'Utilisateur, de la perte ou du vol d'un mot de passe par l'Utilisateur, ni de l'usurpation de Compte qui en résulte.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">4.3</span>Blocage, fermeture et gel des avoirs
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>31.</small>
                                </li>
                                <li>
                                    <b>Blocage des Comptes. </b>Le blocage temporaire et immédiat d'un Compte Client peut être effectué par Lyber pour n'importe quelle raison, à sa seule discrétion, et en particulier:
                                    <ul>
                                        <li>- si le Client n'a pas respecté les dispositions des présentes CGV ;</li>
                                        <li>- si le Client a fourni à Lyber des données d'identification inexactes, obsolètes ou incomplètes ;</li>
                                        <li>- si le Client perd son nom d'utilisateur et/ou son mot de passe, ou en cas de suspicion d'utilisation frauduleuse (notamment usurpation d'identité) ;</li>
                                        <li>- si le Client est suspecté de fraude ou enfreint de quelque manière que ce soit les exigences en matière de LCB-FT ;</li>
                                        <li>- si Lyber reçoit un certain nombre de réclamations relatives à des Transactions non autorisées.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>32.</small>
                                </li>
                                <li>
                                    Dans de tels cas, Lyber se réserve le droit (i) d'appliquer des pénalités et des frais de gestion au Client et/ou (ii) de réclamer des dommages et intérêts audit Client.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>33.</small>
                                </li>

                                <li>
                                    Les Clients concernés seront informés de cette décision par tout moyen. Celle-ci ne donnera lieu à aucun dommages et intérêts.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>34.</small>
                                </li>
                                <li>
                                    La réactivation d’un Compte bloqué se fera à la discrétion de Lyber, sur la base des informations ou documents complémentaires qui pourraient être demandés.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>35.</small>
                                </li>
                                <li>
                                    <b>Fermeture des Comptes. </b> Le Client peut, à tout moment, demander la suppression définitive de son Compte Client personnel dans les conditions prévues sur la Plateforme en écrivant à contact@lyber.com, ce qui implique la suppression de toutes ses données, y compris les données personnelles du Client au sens de la Politique de confidentialité. La Plateforme ne pourra en aucun cas être tenue responsable d'une quelconque perte.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>36.</small>
                                </li>
                                <li>
                                    Lyber se réserve le droit de fermer un Compte Client en cas de violation des présentes CGV, sans préavis ni indemnité.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>37.</small>
                                </li>
                                <li>
                                    <b>Gel des avoirs. </b>En application des mesures de LCB-FT mises en œuvre par Lyber, les fonds du Compte client peuvent être gelés en cas d’inscription du Client sur le registre national français des mesures de gel des avoirs.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>38.</small>
                                </li>
                                <li>
                                    Dès que la mesure de gel des avoirs est abrogée, annulée ou suspendue par une autorité ou une juridiction compétente, ou lorsqu'elle a expiré, Lyber lève toutes les restrictions appliquées aux Transactions et aux avoirs du Client concerné et met en œuvre les mesures de vigilance appropriées.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">5.</span>Services
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>39.</small>
                                </li>
                                <li>
                                    <p>Sous réserve de satisfaire aux exigences en matière de LCB-FT mentionnées ci-dessus, les Clients disposant de Comptes validés peuvent souscrire aux Services suivants proposés par Lyber:</p>
                                    <ul>
                                        <li><b>- Achat de Cryptomonnaies en Euros (5.1.);</b></li>
                                        <li><b>- Vente de Cryptomonnaies contre des Euros (5.2.);</b></li>
                                        <li><b>- Echange de Cryptomonnaies contre d'autres Cryptomonnaies (5.3.)</b></li>
                                        <li><b>- Conservation de Cryptomonnaies (5.4.); et</b></li>
                                        <li><b>- Stratégie d’investissement (5.5).</b></li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>40.</small>
                                </li>
                                <li>
                                    Tous les Services fournis par Lyber entrent dans le champ d'application de l'article L.54-10-2 du code monétaire et financier français. Lyber est enregistrée auprès de l'Autorité des marchés financiers (AMF) en tant que PSAN, pour l'ensemble de ces activités, sous le numéro E2022-039.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>41.</small>
                                </li>
                                <li>
                                    Les Crypto-actifs acceptés et offerts par LYBER sont les suivants:
                                </li>

                            </ul>

                            <Table hover striped className="roundedTable mb-5">
                                <thead>
                                    <tr>
                                        <th className="purple-light text-center" colSpan="3">Crypto Actifs</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    <tr>
                                        <td className="light-black">Bitcoin (BTC)</td>
                                        <td className="light-black">Cardano (ADA)</td>
                                        <td className="light-black">Litecoin (LTC)</td>
                                    </tr>
                                    <tr>
                                        <td className="light-black">Ether (ETH)</td>
                                        <td className="light-black">Dogecoin (Doge)</td>
                                        <td className="light-black">Polygon (MATIC)</td>
                                    </tr>
                                    <tr>
                                        <td className="light-black">Binance Coin (BNB)</td>
                                        <td className="light-black">Ripple (XRP)</td>
                                        <td className="light-black">Pepe (PEPE)</td>
                                    </tr>
                                    <tr>
                                        <td className="light-black">ChainLink (LINK)</td>
                                        <td className="light-black">Polkadot (DOT)</td>
                                        <td className="light-black">Solana (SOL)</td>
                                    </tr>
                                    <tr>
                                        <td className="light-black">Fantom (FTM)</td>
                                        <td className="light-black">Shiba (SHIB)</td>
                                        <td className="light-black">Avalanche (AVAX)</td>
                                    </tr>
                                    <tr>
                                        <td className="light-black">Atom (ATOM)</td>
                                        <td className="light-black">Stellar (XLM)</td>
                                        <td className="light-black">MultiversX (EGLD)</td>
                                    </tr>
                                    <tr>
                                        <td className="light-black">Tether (USDT)</td>
                                        <td className="light-black">Circle (USDC)</td>
                                        <td className="light-black"></td>
                                    </tr>
                                </tbody>
                            </Table>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>42.</small>
                                </li>
                                <li>
                                    Lyber n'accepte aucun actif numérique anonyme.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>43.</small>
                                </li>
                                <li>
                                    Retrait minimum: les quantités minimales de retrait varient d’un actif numérique à une autre en raison de la variation de leur prix. Pour l’achat et la vente d’actifs numériques la quantité minimale est de 20,00€. Concernant l’échange d’actif numérique, la quantité minimale est de 1,00€.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>44.</small>
                                </li>
                                <li>
                                    La Société a la possibilité de procéder au retrait d’un actif numérique de la liste des actifs proposés sur la plateforme LYBER. Le cas échéant, la Société est tenue d’indiquer, par tout moyen écrit au Client et à minima 7 jours avant le retrait, son intention de le retirer  de la liste des actifs proposés par LYBER.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>45.</small>
                                </li>
                                <li>
                                    La responsabilité de la Société ne saurait être engagée en cas de non-retrait de l’actif concerné de son Portefeuille dans le délai imparti.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>46.</small>
                                </li>
                                <li>
                                    Lyber a mis en place une “fenêtre de vérification des retraits” entre 8 heures et 19 heures pendant laquelle le personnel de LYBER vérifie les transactions. Les opérations concernées sont les opérations de dépôt d’actifs numériques, de retraits d’actifs numériques et de retrait de fonds fiat vers un compte bancaire. L’Utilisateur aura toujours la possibilité de réaliser des retraits de fonds à tout moment. L’Utilisateur est informé que la Société pourra vérifier les transactions à tout moment entre 8 heures et 19 heures (GMT+2), sept jours par semaine. Enfin, l’Utilisateur est informé que deux employés de LYBER seront chargés d’accepter les transactions de retrait de 10 heures à 19 heures (GMT+2) le samedi et le dimanche.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.1</span>Achat de Cryptomonnaies en Euros (fiat2crypto)
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>47.</small>
                                </li>
                                <li>
                                    Description. Tout Client ayant des Comptes validés dans les conditions prévues à la section 4, acquérir des cryptoactifs via notre Prestataire de Service de Paiement au travers de l’application mobile de LYBER contre un paiement en euros par un moyen de paiement accepté par le Prestataire de Service de Paiement en utilisant le service Apple Pay ou Google Pay selon la nature du mobile.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>48.</small>
                                </li>
                                <li>
                                    L'Ordre doit contenir les informations suivantes:
                                    <ul>
                                        <li>- le montant en Euros que le Client souhaite convertir ;</li>
                                        <li>- la Cryptomonnaie que le Client veut acheter ;</li>
                                        <li>- l'adresse du Portefeuille Interne du Client.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>49.</small>
                                </li>
                                <li>Aux fins d’exécution d’un ordre par le Client, il lui est laissé une durée de 10 à 30 secondes pour valider l’Ordre et effectuer la transaction. A compter de la validation de l’Ordre, ce dernier est transmis à LYBER pour exécution.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>50.</small>
                                </li>
                                <li>
                                    Une fois acquis par Lyber pour le compte du Client, par l'intermédiaire du Fournisseur de Liquidité, le montant de Cryptomonnaies concerné sera crédité sur le Portefeuille Interne du Client.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>51.</small>
                                </li>
                                <li>
                                    Ensuite, si le Client le demande, les Cryptomonnaies seront transférées depuis son Portefeuille Interne vers son Portefeuille Externe
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.2</span>Vente de Cryptomonnaies contre des Euros (crypto2fiat)
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>52.</small>
                                </li>
                                <li>
                                    <b>Description.</b> Tout client ayant un compte validé dans les conditions prévues à la section 4, peut vendre ses cryptoactifs via l’application mobile de LYBER contre un paiement en euros effectué par virement bancaire par LYBER sur le compte personnel du client.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>53.</small>
                                </li>
                                <li>
                                    L'Ordre doit contenir les informations suivantes:
                                    <ul>
                                        <li>
                                            - la catégorie de Crypto Monnaies que le Client souhaite vendre ;
                                        </li>
                                        <li>
                                            - L’identifiant du compte bancaire (IBAN) du client avec son nom et prénom identique à celui du compte client de Lyber.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>54.</small>
                                </li>
                                <li>Aux fins d’exécution d’un ordre par le Client, il lui est laissé une durée de 10 à 30 secondes pour valider l’Ordre et effectuer la transaction. A compter de la validation de l’Ordre, ce dernier est transmis à LYBER pour exécution.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>55.</small>
                                </li>
                                <li>
                                    Une fois échangé par Lyber pour le compte du Client, par l'intermédiaire du Fournisseur de Liquidités, le montant en Euros correspondant sera crédité sur le Compte Personnel du client.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>56.</small>
                                </li>
                                <li>
                                    Lors d'un Ordre ultérieur du Client, les Euros seront alors transférés de son Compte de Paiement vers son compte bancaire personnel.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.3</span>Echange de Cryptomonnaies contre d'autres Cryptomonnaies (crypto2crypto)
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>57.</small>
                                </li>
                                <li>
                                    <b>Description. </b>Tout Client ayant des Comptes validés dans les conditions prévues à la section 4, peut choisir d’échanger des Cryptomonnaies contre d’autres Cryptomonnaies. Pour ce faire, le Client doit (i) approvisionner le Portefeuille de Lyber en Cryptomonnaies, soit à partir de son Portefeuille Interne, soit à partir de son Portefeuille Externe et (ii) initier l'Ordre à Lyber en précisant dans quelles Cryptomonnaies il souhaite que l'échange soit effectué.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>58.</small>
                                </li>
                                <li>
                                    L'Ordre doit contenir les informations suivantes:
                                    <ul>
                                        <li>- la Cryptomonnaie que le Client souhaite échanger ;</li>
                                        <li>- la Cryptomonnaie que le Client souhaite recevoir ;</li>
                                        <li>- l'adresse du Portefeuille Interne du Client.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>59.</small>
                                </li>
                                <li>Aux fins d’exécution d’un ordre par le Client, il lui est laissé une durée de 10 à 30 secondes pour valider l’Ordre et effectuer la transaction. A compter de la validation de l’Ordre, ce dernier est transmis à LYBER pour exécution.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>60.</small>
                                </li>
                                <li>
                                    Une fois échangé par Lyber pour le compte du Client, par l'intermédiaire du Fournisseur de Liquidités, le montant en Cryptomonnaie correspondant sera crédité sur le Portefeuille Interne du Client.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>61.</small>
                                </li>
                                <li>
                                    Si le Client le demande, les Cryptomonnaies pourront être transférées de son Portefeuille Interne vers son Portefeuille Externe.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.4</span>Conservation de Cryptomonnaies
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>62.</small>
                                </li>
                                <li>
                                    <b>Description. </b>Tout Client ayant des Comptes validés dans les conditions prévues à la section 4, peut choisir de conserver ses Cryptomonnaies dans son Portefeuille Interne, détenu par Lyber par l'intermédiaire du Prestataire de Conservation (le <b>Service de Conservation</b>").
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>63.</small>
                                </li>
                                <li>
                                    Les Clients sont libres d'utiliser leur Portefeuille Interne pour exécuter des Transactions dans le cadre de l'utilisation des Services. Ils peuvent à tout moment créditer ou débiter leur Portefeuille Interne à partir d'un Portefeuille Externe avec leurs propres Cryptomonnaies.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>64.</small>
                                </li>
                                <li>
                                    Le solde du Portefeuille Interne du Client peut être consulté sur la Plateforme par le Client uniquement.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>65.</small>
                                </li>
                                <li>
                                    <b>Performance. </b>Tout Client utilisant le Service de Conservation autorise expressément Lyber à gérer les Cryptomonnaies conservées sur son Portefeuille Interne, pendant toute la durée du Service de Conservation.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>66.</small>
                                </li>
                                <li>
                                    <b>Responsabilité. </b>Lyber est entièrement responsable de tout manquement ou défaillance du Prestataire de Conservation affectant le Portefeuille Interne du Client.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>67.</small>
                                </li>
                                <li>
                                    <b>Restitution. </b>Lyber conservera les Cryptomonnaies du Client sur son Portefeuille Interne jusqu'à ce que:
                                    <ul>
                                        <li>- le Client demande le retrait des Cryptomonnaies sur son Portefeuille Externe ; ou</li>
                                        <li>- le Client demande la conversion des Cryptomonnaies en Euros pour un transfert sur son compte bancaire ; ou</li>
                                        <li>- le Compte Client soit fermé à la demande du Client ; ou</li>
                                        <li>- les présentes CGV soient résiliées conformément aux dispositions prévues à la section 13.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>68.</small>
                                </li>
                                <li>
                                    A la demande du Client, Lyber lui restituera les Cryptomonnaies conservées, dans les meilleurs délais, sauf si Lyber est confronté à un événement échappant à sa responsabilité, par exemple si:
                                    <ul>
                                        <li>- Lyber a bloqué ou fermé le Compte Client pour un motif légitime ;</li>
                                        <li>- Lyber doit effectuer des vérifications concernant le client ;</li>
                                        <li>- Lyber a des raisons de croire que la demande de restitution n'émane pas du Client ;</li>
                                        <li>- Une autorité compétente refuse la restitution ;</li>
                                        <li>- Lyber a été victime d'une tentative d'attaque, d'une attaque, d'un virus ;</li>
                                        <li>- La Plateforme est en maintenance ;</li>
                                        <li>- Le Client est injoignable pendant plus de six (6) mois.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.5</span>Stratégies d’investissement
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>69.</small>
                                </li>
                                <li>
                                    Lyber propose à ses utilisateurs des stratégies d’investissement.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>70.</small>
                                </li>
                                <li>
                                    Ces stratégies ne représentent en aucun cas un conseil en investissement.
                                    Les stratégies permettent aux utilisateurs de passer des ordres d’achat de manière récurrente et automatique. Pour ce faire, il faut impérativement que l’utilisateur dispose des fonds pour activer les stratégies. Le minimum d’investissement est de 10 USDT par actif. Plus le nombre d’actifs est élevé dans une stratégie, plus le minimum d’investissement le sera aussi.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>71.</small>
                                </li>
                                <li>
                                    2 stratégies sont à disposition:
                                    <ul>
                                        <li>- Safe: la stratégie la moins risquée qui dispose de 2 actifs, l’exposition à la volatilité est faible</li>
                                        <li>- Intermediate: une stratégie plus risquée disposant de 4 actifs avec une exposition à la volatilité plus élevée</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>72.</small>
                                </li>
                                <li>
                                    De plus, chaque utilisateur à la possibilité de créer sa propre stratégie d’investissement avec les actifs-numériques que la personne souhaite ainsi que la récurrence de son choix.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>73.</small>
                                </li>
                                <li>
                                    Chaque stratégie à la possibilité d’être mise en place des manières suivantes:
                                    <ul>
                                        <li>- Investissement unique: c’est à dire que l’utilisateur active une seule fois la stratégie et cette dernière ne sera plus jamais activée sauf si l’utilisateur le décide</li>
                                        <li>
                                            - Investissement récurrent:
                                            <ul>
                                                <li>
                                                    - Journalier: tous les jours l’utilisateur est débité pour activer la stratégie
                                                </li>
                                                <li>
                                                    - Hebdomadaire: tous les jeudis l’utilisateur est débité pour activer la stratégie
                                                </li>
                                                <li>
                                                    - Mensuel: tous les 21 du mois l’utilisateur est débité pour activer la stratégie
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.6</span>Compte inactif
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>74.</small>
                                </li>
                                <li>
                                    Lyber peut appliquer des frais de conservation à tout Portefeuille Interne inactif depuis plus de un (1) ans. Nous vous invitons à prendre connaissance de notre politique tarifaire.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>75.</small>
                                </li>
                                <li>
                                    De façon général, à l’issue d’une période de 12 mois consécutifs, un compte est considéré comme inactif dans deux cas de figure:
                                    <ul>
                                        <li>- S’il n’a enregistré aucune opération autre que celles initiées par LYBER (perception de frais et commissions, versement d’intérêts) ;</li>
                                        <li>- Si le titulaire ne s’est pas manifesté auprès de LYBER.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>76.</small>
                                </li>
                                <li>
                                    À l’issue d’un délai de 10 ans d’inactivité, LYBER informe le client concerné 6 mois avant par e-mail et transfère le solde des comptes inactifs à la Caisse des dépôts (CDC) sans réponse de l’utilisateur dans les delais impartis.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>77.</small>
                                </li>
                                <li>
                                    L’utilisateur a la possibilité de récupérer les avoirs d’un compte transféré à la Caisse des Dépôts via son site internet:  <a href="https://ciclade.caissedesdepots.fr/">https://ciclade.caissedesdepots.fr/</a>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>78.</small>
                                </li>
                                <li>
                                    Le site internet Ciclade permet à toute personne ainsi que ses ayants-droits de rechercher des sommes, non réclamées ou oubliées, transférées par Lyber à la Caisse des dépôts.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.7</span>Ordres
                                </strong>
                            </p>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>79.</small>
                                </li>
                                <li>
                                    Chaque Ordre doit contenir les informations pertinentes mentionnées ci-dessus. Lyber ne saurait être responsable si les détails fournis par le Client sont incorrects ou ne sont pas mis à jour.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>80.</small>
                                </li>
                                <li>
                                    Lyber se réserve le droit d'annuler une Transaction si le transfert par carte de crédit/débit est rejeté ou annulé par l'émetteur de la carte.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>81.</small>
                                </li>
                                <li>
                                    Les Transactions sont soumises à l'accord préalable du Client concerné. En l'absence d’un tel consentement, la Transaction ou la série de Transactions sera considérée comme non autorisée.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>82.</small>
                                </li>
                                <li>
                                    Le Client peut annuler son Ordre tant qu'il n'est pas irrévocable (i.e. tant que le Client n’a pas confirmé son Ordre dans le délai de 10 à 30 secondes imparti). Le consentement donné à l'exécution d'une série de Transactions peut être retiré. Le cas échéant, toute Transaction postérieure au retrait du consentement sera considérée comme non autorisée.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>83.</small>
                                </li>
                                <li>
                                    Les Clients qui souhaitent contester une Transaction qui n'a pas été exécutée doivent contacter Lyber à l'adresse contact@lyber.com dans les meilleurs délais après avoir pris connaissance de l'anomalie et au plus tard dans les treize (13) mois suivant l'enregistrement de la Transaction. Avant cette notification, en cas de Transaction non autorisée résultant de la perte ou du vol de l'instrument de paiement, le payeur supportera les pertes liées à l'utilisation de cet instrument, dans la limite de cinquante euros (50 €).
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>84.</small>
                                </li>
                                <li>
                                    <b>Limitations des montants. </b>Des limites de Transactions ou des blocages d'Ordres peuvent être activés à tout moment par Lyber en cas de risque de fraude. Toute Transaction susceptible de dépasser les limites applicables sera automatiquement rejetée par Lyber.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>85.</small>
                                </li>
                                <li>
                                    <b>Délais d’exécution. </b>Opérations de paiement en euro, lorsque les deux prestataires de services de paiement sont situés dans l’Espace Économique Européen, ou entraînant une seule conversion entre l’euro, le compte du bénéficiaire de l’opération de paiement est crédité au plus tard à la fin du premier jour ouvrable suivant le moment de réception de l’ordre par LYBER. Opération en actif numérique, le compte du bénéficiaire de l’opération de paiement est crédité au plus tard à la fin du premier jour ouvrable suivant le moment de réception de l’ordre par LYBER.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>86.</small>
                                </li>
                                <li>
                                    <b>Mise à disposition. </b>Lyber mettra le montant de l’opération de paiement dont le Client est bénéficiaire à sa disposition immédiatement après que son propre compte a été crédité lorsqu’elle ne nécessite pas de conversion.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>87.</small>
                                </li>
                                <li>
                                    <b>Date de valeur. </b>Lyber attribue à l’opération de paiement une date de valeur qui est la date de référence qu’elle utilise pour calculer les intérêts applicables aux fonds débités ou crédités sur le compte. Les dates de valeur appliquées par Lyber figurent à la plaquette de tarification.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">6.</span>Tarification
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>88.</small>
                                </li>
                                <li>
                                    Cette clause introduit les Conditions Générales et précise l'utilisation du token utilitaire émis par Lyber. Elle stipule que l'acceptation des Conditions Générales est une condition préalable à l'utilisation du token LYB.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>89.</small>
                                </li>
                                <li>
                                    En utilisant ou en acquérant le token LYB, l'utilisateur accepte d'être lié par les Conditions Générales de Vente. Si l'utilisateur n'est pas en accord avec ces conditions, il ne doit pas utiliser ou acquérir le token.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>90.</small>
                                </li>
                                <li>
                                    Le token LYB est un jeton utilitaire, ce qui signifie qu'il ne confère aucun droit de propriété, de vote, de dividende ou toute autre participation financière dans la société. Il est essentiellement un outil pour accéder à des services spécifiques.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>91.</small>
                                </li>
                                <li>
                                    Le token ne doit pas être considéré comme un investissement. Il est destiné à être utilisé exclusivement dans le cadre des services offerts par Lyber. Cette section décrit comment les tokens peuvent être acquis à savoir via une plateforme d'échange et une Initial Coin Offering (ICO).
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>92.</small>
                                </li>
                                <li>
                                    Le document d’information explique les utilisations possibles des tokens, comme l'accès à des services spécifiques et de l'obtention de réductions.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>93.</small>
                                </li>
                                <li>
                                    L'utilisateur doit utiliser les tokens conformément aux Conditions Générales de Ventes et à toutes les lois et réglementations applicables.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>94.</small>
                                </li>
                                <li>
                                    L'utilisateur est responsable de la sécurité de ses tokens et de son portefeuille numérique. Lyber ne peut être tenue responsable en cas de perte ou de vol de tokens. LYBER s'engage à fournir les services associés aux Tokens conformément aux présentes Conditions Générales et du white papper.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>95.</small>
                                </li>
                                <li>
                                    Lyber se réserve le droit de modifier, suspendre ou interrompre les services associés aux Tokens à tout moment et de façon générale avec un préavis de 30 jours ouvré.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>96.</small>
                                </li>
                                <li>
                                    Lyber ne pourra être tenue responsable des pertes ou dommages résultant de l'utilisation ou de l'incapacité à utiliser les Tokens, sauf en cas de faute intentionnelle ou de négligence grave de la part de Lyber.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>97.</small>
                                </li>
                                <li>
                                    Lyber ne garantit pas que les services associés aux Tokens seront disponibles en permanence ou exempts d'erreurs mais s’efforcera à maintenir les services.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>98.</small>
                                </li>
                                <li>
                                    Pour respecter les exigences de conformité et les obligations légales, vous attestez que les tokens LYB seront transférés exclusivement vers un wallet à votre nom et prénom.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>99.</small>
                                </li>
                                <li>
                                    Lyber met à disposition un document d’information (White Papper) portant sur les caractéristiques du Token LYB, les facteurs de risques du produit, les modalités d’acquisition et de vente du token LYB ainsi que les modalités de tarification.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>100.</small>
                                </li>
                                <li>
                                    LYBER recommande de consulter le white paper.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">7.</span>Tarification
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>101.</small>
                                </li>
                                <li>
                                    Les frais applicables aux produits et services de Lyber figurent dans la <a href="/pricing">politique tarifaire.</a>
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">8.</span>Non application du Code de la Consommation
                            </p>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>102.</small>
                                </li>
                                <li>
                                    Tout Client, personne physique agissant à des fins qui n’entrent pas dans le cadre de son activité commerciale, industrielle, artisanale ou agricole sera identifié comme consommateur au sens de l’article liminaire du Code de la consommation.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>103.</small>
                                </li>
                                <li>
                                    Le droit de rétractation du consommateur prévu par l'article L.221-18 du code de la consommation ne peut être exercé dans le cadre (i) de la fourniture d'un contenu numérique non fourni sur un support physique, et dont l'exécution a commencé après accord préalable exprès du consommateur et renoncement exprès à son droit de rétractation ; ou (ii) de la fourniture de biens ou de services dont le prix dépend de fluctuations sur le marché financier échappant au contrôle du professionnel et susceptibles de se produire pendant le délai de rétractation (article L. 221-28 du code de la consommation).
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>104.</small>
                                </li>
                                <li>
                                    En conséquence, en acceptant les présentes CGV, le Client déclare expressément renoncer à son droit de rétractation, préalablement à l’exécution des Services.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">9.</span>Responsabilité des Parties
                            </p>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">9.1</span>Responsabilité du Client
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>105.</small>
                                </li>
                                <li>
                                    Les Clients garantissent Lyber contre tout manquement excédant le risque prévisible, contre toute utilisation frauduleuse ou de mauvaise foi des Services, et contre toute erreur de montant envoyé sur leur Portefeuille Interne (compte client) ainsi que sur leur compte bancaire personnel dans le cadre d’une vente de cryptoactif.
                                    <div className="text-rectangle">Les clients s’engagent à indemniser, défendre et garantir Lyber contre toute réclamation, portée contre LYBER par un tiers et découlant de l’utilisation des services, à l’exception d’un manquement manifeste de la Société à ses obligations au titre des présentes.</div>
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>106.</small>
                                </li>
                                <li>
                                    Les Clients s'engagent en outre à indemniser et à dégager Lyber de toute responsabilité, perte, dommages, coûts, réclamations et/ou dépenses, y compris, mais sans s'y limiter, les honoraires d'avocat, que Lyber pourrait encourir ou devoir payer en raison de telles réclamations.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">9.2</span>Responsabilité de Lyber
                                </strong>
                            </p>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>107.</small>
                                </li>
                                <li>
                                    Lyber s'engage à exécuter ses obligations avec le soin normalement attendu d'un professionnel du même secteur d'activité et à respecter les usages professionnels en vigueur.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>108.</small>
                                </li>
                                <li>
                                    Les Services sont fournis « en l'état », à l'exclusion de toute garantie quelle qu'elle soit. Lyber décline toute responsabilité et toute obligation d'indemniser quelque personne que ce soit pour une perte ou un dommage causé par un Client ou par un Tiers.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>109.</small>
                                </li>
                                <li>
                                    Lyber s'engage à mettre en œuvre tous les moyens nécessaires pour assurer la meilleure fourniture des Services à ses Clients. Toutefois, Lyber ne peut être tenu qu'à une obligation de moyens.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>110.</small>
                                </li>
                                <li>
                                    Lyber ne garantit pas un accès continu et ininterrompu aux Services. Par conséquent, Lyber ne peut être tenu pour responsable de tout retard et/ou inaccessibilité totale ou partielle des Services dès lors qu'ils résultent de facteurs échappant à son contrôle.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>111.</small>
                                </li>
                                <li>
                                    Les Clients sont informés que Lyber pourra occasionnellement interrompre l'accès à tout ou partie des Services:
                                    <ul>
                                        <li>- pour permettre des réparations, des opérations de maintenance ou d'amélioration des fonctionnalités ;</li>
                                        <li>- en cas de suspicion de tentative de piratage, de détournement de fonds ou de tout autre risque de dommage ;</li>
                                        <li>- sur demande ou instruction de personnes autorisées ou d'autorités compétentes.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>112.</small>
                                </li>
                                <li>
                                    Lyber ne peut en aucun cas être tenu responsable des dommages résultant de telles suspensions. Dès la reprise normale du service, Lyber s'efforcera raisonnablement de traiter les Transactions en cours dans les meilleurs délais.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>113.</small>
                                </li>
                                <li>
                                    En outre, la responsabilité de Lyber ne saurait être retenue en cas:
                                    <ul>
                                        <li>- De refus d’un virement bancaire ; </li>
                                        <li>- D’une utilisation inhabituelle ou illégale des Services ;</li>
                                        <li>- D'un manquement délibéré ou d'une négligence grave d'un Client à ses obligations ou de toute faute de sa part ;</li>
                                        <li>- Des pannes ou dysfonctionnements des Services qui ne sont pas de la responsabilité de Lyber ; </li>
                                        <li>- De cyberattaques ; </li>
                                        <li>- De dommages indirects qui pourraient résulter des Services fournis par Lyber, tels que l’action dirigée par un Tiers contre un Client, le préjudice commercial, la perte de clientèle, le trouble commercial, le manque à gagner, la perte de données n'étant pas imputable à Lyber, la perte d'image de marque subie par un Client ou par un Tiers ;</li>
                                        <li>- De tout cas de force majeure tel que défini par l'article 1218 du code civil ou tout événement indépendant de la volonté de Lyber mais aussi toute mesure prise ou législation adoptée par les autorités françaises ou étrangères.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>114.</small>
                                </li>
                                <li>
                                    Ainsi, la responsabilité de Lyber ne pourra être engagée que si les Clients prouvent que (i) Lyber a agi de manière fautive et intentionnelle et (ii) exclusivement si ce comportement lui a causé un préjudice certain, personnel, direct et raisonnablement prévisible, sauf disposition légale ou réglementaire contraire.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>115.</small>
                                </li>
                                <li>
                                    Si Lyber est responsable d'une Transaction non exécutée, Lyber restituera sans délai le montant correspondant au Client et rétablira le Compte débité dans la position qui aurait été la sienne si la Transaction mal gérée n'avait pas eu lieu.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>116.</small>
                                </li>
                                <li>
                                    En tous cas, la responsabilité de Lyber ne pourra être engagée au-delà des montants facturés aux Clients par la Plateforme conformément aux CGV au cours des douze (12) mois précédant l'incident ou la série d'incidents, sauf disposition légale ou réglementaire contraire.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">10.</span>Confidentialité
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>117.</small>
                                </li>
                                <li>
                                    Lyber s'engage à maintenir la confidentialité des Transactions des Clients, sauf si un décret, une loi ou une décision de justice l'oblige à divulguer ces informations ou dans le cadre de toute autre demande liée aux obligations de lutte contre le blanchiment d’argent et de lutte contre le financement du terrorisme.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">11.</span>Données personnelles
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>118.</small>
                                </li>
                                <li>
                                    Certaines informations identifiant les Utilisateurs directement ou indirectement, sont traitées par Lyber lors de l'utilisation de la Plateforme.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>119.</small>
                                </li>
                                <li>
                                    Pour informer les Utilisateurs de manière transparente sur les finalités et modalités de traitement de leurs données personnelles, Lyber a mis en place une Politique de Confidentialité disponible sur la Plateforme. (<a href="https://www.lyber.com/privacy">https://www.lyber.com/privacy</a>)
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">12.</span>Preuve
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>120.</small>
                                </li>
                                <li>
                                    Les communications, y compris les réclamations au sens de la section 18.1, effectuées par courrier électronique sont des modes de communication valablement admis comme preuve par les Utilisateurs/Clients et Lyber.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>121.</small>
                                </li>
                                <li>
                                    Sauf preuve contraire, les données enregistrées dans le système informatique de Lyber constituent la preuve de toutes les Transactions ordonnées par les Clients.
                                </li>
                            </ul>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>122.</small>
                                </li>
                                <li>
                                    Toutes les informations enregistrées dans les bases de données informatiques de Lyber relatives aux Ordres, aux Transactions, aux dépôts en Euros, aux dépôts et/ou retraits en Cryptomonnaies ont, sauf preuve contraire, la même force probante qu'un document écrit sur support papier, tant en ce qui concerne leur contenu qu'en ce qui concerne la date et l'heure auxquelles l'information est donnée et/ou reçue. Ce suivi inaltérable, sûr et fiable est stocké et conservé dans les systèmes informatiques de Lyber.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>123.</small>
                                </li>
                                <li>
                                    Les documents de Lyber reproduisant ces informations, ainsi que les copies ou reproductions de documents ont la même valeur probante que l'original, jusqu'à preuve du contraire.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">13.</span>Modification
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>124.</small>
                                </li>
                                <li>
                                    Lyber se réserve le droit de modifier ou d'interrompre à tout moment l'accessibilité de tout ou partie des Services et/ou de la Plateforme.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>125.</small>
                                </li>
                                <li>
                                    Lyber se réserve le droit de modifier, à tout moment, tout ou partie des présentes CGV. L'utilisation des Services par les Clients vaut acceptation de la modification des CGV. La version applicable des CGV est la dernière version publiée à la date de création du Compte Client.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">14.</span>Résiliation
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>126.</small>
                                </li>
                                <li>
                                    En cas de modification de la loi ou de la réglementation applicable et de l'interprétation qui en est faite par l'autorité réglementaire compétente, affectant la capacité de Lyber ou de ses employés à exécuter les Services, les présentes CGV seront automatiquement résiliées.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>127.</small>
                                </li>
                                <li>
                                    La résiliation des présentes CGV entraîne la clôture des Comptes. La clôture d'un Compte ne peut donner lieu à aucune indemnisation, quel que soit le préjudice causé par la clôture de ce Compte. Sauf accord exprès de Lyber, le Client dont le Compte a été clôturé par Lyber n'est pas autorisé à en ouvrir un autre. Tout Compte ouvert en violation de cette disposition pourra être immédiatement clôturé par Lyber sans préavis.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>128.</small>
                                </li>
                                <li>
                                    Les Clients ne pourront plus initier d’Ordre à compter de la date effective de la résiliation. Toutefois, le Compte Client pourra être maintenu pendant une période de treize (13) mois afin de couvrir tout litige et toute réclamation ultérieure. Les Transactions initiées avant la date effective de résiliation ne seront pas affectées par la résiliation et seront exécutées selon les termes des présentes CGV.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>129.</small>
                                </li>
                                <li>
                                    Les fonds restants seront transférés sur le compte bancaire personnel de l'Utilisateur.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">15.</span>Propriété Intellectuelle
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>130.</small>
                                </li>
                                <li>
                                    Tous les droits de propriété intellectuelle liés à la Plateforme et aux Services, y compris l'ensemble des marques, logos, textes, logiciels, bases de données, noms, designs, thèmes, concepts, images et agencement de ceux-ci (collectivement les "Contenus") restent en tout temps de la titularité de Lyber, ses concédants et licenciés et sont protégés par le droit d'auteur, le droit des marques et tout autres droits de propriété intellectuelle applicable.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>131.</small>
                                </li>
                                <li>
                                    Aucune disposition des présentes CGV ne saurait être interprétée comme accordant, que ce soit aux Utilisateurs ou aux Clients, un droit quelconque sur les Contenus. Par conséquent, il est interdit aux Utilisateurs et aux Clients de copier, reproduire, afficher, distribuer ou exploiter de quelque manière que ce soit, en tout ou en partie, les Contenus, de décompiler ou essayer de décompiler les logiciels, d'extraire ou d’essayer d’extraire des données des bases de données, d’interférer ou de tenter d'interférer de quelque manière que ce soit avec les mesures de sécurité mises en place par Lyber, sans l'autorisation écrite préalable de Lyber et/ou de ses concédants, qui sera accordée à la seule discrétion de Lyber.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">16.</span>Assistance mutuelle
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>132.</small>
                                </li>
                                <li>
                                    Si des formalités administratives sont nécessaires pour l'exécution des présentes CGV, Lyber et les Utilisateurs/Clients s'engagent à se prêter mutuellement assistance pour la régularisation de ces formalités.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">17.</span>Hyperliens
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>133.</small>
                                </li>
                                <li>
                                    Les Services peuvent contenir des liens ou du contenu redirigeant vers des sites internet ou des ressources de Tiers.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>134.</small>
                                </li>
                                <li>
                                    Ces Services Tiers peuvent être soumis à des conditions générales et des politiques de confidentialité différentes.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>135.</small>
                                </li>
                                <li>
                                    Lyber ne sera pas responsable de la disponibilité, de l'exactitude, du contenu, des produits ou des services disponibles à partir de ces liens. La présence de tels liens ne constitue pas une approbation de ces Services Tiers par Lyber.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">18.</span>Non-renonciation
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>136.</small>
                                </li>
                                <li>
                                    Le fait que Lyber n'exige pas à un certain moment que l'autre Partie exécute l'une de ses obligations en vertu des présentes CGV n'affecte pas le droit de Lyber de demander leur exécution par la suite.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>137.</small>
                                </li>
                                <li>
                                    La nullité d'une clause n'affecte pas la validité et l'obligation de respecter les autres clauses.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">19.</span>Réclamations et litiges
                            </p>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">19.1</span>Réclamations
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>138.</small>
                                </li>
                                <li>
                                    En toutes circonstances, les Utilisateurs peuvent contacter Lyber afin d'obtenir toutes les informations souhaitées, ou de formuler toute réclamation.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>139.</small>
                                </li>
                                <li>
                                    <b> Lyber peut être contacté: </b>
                                    <ul>
                                        <li>- Par le formulaire de contact en <a href="/contact">cliquant ici</a>.</li>
                                        <li>- Par email: contact@lyber.com.</li>
                                        <li>- Par courrier postal:  15 rue des Halles, 75001 Paris 1, France ; </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>140.</small>
                                </li>
                                <li>
                                    Dans le cadre du traitement des réclamations, Lyber s'engage à:
                                    <ul>
                                        <li>- Accuser réception de toute réclamation des Clients dans un délai de dix (10) Jours Ouvrables à compter du jour de sa réception ;</li>
                                        <li>- Répondre aux Clients dans les quinze (15) Jours Ouvrables suivant la réception de la réclamation. Toutefois, si un délai supplémentaire est nécessaire pour répondre, Lyber informera le Client de la raison du retard. Il tiendra le Client informé de l'évolution du traitement de sa réclamation. En tout état de cause, le Client recevra une réponse définitive au plus tard trente-cinq (35) Jours Ouvrables suivant la réception de la réclamation par Lyber.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">19.2</span>Médiation
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>141.</small>
                                </li>
                                <li>
                                    Tout litige ou requête lié (i) aux informations communiquées par Lyber en vertu des présentes CGV, (ii) à une erreur dans l'exécution ou l’inexécution des Services ou (iii) à une erreur dans le prélèvement de commissions, taxes ou frais par Lyber, doit être notifié à Lyber par les Utilisateurs/Clients dans les meilleurs délais suivant la date à laquelle les Utilisateurs/Clients en ont eu connaissance ou sont présumés en avoir eu connaissance.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>142.</small>
                                </li>
                                <li>
                                    En cas de litige résultant de l’application des présentes CGV, et à défaut d'accord amiable, le Client consommateur peut saisir gratuitement un médiateur indépendant, sans préjudice de tout autre recours. Pour cela, le Client consommateur peut contacter le médiateur par plusieurs moyens:
                                    <ul>
                                        <li>- Courrier postal: Centre de Médiation et d’Arbitrage de Paris (CMAP), 39 Avenue Franklin D. Roosevelt 75008 PARIS</li>
                                        <li>- Email: cmap@cmap.fr</li>
                                        <li>- Téléphone: 01.44.95.11.40 du Lundi au Vendredi de 9h à 18H</li>
                                    </ul>
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">20.</span>Juridiction compétente et loi applicable
                            </p>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">20.1</span>Loi applicable et langue
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>143.</small>
                                </li>
                                <li>
                                    Les présentes CGV sont exclusivement soumises au droit français.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>144.</small>
                                </li>
                                <li>
                                    En cas de contradiction entre la version française et la version anglaise des présentes CGV, ou en cas de difficulté d’interprétation quelconque, la version française prévaudra.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">20.2</span>Juridiction
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>145.</small>
                                </li>
                                <li>
                                    Sauf disposition légale contraire, tous les litiges ou réclamations relatifs à ou découlant des présentes CGV et de toutes les Transactions conclues en application de celles-ci, concernant leur validité, leur formation, leur interprétation, leur exécution, leur résiliation et leurs conséquences, seront soumis aux tribunaux compétents du ressort de la Cour d'Appel de Paris.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
