const KYC = () => {
    return (
        <div className="kyc-page">
            <div className="container">
                <div className="card kyc-card">
                    <div className="card-body">
                        <i class="fa-solid fa-circle-check mb-3"></i>
                        <h2>KYC Successfully Done</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KYC;
