export const LegalNoticesEN = () => {
    return (
        <>
            <section className="privacy-policy pb-5 mb-3">
                <div className="container">
                    <h2 className="term-heading text-center mt-lg-4 mt-2 text-capitalize">
                        LEGAL NOTICE
                    </h2>
                    <br />
                    <br />
                    <br />

                    <p>
                        The information contained on the LYBER.COM site is strictly informative and does not imply any legal commitment or contractual agreement on the part of LYBER, which also reserves the right to modify its characteristics at any time.
                    </p>
                    <p>
                        Furthermore, access to the products and services presented on the site may be restricted for certain people or in certain countries. In any case, none of the products and / or services can be provided by LYBER to a natural or legal person, if the law of their country of origin or any other country does not authorize it or prohibits it.
                    </p>
                    <p>
                        Therefore, it is the responsibility of any interested party to verify beforehand with their usual advisers that their legal and tax status does not prevent them from subscribing to the products and / or services presented on the LYBER.COM site.
                    </p>
                    <br />
                    <h3>GENERAL PRESENTATION</h3>
                    <br />
                    <p>
                        Currently, you are connected to the <a href="/">LYBER.COM</a> website which is edited by LYBER.
                    </p>
                    <p>
                        LYBER is a simplified joint-stock company whose head office is located at 15 rue des Halles 75001 PARIS, registered in the Trade and Companies Register under number <span className="text-purple-color">903 241 925</span> with a capital of 2809,52 EUR.
                    </p>
                    <p>
                        The intra-community VAT number is: <span className="text-purple-color">FR38903241925</span>
                    </p>
                    <p>
                        LYBER is registered as a Provider of digital asset services with the Market Authority (AMF) under the number <a href="https://protectepargne.amf-france.org/acteurs-listes-blanches/psan/lyber-sas">E2022-039</a>.
                    </p>
                    <p>In this respect, LYBER provides several types of services namely:</p>
                    <ul>
                        <li>Preservation of digital assets</li>
                        <li>Exchange of digital assets for other digital assets</li>
                        <li>Buying and selling assets against legal tender</li>
                    </ul>

                    <br />
                    <h3>PUBLISHER'S LIABILITY</h3>
                    <br />

                    <p>The director of publication is Mr. Jules PUENTE CASTAN in his capacity as President of the company. The LYBER website is hosted by AWS - Amazon Web Services. The contact details of AWS Amazon Web Services are as follows: Amazon CS Ireland Ltd, Unit 27 - 6400 Cork Airport Business Park - Kinsale Road - Ireland (Amazon CS Ireland Ltd is a company of the Amazon Web Services (AWS) group, whose head office is located at Inc. P.O/ Box 81226 - Seattle, WA 98108-1226, tel: (206) 266-4064 fax: (206) 266-7010).</p>
                    <p>Contact means: support@lyber.com</p>

                    <br />
                    <h3>INTELLECTUAL PROPERTY</h3>
                    <br />

                    <p>All the content of this site including texts, photographs, drawings, images, sound sequences is the property of LYBER or third parties who have authorized LYBER to use them and falls under French and international legislation on copyright and intellectual property.</p>
                    <p>All reproduction rights are reserved, including for downloadable documents and iconographic and photographic representations.</p>
                    <p>The reproduction, representation, or dissemination of the Site and / or any element belonging to LYBER without written and prior authorization from LYBER, would constitute an infringement sanctioned by articles L.335-2 and following of the Intellectual Property Code. Any use of the elements of the Site for unauthorized purposes may be subject to legal proceedings, in France as well as abroad.</p>
                    <p>In general, it is up to the user to take the necessary precautions to ensure that the selected site is not infested with viruses or any other destructive parasite. In no case can LYBER be held responsible for direct or indirect damage resulting from the use of its website or other sites linked to it.</p>

                    <br />
                    <h3>MODIFICATION OF THE SITE AND UPDATE OF THE TERMS OF USE</h3>
                    <br />

                    <p>LYBER reserves the right at any time and without notice, to modify all or part of the content of the Site. Users are invited to regularly consult the Site to become aware of the applicable conditions.</p>


                    <br />
                    <h3>APPLICABLE LAW</h3>
                    <br />

                    <p>The Site and any document found there are subject to the provisions of French law. We commit to respecting all laws concerning the establishment and activity of a website. In case of dispute, the French courts are the only competent ones.</p>

                    <br />
                    <h3>PERSONAL DATA AND BANKING SECRECY</h3>
                    <br />

                    <p>
                        The personal data collected in the forms appearing on this site are mainly processed by LYBER, the data processing manager. Moreover, information on data processing and on the exercise of rights on these data can be found in our privacy policy.
                    </p>
                    <p>
                        It should be noted that throughout the duration of the business relationship, LYBER may communicate the transmitted data to the competent authorities.
                    </p>
                </div>
            </section>
        </>
    );
};