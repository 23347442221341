export const LegalNoticesFR = () => {
    return (
        <>
            <section className="privacy-policy pb-5 mb-3">
                <div className="container">
                    <h2 className="term-heading text-center mt-lg-4 mt-2 text-capitalize">
                        MENTIONS LÉGALES
                    </h2>
                    <br />
                    <br />
                    <br />

                    <p>
                        Les informations contenues sur le site LYBER.COM ayant un caractère strictement informatif, n’emporte aucun engagement juridique, ni accord contractuel de la part de LYBER laquelle réserve, par ailleurs, la faculté d’en modifier les caractéristiques à tout moment.
                    </p>
                    <p>
                        Par ailleurs, l’accès aux produits et services présentés sur le site peut faire l’objet de restrictions à l’égard de certaines personnes ou dans certains pays. En tout état de cause, aucun des produits et / ou services ne pourra être fourni par LYBER à une personne physique ou morale, si la loi de son pays d’origine ou de tout autre pays ne l’autorise pas ou bien l’interdit.
                    </p>
                    <p>
                        De ce fait, il appartient à toute personne intéressée de vérifier préalablement auprès de ses conseils habituels que son statut juridique et fiscal, ne s’oppose pas à la souscription des produits et/ ou services présentés sur le site LYBER.COM.
                    </p>
                    <br />
                    <h3>PRÉSENTATION GÉNÉRALE</h3>
                    <br />
                    <p>
                        Actuellement vous êtes connecté sur le site internet <a href="/">LYBER.COM</a> qui a été édité par LYBER.
                    </p>
                    <p>
                        LYBER est une société par actions simplifiée dont le siège social est situé au 15 rue des Halles 75001 PARIS, inscrite au RCS sous numéro <span className="text-purple-color">903 241 925</span> et ayant un capital de 2809,52 EUR.
                    </p>
                    <p>
                        Le numéro TVA intracommunautaire est : <span className="text-purple-color">FR38903241925</span>
                    </p>
                    <p>
                        LYBER est enregistrée au titre de Prestataire de service des actifs numériques auprès de l’Autorité de Marché Financier (AMF) sous le numéro <a href="https://protectepargne.amf-france.org/acteurs-listes-blanches/psan/lyber-sas">E2022-039</a>.
                    </p>
                    <p>A ce titre, LYBER permet de fournir plusieurs types de services à savoir :</p>
                    <ul>
                        <li>Conservation d’actifs numériques</li>
                        <li>Échange d’actifs numériques contre d’autres actifs numériques</li>
                        <li>Achat et vente d’actifs contre une monnaie ayant courant légal</li>
                    </ul>

                    <br />
                    <h3>RESPONSABILITÉ DE L'ÉDITEUR</h3>
                    <br />

                    <p>Le directeur de la publication est Monsieur Jules PUENTE CASTAN en sa qualité de Président de la société. Le site Internet de LYBER est hébergé par AWS - Amazon Web Services. Les coordonnées d'AWS Amazon Web Services sont les suivantes : Amazon CS Ireland Ltd, Unit 27 - 6400 Cork Airport Business Park - Kinsale Road - Ireland (Amazon CS Ireland Ltd est une société du groupe Amazon Web Services (AWS), dont le siège social est situé à Inc. P.O/ Box 81226 - Seattle, WA 98108-1226, tél : (206) 266-4064 fax : (206) 266-7010).</p>
                    <p>Moyen de contact: support@lyber.com</p>

                    <br />
                    <h3>PROPRIÉTÉ INTELLECTUELLE</h3>
                    <br />

                    <p>L'ensemble du contenu de ce site y compris les textes, photographies, dessins, images, séquences sonores est la propriété de LYBER  ou de tiers ayant autorisé LYBER  à les utiliser et relève de la législation française et internationale sur le droit d'auteur et la propriété intellectuelle.</p>
                    <p>Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les représentations iconographiques et photographiques.</p>
                    <p>La reproduction, la représentation, ou la diffusion du Site et/ou de tout élément appartenant à LYBER sans autorisation écrite et préalable de LYBER, constituerait une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Toute utilisation des éléments du Site à des fins non autorisées pourra faire l'objet de poursuites judiciaires, en France comme à l'étranger.</p>
                    <p>De façon générale, il appartient à l’utilisateur de prendre les précautions nécessaires pour s’assurer que le site sélectionné n’est pas infesté de virus ou de tout autre parasite de nature destructive. En aucun cas, LYBER ne pourra être tenu responsable des dommages directs ou indirects résultant de l’usage de son site web ou d’autres sites qui lui sont liés.</p>

                    <br />
                    <h3>MODIFICATION DU SITE ET MISE À JOUR DES CONDITIONS D’UTILISATION</h3>
                    <br />

                    <p>LYBER  se réserve le droit à tout moment et sans préavis, de modifier tout ou partie du contenu du Site. Les utilisateurs sont invités à consulter régulièrement le Site pour prendre connaissance des conditions applicables.</p>


                    <br />
                    <h3>DROIT APPLICABLE </h3>
                    <br />

                    <p>Le Site et tout document s’y trouvant sont soumis aux dispositions du droit français. Nous nous engageons à respecter l’ensemble des lois concernant la mise en place et l’activité d’un site internet. En cas de litige les tribunaux français sont seuls compétents.</p>

                    <br />
                    <h3>DONNÉES PERSONNELLES ET SECRET BANCAIRE</h3>
                    <br />

                    <p>
                        Les données personnelles recueillies dans les formulaires figurant sur ce présent site sont principalement traitées par LYBER, responsable du traitement des données. Par ailleurs, les informations sur les traitements de données et sur l’exercice des droits sur ces données figurent dans notre politique de confidentialité.
                    </p>
                    <p>
                        Il est à noter que pendant toute la durée de la relation d’affaire, LYBER pourra communiquer les données transmises aux autorités habilitées.
                    </p>
                </div>
            </section>
        </>
    );
};
