import QuestionMark from "../../assets/images/home/question-mark.png";
import Telegram from "../../assets/images/home/telegram.svg";
import Twitter from "../../assets/images/home/twitter.svg";
import Download from "../../assets/images/home/download2.svg";
import Book from "../../assets/images/home/book.svg";
import FileCheck from "../../assets/images/home/file-earmark-check.svg";
import ShieldCheck from "../../assets/images/home/shield-check.svg";
import AuditWagmiLYB from "../../documents/AuditWagmiLYB.pdf"
import LegalOpinionLYB from "../../documents/LegalOpinionLYB.pdf"
import WPEnglishLYB from "../../documents/WPEnglishLYB.pdf"
import WPFrenchLYB from "../../documents/WPFrenchLYB.pdf"
import { validateEmail } from "../../utils/validation_regex";
import { API_ROOT } from "../../utils/henceforthApi";
import axios from "axios";
import { useState, useContext } from "react";
import { GlobalContext } from "../../context/Provider";
import language_contant from "../../utils/language_contant";
import { Button, Modal } from "react-bootstrap";
import QuestionMark1 from "../../assets/images/Subtract.svg";
import TickImage from "../../assets/images/successful.svg";
import { Spinner } from "../BootstrapCompo";
import ReactPixel from 'react-facebook-pixel';

const Lyb = () => {
    const { authState, authDispatch } = useContext(GlobalContext);

    if (authState.areCookiesAccepted === true) {
        if (authState.metaSliderState === true)
            ReactPixel.pageView()
        if (authState.googleSliderState === true)
            window.gtag('event', 'page_view');
    }

    language_contant.setLang(authState.lang);
    const lang = authState.lang;

    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showSuccessful, setShowSuccessful] = useState(false);
    const [showInvalid, setShowInvalid] = useState(false);
    const [showAlreadyId, setShowAlreadyId] = useState(false);

    const handleClose = () => {
        setShowConfirm(false);
        setShowSuccessful(false);
    };
    const handleCloseAdreadyId = () => setShowAlreadyId(false);
    const handleCloseInvalid = () => setShowInvalid(false);
    const handleShow = () => setShowConfirm(true);

    const [state, setState] = useState({
        email: "",
        language: "ENGLISH",
        loading: false,
    });

    const checkEmail = async () => {
        const { email, loading } = state;
        if (validateEmail(email)) {
            handleShow(true);
        } else {
            setShowInvalid(true);
        }
    };

    const handleConfirm = async () => {
        handleShow(true);
        await preRegister();
    };
    const preRegister = async () => {
        const { email, loading } = state;
        if (validateEmail(email)) {
            setState({
                ...state,
                loading: true,
            });
            try {
                setLoading(true);
                let items = {
                    email: state.email,
                    language: authState.lang === "other" ? "FRENCH" : "ENGLISH",
                };
                // debugger;
                let res = await axios.post(`${API_ROOT}/api/email`, { email: state.email });
                setState({
                    ...state,
                    loading: false,
                    email: "",
                    language: "",
                    res,
                });
                setShowSuccessful(true);
                // alert(res.data.message);
            } catch (error) {
                setShowConfirm(false);
                setShowAlreadyId(true);

                // alert(error.response.body.message);
                setState({
                    ...state,
                    loading: false,
                });
            } finally {
                setLoading(false);
            }
        } else {
            setShowInvalid(true);
            // alert("Invalid email ID");
        }
    };

    return (
        <>
            <section className="section-spacing home-banner lyb-banner">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6">
                            <div className="banner-content">
                                <h1>{language_contant.Lyb.lyberHeading()}</h1>
                                <button className="btn btn-primary text-nowrap my-md-5 my-4" onClick={() => { window.location.href = "/claim-token"; }}>{language_contant.Lyb.claimMyTokens()}</button>
                                <p className="mt-2 sub-text">{language_contant.Lyb.lyberPara()}</p>
                                <div className="d-flex gap-5">
                                    <div className="gap-5">
                                        <div className="my-md-5 my-4">
                                            <a
                                                href="https://lyber.gitbook.io/lyber-your-first-step-into-crypto/"
                                                target="_blank"
                                                className="d-flex align-items-center"
                                            >
                                                {" "}
                                                <img src={Book} className="me-2" />
                                                {language_contant.Lyb.readLitePaper()}
                                            </a>
                                        </div>
                                        <div className="my-md-5 my-4">
                                            <a
                                                href={LegalOpinionLYB}
                                                target="_blank"
                                                className="d-flex align-items-center"
                                            >
                                                {" "}
                                                <img src={FileCheck} className="me-2" />
                                                {language_contant.Lyb.legalOpinion()}
                                            </a>
                                        </div>

                                    </div>
                                    <div className="gap-5">
                                        <div className="my-md-5 my-4">
                                            <a
                                                href={lang === "other" ? WPFrenchLYB : WPEnglishLYB}
                                                target="_blank"
                                                className="d-flex align-items-center"
                                            >
                                                {" "}
                                                <img src={Download} className="me-2" />
                                                {language_contant.Lyb.downloadWhitePaper()}
                                            </a>
                                        </div>

                                        <div className="my-md-5 my-4">
                                            <a
                                                href={AuditWagmiLYB}
                                                target="_blank"
                                                className="d-flex align-items-center"
                                            >
                                                {" "}
                                                <img src={ShieldCheck} className="me-2" />
                                                {language_contant.Lyb.smartContractsAudit()}
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-spacing">
                <div className="container">
                    <div className="advantage-wrapper">
                        {language_contant.Lyb.advanceTier()}
                        <ul
                            class="nav nav-pills mb-4 tier-buttons scrollbar flex-nowrap"
                            style={{ marginTop: "75px" }}
                            id="pills-tab"
                            role="tablist"
                        >
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link tier-btn text-nowrap active mb-2"
                                    id="pills-tier0-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-tier0"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-tier0"
                                    aria-selected="true"
                                >
                                    Tier 1
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link tier-btn text-nowrap mb-2"
                                    id="pills-tier1-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-tier1"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-tier1"
                                    aria-selected="false"
                                >
                                    Tier 02
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link tier-btn text-nowrap mb-2"
                                    id="pills-tier2-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-tier2"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-tier2"
                                    aria-selected="false"
                                >
                                    Tier 03
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link tier-btn text-nowrap mb-2"
                                    id="pills-tier3-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-tier3"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-tier3"
                                    aria-selected="false"
                                >
                                    Tier 04
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link tier-btn text-nowrap mb-2"
                                    id="pills-tier4-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-tier4"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-tier4"
                                    aria-selected="false"
                                >
                                    Tier 05
                                </button>
                            </li>
                            {/* <li class="nav-item" role="presentation">
                <button
                  class="nav-link tier-btn text-nowrap mb-2"
                  id="pills-tier5-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-tier5"
                  type="button"
                  role="tab"
                  aria-controls="pills-tier5"
                  aria-selected="false"
                >
                  Tier 05
                </button>
              </li> */}
                        </ul>

                        <div class="tab-content" id="pills-tabContent">
                            <div
                                class="tab-pane fade show active"
                                id="pills-tier0"
                                role="tabpanel"
                                aria-labelledby="pills-tier1-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card staked me-3">
                                            <p className={`${lang === "other" ? "french" : "mb-5"}`}>
                                                {lang === "other" ? "LYB detenus " : "LYB held"}
                                            </p>
                                            <h3 className="mb-0">1,000</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card APY me-3">
                                            <p className={`${lang === "other" ? "french" : "mb-5"}`}>
                                                {lang === "other" ? "Réduction de frais" : "Fee rebates"}
                                            </p>
                                            <h3 className="mb-0">5%</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card Referral me-3">
                                            <p className="french">
                                                {lang === "other" ? " Paramètres stratégie IA" : "AI strategy parameters"}
                                            </p>
                                            <h3 className="mb-0">1</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="tab-pane fade"
                                id="pills-tier1"
                                role="tabpanel"
                                aria-labelledby="pills-tier2-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card staked me-3">
                                            <p className={`${lang === "other" ? "french" : "mb-5"}`}>
                                                {lang === "other" ? "LYB detenus " : "LYB held"}
                                            </p>
                                            <h3 className="mb-0">10,000</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card APY me-3">
                                            <p className={`${lang === "other" ? "french" : "mb-5"}`}>
                                                {lang === "other" ? "Réduction de frais" : "Fee rebates"}
                                            </p>
                                            <h3 className="mb-0">10%</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card Referral me-3">
                                            <p className="french">
                                                {lang === "other" ? " Paramètres stratégie IA" : "AI strategy parameters"}
                                            </p>
                                            <h3 className="mb-0">2 </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="tab-pane fade"
                                id="pills-tier2"
                                role="tabpanel"
                                aria-labelledby="pills-tier3-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card staked me-3">
                                            <p className={`${lang == "other" ? "french" : "mb-5"}`}>
                                                {lang == "other" ? "LYB detenus " : "LYB held"}
                                            </p>
                                            <h3 className="mb-0">100,000</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card APY me-3">
                                            <p className={`${lang === "other" ? "french" : "mb-5"}`}>
                                                {lang === "other" ? "Réduction de frais" : "Fee rebates"}
                                            </p>
                                            <h3 className="mb-0">15%</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card Referral me-3">
                                            <p className="french">
                                                {lang === "other" ? " Paramètres stratégie IA" : "AI strategy parameters"}
                                            </p>
                                            <h3 className="mb-0">3 </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="tab-pane fade"
                                id="pills-tier3"
                                role="tabpanel"
                                aria-labelledby="pills-tier4-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card staked me-3">
                                            <p className={`${lang === "other" ? "french" : "mb-5"}`}>
                                                {lang === "other" ? "LYB detenus " : "LYB held"}
                                            </p>
                                            <h3 className="mb-0">250,000</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card APY me-3">
                                            <p className={`${lang === "other" ? "french" : "mb-5"}`}>
                                                {lang === "other" ? "Réduction de frais" : "Fee rebates"}
                                            </p>
                                            <h3 className="mb-0">25%</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card Referral me-3">
                                            <p className="french">
                                                {lang === "other" ? " Paramètres stratégie IA" : "AI strategy parameters"}
                                            </p>
                                            <h3 className="mb-0">4</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="tab-pane fade"
                                id="pills-tier4"
                                role="tabpanel"
                                aria-labelledby="pills-tier5-tab"
                            >
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card staked me-3">
                                            <p className={`${lang === "other" ? "french" : "mb-5"}`}>
                                                {lang === "other" ? "LYB detenus " : "LYB held"}
                                            </p>
                                            <h3 className="mb-0">500,000</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card APY me-3">
                                            <p className={`${lang === "other" ? "french" : "mb-5"}`}>
                                                {lang === "other" ? "Réduction de frais" : "Fee rebates"}
                                            </p>
                                            <h3 className="mb-0">50%</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                                        <div className="ad-card Referral me-3">
                                            <p className="french">
                                                {lang === "other" ? " Paramètres stratégie IA" : "AI strategy parameters"}
                                            </p>
                                            <h3 className="mb-0">5</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div
                class="tab-pane fade"
                id="pills-tier5"
                role="tabpanel"
                aria-labelledby="pills-tier5-tab"
              >
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                    <div className="ad-card staked me-3">
                      <p className={`${lang == "other" ? "french" : "mb-5"}`}>
                        {lang == "other" ? "LYB stakés " : "LYB staked"}
                      </p>
                      <h3 className="mb-0">1000000</h3>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                    <div className="ad-card APY me-3">
                      <p className={`${lang == "other" ? "french" : "mb-5"}`}>
                        {lang == "other"
                          ? "Réduction de frais"
                          : "Fee rebates"}
                      </p>
                      <h3 className="mb-0">21%</h3>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                    <div className="ad-card Referral me-3">
                      <p className={`${lang == "other" ? "french" : "mb-5"}`}>
                        {lang == "other"
                          ? " Part des frais d’affiliation"
                          : "Referral rate"}
                      </p>
                      <h3 className="mb-0">50% </h3>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-6 px-2 mb-lg-0 mb-3">
                    <div className="ad-card tickets me-3">
                      <p className={`${lang == "other" ? "french" : "mb-5"}`}>
                        {lang == "other" ? "Tickets d’IEO " : "IEO tickets"}
                      </p>
                      <h3 className="mb-0">5</h3>
                    </div>
                  </div>
                </div>
              </div> */}
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <p className="ad-bottom-text">
                                    <img src={QuestionMark} className="me-2" />
                                    {lang === "other"
                                        ? "Accédez à des tiers plus élevés en détenant du LYB dans votre portfolio Lyber"
                                        : "Ramp up on tiers by holding LYB in your Lyber portfolio"}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-spacing py-md-0 trustworthy-wrapper trustworthy-wrapper"></section>
            <section className="breakdown-wrapper">
                <div className="container">
                    <div className=" rebate-wrapper py-0">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                {lang == "other" ? (
                                    <h2 className="main-title text-dark">
                                        <span>Répartition du LYB token </span>
                                    </h2>
                                ) : (
                                    <h2 className="main-title">
                                        Supply breakdown
                                        {/* <span className="text-gray">
                      how we allocate our LYB token
                    </span> */}
                                    </h2>
                                )}
                            </div>
                            <div className="col-lg-6">
                                <div className="breakdown-content">
                                    <div className="mb-4">
                                        <h6>{lang == "other" ? "Liquidity pool" : "Liquidity pool"}</h6>
                                        <div className="d-flex align-items-center ">
                                            <h4>65%</h4>
                                            <div className="progress pool"></div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <h6>{lang == "other" ? "Equipe et conseil" : "Team and advisory"}</h6>
                                        <div className="d-flex align-items-center">
                                            <h4>16.3%</h4>
                                            <div className="progress team"></div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <h6>{lang == "other" ? "Vente privée" : "Private sale"}</h6>
                                        <div className="d-flex align-items-center">
                                            <h4>10%</h4>
                                            <div className="progress sale"></div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <h6>Seed</h6>
                                        <div className="d-flex align-items-center">
                                            <h4>8%</h4>
                                            <div className="progress seed"></div>
                                        </div>
                                    </div>
                                    <div className="mb-0">
                                        <h6>{lang == "other" ? "Vente publique" : "Public sale"}</h6>
                                        <div className="d-flex align-items-center ">
                                            <h4>0.7%</h4>
                                            <div className="progress public"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-spacing newsletter-wrapper pt-md-0" id="pre-register">
                <div className="container">
                    <div className="newsletter newsletter-bg">
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 text-center-991px">{language_contant.Home.startToInvest()}</div>
                            <div className="col-lg-6 col-xl-6 d-flex align-items-center justify-center-991px">
                                <div className="pre-register-content">
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            checkEmail();
                                        }}
                                    >
                                        <div className="form-group d-flex align-items-center register-input">
                                            <input
                                                type=""
                                                className="form-control me-sm-2"
                                                placeholder={lang == "other" ? "Email" : "Enter your email"}
                                                onChange={(e) => setState({ ...state, email: e.target.value })}
                                                value={state.email}
                                                disabled={state.loading}
                                                required
                                            />
                                            <button className="btn btn-secondary text-nowrap">
                                                {language_contant.Home.preRegister()}
                                            </button>
                                        </div>
                                    </form>

                                    {/* <p className="or-content my-2 text-center">
                                        <span className="text-or">{lang == "other" ? "OU" : "OR"}</span>
                                    </p>
                                    <div className="d-flex align-items-center flex-sm-row flex-column socials">
                                        <a
                                            href="https://twitter.com/LyberOfficial"
                                            target="_blank"
                                            className=" me-sm-1 w-50"
                                        >
                                            <button className="btn btn-light w-100">
                                                <img src={Twitter} className="icon" />
                                                {lang == "other" ? "Twitter" : "Follow us on Twitter"}
                                            </button>
                                        </a>
                                        <a
                                            href="https://t.me/LyberOfficial"
                                            target="_blank"
                                            className=" ms-sm-1 w-50 mt-sm-0 mt-2"
                                        >
                                            <button className="btn btn-light w-100">
                                                <img src={Telegram} className="icon" />
                                                {lang == "other" ? "Telegram" : "Join Telegram"}
                                            </button>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                {/* Confimed And Success Modal */}
                <Modal
                    show={showConfirm}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    className="confirm-box-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {showSuccessful
                                ? lang == "other"
                                    ? "Merci de nous avoir rejoint !"
                                    : "Welcome at Lyber!"
                                : lang == "other"
                                    ? "Confirmez votre inscription"
                                    : "Confirm your subscription"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center  my-3 px-4">
                            <div>
                                {showSuccessful ? (
                                    <img style={{ height: "50px", width: "50px" }} src={TickImage} alt="logo" />
                                ) : (
                                    <img style={{ height: "50px", width: "50px" }} src={QuestionMark1} alt="logo" />
                                )}
                            </div>
                            <div>
                                {showSuccessful ? (
                                    lang == "other" ? (
                                        <div className="ms-3">
                                            Vous êtes maintenant inscrit à la newsletter de Lyber. Vous allez bientôt
                                            recevoir un email de confirmation.
                                        </div>
                                    ) : (
                                        <div className="ms-3">
                                            You are now registered on Lyber’s newsletter. You should receive a
                                            confirmation email soon.
                                        </div>
                                    )
                                ) : lang == "other" ? (
                                    <div className="ms-3">
                                        En cliquant sur
                                        <span className="text-theme"> “Oui” </span> vous acceptez de recevoir des
                                        communiqués de la part de Lyber.
                                    </div>
                                ) : (
                                    <div className="ms-3">
                                        By clicking on <span className="text-theme">“Yes”</span> you agree to receive
                                        communications from Lyber.
                                    </div>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {showSuccessful ? (
                            <div>
                                <Button onClick={handleClose} className="px-6 confirm-btn">
                                    Ok
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <Button onClick={handleClose} className="px-6 reject-btn">
                                    {lang == "other" ? "Non" : "No"}
                                </Button>
                                <Button onClick={handleConfirm} className="px-6 confirm-btn ms-3" disabled={loading}>
                                    {loading ? <Spinner /> : lang == +"other" ? "Oui" : "Yes"}
                                </Button>
                            </div>
                        )}
                    </Modal.Footer>
                </Modal>
                {/* Already Registerd Modal */}
                <Modal
                    show={showAlreadyId}
                    onHide={handleCloseAdreadyId}
                    backdrop="static"
                    keyboard={false}
                    centered
                    className="confirm-box-modal"
                >
                    <Modal.Header>
                        <Modal.Title>
                            {lang == "other" ? "Vous nous suivez déjà !" : "You’re already registered!"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center my-3 px-4">
                            <div>
                                {lang == "other"
                                    ? "L'adresse e-mail est déjà enregistrée. Veuillez utiliser une autre adresse e-mail."
                                    : "The email address is already registered. Please use another email address."}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleCloseAdreadyId} className="px-6 confirm-btn">
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Invalid Modal */}
                <Modal
                    show={showInvalid}
                    onHide={handleCloseAdreadyId}
                    backdrop="static"
                    keyboard={false}
                    centered
                    className="confirm-box-modal"
                >
                    <Modal.Header>
                        <Modal.Title> {lang == "other" ? "Non valide" : "Invalid"} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="my-3 text-center">
                            <div>{lang == "other" ? "Identifiant de messagerie invalide" : "Invalid Email Id."}</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleCloseInvalid} className="px-6 confirm-btn">
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};
export default Lyb;
