import { Table } from "react-bootstrap"

export const PricingPolicyEN = () => {
    return (
        <>
            <section className="privacy-policy pb-5 mb-3">
                <div className="container">
                    <h2 className="term-heading text-center mt-lg-4 mt-2 text-capitalize">
                        Pricing Policy
                    </h2>
                    <br />
                    <br />
                    <br />

                    <p>
                        This policy covers the usage fees for the main services related to a payment account on the one hand and a digital asset account on the other hand.
                    </p>
                    <p>
                        All our prices are indicated in TTC.
                    </p>
                    <p>
                        These pricing conditions apply on the website and on the LYBER application.
                    </p>
                    <br />
                    <p>
                        The pricing grid exposed below is presented on 5 levels :
                    </p>

                    <ul>
                        <li>Level 1: OPENING AND OPERATION OF YOUR ACCOUNT</li>
                        <li>Level 2: SERVICE</li>
                        <li>Level 3: FIAT MONEY PAYMENT (EURO)</li>
                        <li>Level 4: CRYPTO ASSET PAYMENT</li>
                        <li>Level 5: SPECIFICITY OF CRYPTO ASSETS</li>
                    </ul>
                    <br />
                    <br />
                    <br />
                    <h3 className="text-center mt-lg-4 mt-2 text-capitalize">
                        PRICING GRID
                    </h3>
                    <br />
                    <Table hover striped className="roundedTable mb-5">
                        <thead>
                            <tr>
                                <th width="80%" className="purple-light">OPENING AND OPERATION OF YOUR ACCOUNT</th>
                                <th width="20%" className="purple-light">RATE</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            <tr>
                                <td className="light-black">Account opening</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Account closure</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Monthly account statement (dematerialized)</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Statement of fees and commissions (dematerialized)</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Change of details and all information related to KYC<sup>1</sup></td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Account management</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Management of inactive account as per article L.312-19 of the CMF<sup>2</sup> and Eckert Law</td>
                                <td className="light-purple">30 EUR / YEAR</td>
                            </tr>
                        </tbody>
                        <thead className="table-body">
                            <tr>
                                <th width="80%" className="purple-light">SERVICE</th>
                                <th width="20%" className="purple-light">RATE</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            <tr>
                                <td className="light-black">Complaints service</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Mediation service</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Intervention commissions<sup>3</sup></td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Subscription to services (investment strategy)<sup>4</sup></td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Consultation, account management and messaging on the website</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Consultation, account management and messaging on the application</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                        </tbody>
                        <thead className="table-body">
                            <tr>
                                <th width="80%" className="purple-light">FIAT MONEY PAYMENT (EURO)</th>
                                <th width="20%" className="purple-light">RATE</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {/* <tr>
                                <td className="light-black">Incoming SEPA bank transfer (deposit)</td>
                                <td className="light-purple">FREE</td>
                            </tr> */}
                            <tr>
                                <td className="light-black">Incoming payment (deposit) via Apple Pay and Google Pay under 60 euros</td>
                                <td className="light-purple">3%</td>
                            </tr>
                            <tr>
                                <td className="light-black">Incoming payment (deposit) via Apple Pay and Google Pay greater than or equal to 60 euros</td>
                                <td className="light-purple">2%</td>
                            </tr>
                            <tr>
                                <td className="light-black">Outgoing transfer only by SEPA bank transfer (withdrawal)</td>
                                <td className="light-purple">0.66€</td>
                            </tr>
                            {/* <tr>
                                <td className="light-black">Fiat currency holding</td>
                                <td className="light-purple">FREE</td>
                            </tr> */}
                        </tbody>
                        <thead className="table-body">
                            <tr>
                                <th width="80%" className="purple-light">CRYPTO ASSET PAYMENT (excluding network fees passed on to customers)</th>
                                <th width="20%" className="purple-light">RATE</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {/* <tr>
                                <td className="light-black">Transfer between friend from one digital asset to another digital asset (withdrawal - external account)</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Transfer between friend from one digital asset to another digital asset (withdrawal - internal account)</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Single order purchase of a digital asset</td>
                                <td className="light-purple">1%</td>
                            </tr>
                            <tr>
                                <td className="light-black">Single order sale of a digital asset</td>
                                <td className="light-purple">1%</td>
                            </tr> */}
                            <tr>
                                <td className="light-black">Trading one digital asset for another</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">Transfer a digital asset to an internal account (deposit)<sup>6</sup></td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Transfer of digital assets to an external account (withdrawal)<sup>7</sup></td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Transfer digital assets to and from a Lyber account</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                            <tr>
                                <td className="light-black">Execution of an investment strategy by successive orders<sup>8</sup></td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">Custody of digital assets</td>
                                <td className="light-purple">FREE</td>
                            </tr>
                        </tbody>
                        <thead className="table-body">
                            <tr>
                                <th width="80%" className="purple-light">BLOCKCHAIN FEES</th>
                                <th width="20%" className="purple-light">RATE</th>
                            </tr>
                        </thead>

                        <tbody className="table-body">
                            <tr>
                                <td className="light-black">Commission corresponding to the reimbursement of blockchain fees advanced by Lyber for any purchase and sale of a crypto asset against another crypto asset</td>
                                <td className="light-purple">Blockchain fee variation depending on traffic on the blockchain</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p className="footnote"><sup>1</sup> Know Your Customer, it's a process of identification and verification of LYBER users.</p>
                    <p className="footnote"><sup>2</sup> Monetary and Financial Code</p>
                    <p className="footnote"><sup>3</sup> These are all types of interventions incumbent on LYBER to investigate and generate fees through its suppliers. For example, it could be paying claims due to an error coming from the customer and LYBER will have to intervene to investigate.</p>
                    <p className="footnote"><sup>4</sup> Description of the strategic subscription offer: the user has the possibility to give strategic instructions allowing LYBER to execute orders monthly. This is only valid for an order execution purchase. </p>
                    <p className="footnote"><sup>5</sup> The minimum deposit amount for Apple Pay and Google Pay is 20 EUR.</p>
                    <p className="footnote"><sup>6</sup> An internal account is defined as an account opened at Lyber</p>
                    <p className="footnote"><sup>7</sup> Deposits and withdrawals of cryptoassets are free of charge, Lyber does not charge any fees. However, network fees may apply beyond Lyber's control.</p>
                    <p className="footnote"><sup>8</sup> An order sequence is defined as a set of orders placed by Lyber on the market in order to best execute the strategy chosen by the user. </p>
                    <br />
                    <br />
                    <br />
                    <h3 className="text-center mt-lg-4 mt-2 text-capitalize">
                        SPECIFIC PRICE LIST FOR DIGITAL ASSETS
                    </h3>
                    <br />
                    <Table hover striped className="roundedTable mb-5">
                        <thead>
                            <tr>
                                <th width="50%" className="purple-light">DIGITAL ASSETS</th>
                                <th width="50%" className="purple-light">COMMISSIONS</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            <tr>
                                <td className="light-black">BITCOIN (BTC)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">ETHER (ETH)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">BINANCE COIN (BNB)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">CHAINLINK (LINK)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">FANTOM (FTM)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">ATOM (ATOM)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">MULTIVERSX (EGLD)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">CARDANO (ADA)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">DOGECOIN (DOGE)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">PEPE (PEPE)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">TETHER (USDT)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">RIPPLE (XRP)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">POLKADOT (DOT)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">SHIBA (SHIB)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">STELLAR (XLM)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">LITECOIN (LTC)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">POLYGON (MATIC)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">SOLANA (SOL)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">AVALANCHE (AVAX)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">CIRCLE (USDC)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p>Any modification of the price list and the specific commissions related to digital assets will be brought to the attention of the client as far as possible 30 days before the entry into force of the new fees.</p>
                </div>
            </section>
        </>
    );
};