import { API_ROOT } from "../../utils/henceforthApi";
import language_contant from "../../utils/language_contant";
import { useContext, useState } from "react";
import { GlobalContext } from "../../context/Provider";
import { validateEmail } from "../../utils/validation_regex";
import React from "react";
import { useRef } from "react"
import axios from "axios";

import { Button, Modal } from "react-bootstrap";
import QuestionMark from "../../assets/images/Subtract.svg";
import "react-toastify/dist/ReactToastify.css";
import TickImage from "../../assets/images/successful.svg";
import { Spinner } from "../BootstrapCompo";
import mailIcon from "../../assets/images/mailIcon.svg"
import localisationIcon from "../../assets/images/localisationIcon.svg"
import telegramPurple from "../../assets/images/home/telegramPurple.svg"
import earth from "../../assets/images/earth.png"

import ReCAPTCHA from "react-google-recaptcha"

export const ContactFormFR = () => {
    const { authState, authDispatch } = useContext(GlobalContext);
    const captchaRef = useRef(null);
    language_contant.setLang(authState.lang);
    const lang = authState.lang;

    const [loading, setLoading] = useState(false);
    const [showSuccessful, setShowSuccessful] = useState(false);
    const [showInvalid, setShowInvalid] = useState(false);
    const [showMissingField, setShowMissingField] = useState(false);
    const [showMissingCaptcha, setShowMissingCaptcha] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleCloseInvalid = () => setShowInvalid(false);
    const handleCloseMissingField = () => setShowMissingField(false);
    const handleCloseSuccessful = () => setShowSuccessful(false)
    const handleCloseMissingCaptcha = () => setShowMissingCaptcha(false)

    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        language: "ENGLISH",
        loading: false,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        try {
            // const response = await axios.post(`${API_ROOT}/api/recaptcha`, { token: token });
            const { email, firstName, lastName, message, loading } = state;
            if (email === "" || firstName === "" || lastName === "" || message.trim().length === 0) {
                setErrorMessage("Une ou plusieurs informations sont manquante.");
                setShowMissingField(true)
            }
            else if (validateEmail(email) === false) {
                setErrorMessage("L'adresse mail est invalide.");
            }
            else if (token === "") {
                setErrorMessage("Vous devez valider le CAPTCHA.");
                setShowMissingCaptcha(true)
            } else {
                setErrorMessage("");
                const response = await axios.post(`${API_ROOT}/api/sendTicket`, { token: token, name: `${firstName} ${lastName}`, email: email, message: message });
                if (response.status === 200) {
                    setShowSuccessful(true)
                    setState({
                        firstName: "",
                        lastName: "",
                        email: "",
                        message: "",
                        language: "ENGLISH",
                        loading: false,
                    })
                }
                else {
                    setShowInvalid(true)
                }
            }
        }
        catch (e) {
            setShowInvalid(true)
        }
    }

    return (
        <>

            <section>
                <div className="container">
                    <img className="img-earth-bottom" src={earth} />
                    <div className="row justify-content-center">
                        <div className="col-lg-6" >
                            <div className="contact-form gap-3">
                                <h1 className="text-center">Contactez nous</h1>
                                <div className="contact-top-box">
                                    <div className="mail-box-title">
                                        <a href="mailto:contact@lyber.com" target="_blank" >
                                            <img src={mailIcon} className="mail-icon" />
                                        </a>
                                        <p>Email</p>
                                    </div>

                                    <p className="mt-2 sub-text">Envoyez-nous un email à <a href="mailto:contact@lyber.com" target="_blank">contact@lyber.com</a> ou remplissez le formulaire de contact ci-dessous :</p>

                                </div>

                                <div>
                                    <form
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="d-flex register-input flex-column contact-form-content gap-3 ">
                                            <h1 className="header-contact-form text-start">Formulaire de contact</h1>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Prénom"
                                                onChange={(e) => {
                                                    if (!(e.target.value.length > 30))
                                                        setState({ ...state, firstName: e.target.value })
                                                }}
                                                value={state.firstName}
                                                disabled={state.loading}
                                            />
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Nom de famille"
                                                onChange={(e) => {
                                                    if (!(e.target.value.length > 30))
                                                        setState({ ...state, lastName: e.target.value })
                                                }}
                                                value={state.lastName}
                                                disabled={state.loading}
                                            />
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Email"
                                                onChange={(e) => {
                                                    if (!(e.target.value.length > 320))
                                                        setState({ ...state, email: e.target.value })
                                                }}
                                                value={state.email}
                                                disabled={state.loading}
                                            />
                                            {errorMessage && errorMessage === "L'adresse mail est invalide." && validateEmail(state.email) === false && <div className="error-message">{errorMessage}</div>}
                                            <textarea
                                                type="text"
                                                className="form-text-area form-control"
                                                placeholder="Message"
                                                onChange={(e) => setState({ ...state, message: e.target.value })}
                                                value={state.message}
                                                disabled={state.loading}
                                            />
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_SITE_KEY}
                                                ref={captchaRef}
                                                hl="fr"
                                            />


                                            <button className="btn btn-primary text-nowrap">
                                                Envoyer
                                            </button>
                                        </div>
                                        {errorMessage && errorMessage !== "L'adresse mail est invalide." && <div className="error-message">{errorMessage}</div>}
                                    </form>
                                </div>

                                <div className="contact-top-box">
                                    <div className="mail-box-title">
                                        <img src={localisationIcon} className="mail-icon" />
                                        <p>Adresse</p>
                                    </div>
                                    <p className="mt-2 sub-text">Vous pouvez également nous contacter par courrier à l'adresse <a href="mailto:contact@lyber.com" target="_blank">274 ter/3, Avenue de la Marne, 59700 Marcq-en-Baroeul, Lille, France.</a></p>
                                </div>
                                <p className="text-center contact-bottom-text">Les informations recueillies sur ce formulaire sont nécessaires à LYBER et à ses prestataires pour la réalisation et le suivi de votre demande.  Vous pouvez exercer votre droit d'accès, de rectification et d'opposition pour motif légitime à tout moment conformément à la réglementation relative aux données personnelles. Vous devez en faire la demande accompagnée d'un justificatif d'identité par courrier adressé à LYBER - 274 ter/3, Avenue de la Marne, 59700 Marcq-en-Baroeul, Lille, France ou en contactant le Délégué à la Protection des Données - DPO par e-mail à l'adresse <a href="mailto:dpo@lyber.com" target="_blank">dpo@lyber.com</a>. En outre, vous avez la possibilité de vous référer à notre politique de confidentialité (à fournir).</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Modal
                        show={showInvalid}
                        onHide={handleCloseInvalid}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header>
                            <Modal.Title>Une erreur est survenue.</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-3 text-center">
                                <div>
                                    Veuillez réessayer plus tard...
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseInvalid} className="px-6 confirm-btn">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={showMissingField}
                        onHide={handleCloseMissingField}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header>
                            <Modal.Title>Information(s) manquante(s).</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-3 text-center">
                                <div>
                                    Une ou plusieurs informations sont manquantes. Merci de les compléter avant de réessayer.
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseMissingField} className="px-6 confirm-btn">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={showMissingCaptcha}
                        onHide={handleCloseMissingCaptcha}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header>
                            <Modal.Title>Vous devez valider le CAPTCHA.</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-3 text-center">
                                <div>
                                    Pour nous envoyer un message, vous devez valider le CAPTCHA pour prouver que vous n'êtes pas un robot.
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseMissingCaptcha} className="px-6 confirm-btn">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={showSuccessful}
                        onHide={handleCloseSuccessful}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header>
                            <Modal.Title>Message envoyé.</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-3 text-center">
                                <div>
                                    Merci d'avoir contacté le support. Nous vous répondrons dans les prochaines 24 heures.
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseSuccessful} className="px-6 confirm-btn">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </section>
        </>
    );
};
