import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const ResetPassword = () => {
    let location = useLocation();
    const params = new URLSearchParams(location.search);
    const lang = params.get("lang") ?? "EN";
    const token = params.get("token");
    const iosLink = `lyber://reset-password?token=${token}`;
    const androidLink = `TODO`;
    let link;
    const localized = {
        "FR": {
            "Click to reset your Lyber password": "Cliquez pour réinitialiser votre mot de passe Lyber",
            "You need to open this link on your device": "Vous devez ouvrir ce lien depuis votre smartphone"
        },
        "EN": {
            "Click to reset your Lyber password": "Click to reset your Lyber password",
            "You need to open this link on your device": "You need to open this link on your device"
        }
    }

    function isIOS() {
        return /ipad|iphone|ipod/i.test(navigator.userAgent);
    }

    function isAndroid() {
        return /android/i.test(navigator.userAgent);
    }

    link = isIOS() ? iosLink : (isAndroid() ? androidLink : undefined);

    useEffect(() => {
        if (link) window.open(link);
    }, [link]);

    return (
        <>
            <div style={{ textAlign: "center", marginTop: "100px", fontSize: "25px" }}>
                {link
                    ? <a href={link} rel="noopener noreferrer" aria-label={localized[lang]["Click to reset your Lyber password"]}>
                        {localized[lang]["Click to reset your Lyber password"]}
                    </a>
                    : <p>{localized[lang]["You need to open this link on your device"]}</p>
                }
            </div>
        </>
    );
};

export default ResetPassword;
