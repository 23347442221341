import { useState, useContext, useEffect } from "react";
import { API_ROOT } from "../../utils/henceforthApi";
import Popup from "../popupSubscribe/popupSubscribe";
import axios from "axios";
import ReactPixel from 'react-facebook-pixel';
import { GlobalContext } from "../../context/Provider";


const Articles = () => {
    const [medium, setMedium] = useState([]);
    const [substack, setSubstack] = useState([]);
    const [isLyberType, setIsLyberType] = useState(true);
    const [showPopupSubscribe, setShowPopupSubscribe] = useState(false);

    const { authState } = useContext(GlobalContext);
    if (authState.areCookiesAccepted === true) {
        if (authState.metaSliderState === true)
            ReactPixel.pageView()
        if (authState.googleSliderState === true)
            window.gtag('event', 'page_view');
    }

    const initialse = async () => {
        try {
            let apiResMedium = await axios.get(`${API_ROOT}/api/articles-medium`);
            setMedium(apiResMedium.data.sort(function (a, b) {
                return (new Date(b.date_of_publication) - new Date(a.date_of_publication));
            }));

            let apiResSubstack = await axios.get(`${API_ROOT}/api/articles-substack`);
            setSubstack(apiResSubstack.data.sort(function (a, b) {
                return new Date(b.date_of_publication) - new Date(a.date_of_publication);
            }));

        } catch (error) {
            console.log(error);
        } finally {
        }
    };
    useEffect(() => {
        initialse();
    }, []);

    const handleLyberButton = () => {
        setIsLyberType(true)
    }

    const handleCryptoButton = () => {
        setIsLyberType(false)
    }

    const onClosePopup = () => {
        setShowPopupSubscribe(false)
    }

    return (
        <>
            <Popup show={showPopupSubscribe} onClose={onClosePopup} />
            <section className="py-5 article-page">
                <div className="container">
                    <div className="row article-header-row">
                        <div className="col">
                            {isLyberType ? (<div className="page-heading">
                                <h2 className="m-0 mb-1">Les actualités Lyber.</h2>
                                <p className="m-0" style={{ fontSize: "30px" }}>
                                    <a className="btn bg-transparent p-0  shadow-none" onClick={() => { setShowPopupSubscribe(true) }}>
                                        Abonnez-vous
                                    </a>{" "}
                                    à notre newsletter pour être
                                    informé en temps réel.{" "}
                                </p>
                            </div>) :
                                (<div className="page-heading">
                                    <h2 className="m-0 mb-1">Nos articles.</h2>
                                    <p className="m-0" style={{ fontSize: "30px" }}>
                                        <a href="#footer" className="btn bg-transparent p-0  shadow-none">
                                            Suivez-nous
                                        </a>{" "}
                                        pour obtenir les dernières nouveautés en temps réel.{" "}
                                    </p>
                                </div>)}
                        </div>
                        <div className="col article-switch-button-col">
                            <div onClick={handleLyberButton} class={isLyberType ? "article-switch-button purple-light" : "article-switch-button light-voilet"}>
                                <p style={isLyberType ? { color: "white" } : { color: "black" }}>Newsletters</p>
                                <p style={isLyberType ? { color: "white" } : { color: "black" }}>Lyber</p>
                            </div>
                            <div onClick={handleCryptoButton} class={isLyberType ? "article-switch-button light-voilet" : "article-switch-button purple-light"}>
                                <p style={isLyberType ? { color: "black" } : { color: "white" }}>Articles</p>
                            </div>
                        </div>
                    </div>
                    {!isLyberType &&
                        <div className="row gy-5 pt-5 article-row">
                            {/* 1  */}
                            {medium.map((res, index) => {
                                const splitedDate = res.date_of_publication.toString().split('-');
                                const finalDate = `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`
                                return (
                                    <>
                                        <div className="col-lg-4">
                                            <a href={res.link} target="_blank">
                                                <div className="article-page-card">
                                                    <div class="article-page-upper-box">
                                                        <div class="article-image-div">
                                                            <img class="article-image" src={res.image} />
                                                        </div>

                                                    </div>
                                                    <div class="article-page-lower-box">
                                                        <h4 className="m-0 article-title">{res.title}</h4>
                                                        <p className="my-1 text-black">{res.time_to_read}min</p>
                                                        {/* <p className="my-1">{getTimeInHrsMinutes(Number(res.read_time))}</p> */}
                                                        <p className="fw-light article-createdat" style={{ color: "black" }}>
                                                            {finalDate}
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    }
                    {isLyberType &&
                        <div className="row gy-5 pt-5 article-row">
                            {/* 1  */}
                            {substack.map((res, index) => {
                                const splitedDate = res.date_of_publication.toString().split('-');
                                const finalDate = `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`
                                return (
                                    <>
                                        <div className="col-lg-4">
                                            <a href={res.link} target="_blank">
                                                <div className="article-page-card">
                                                    <div class="article-page-upper-box">
                                                        <div class="article-image-div">
                                                            <img class="article-image" src={res.image} />
                                                        </div>
                                                    </div>
                                                    <div class="article-page-lower-box">
                                                        <h4 className="m-0 article-title">{res.title}</h4>
                                                        <p className="my-1 text-black">{res.time_to_read}min</p>
                                                        {/* <p className="my-1">{getTimeInHrsMinutes(Number(res.read_time))}</p> */}
                                                        <p className="fw-light article-createdat" style={{ color: "black" }}>
                                                            {finalDate}
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    }
                </div>
            </section>
        </>
    );
};

export default Articles;
