import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "../../components/BootstrapCompo";
import { API_ROOT } from "../../utils/henceforthApi";
import axios from "axios";
import LyberLogo from "../../assets/images/home/logo.svg";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

export default () => {
    let location = useLocation();
    const searchParam = new URLSearchParams(location.search);

    const [success, setSuccess] = useState(false);
    const [state, setState] = useState({
        message: "",
    });
    useEffect(async () => {
        try {
            throw "not implemented"
            //let data = await henceforthApi.Auth.verifyEmail(searchParam.get("token"));
            // setSuccess("good");
            // setState({
            //     ...state,
            //     ...data,
            // });
        } catch (error) {
            // toast("Verification link expired");
            setSuccess("bad");
            setState({
                ...state,
                ...error.response.body,
            });
        }
    }, []);
    return (
        // (success == "good") ?
        // <div className="verify-email-wrapper">
        //     <div className="verify-email">
        //         <img src={LyberLogo} alt="LyberLogo" className="img-fluid logo mb-3 w-25" />
        //         <h2>Your email address has been verified</h2>
        //         <p>Thank you for your support, we have successfully verified your email address. </p>
        //         <p>You can now proceed to the homepage.</p>
        //         <Link to="/" className="btn btn-primary">Continue to Lyber</Link>
        //     </div>
        // </div> : (success == "bad") ? <div className="verify-email-wrapper">
        //     <div className="verify-email">
        //         <div className="text-center mb-3">
        //             <img src={LyberLogo} alt="LyberLogo" className="img-fluid logo" />
        //         </div>
        //         <h2>{state.message ? state.message : ""}</h2>
        //     </div>
        // </div> : <Spinner />)
        <div className="verify-email-wrapper">
            <div className="verify-email">
                <img src={LyberLogo} alt="LyberLogo" className="img-fluid logo mb-3 w-25" />
                <h2>Verification link is expired</h2>

                {/* <h2>Your email address has been verified</h2> */}
                {/* <p>
          Thank you for your support, we have successfully verified your email
          address.{" "}
        </p>
        <p>You can now proceed to the homepage.</p> */}
                <a href="com.Lyber://" target="_blank" className="btn btn-primary">
                    Continue to Lyber
                </a>
            </div>
            {/* <ToastContainer /> */}
        </div>
    );
};
