import { COOKIE_ACTION } from "../ActionTypes";

const setCookie = (payload) => (dispatch) => {
    dispatch({
        type: COOKIE_ACTION,
        payload: payload,
    });
};

export default {
    setCookie,
};
