import { useEffect, useContext } from "react";
import { GlobalContext } from "../../context/Provider";
import { PrivacyPolicyEN } from "./index.en";
import { PrivacyPolicyFR } from "./index.fr";
import ReactPixel from 'react-facebook-pixel';

export default () => {
    const { authState } = useContext(GlobalContext);

    if (authState.areCookiesAccepted === true) {
        if (authState.metaSliderState === true)
            ReactPixel.pageView()
        if (authState.googleSliderState === true)
            window.gtag('event', 'page_view');
    }

    const lang = authState.lang;

    useEffect(() => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }, []);
    return <>{lang == "other" ? PrivacyPolicyFR() : PrivacyPolicyEN()}</>;
};
