import Twitter from "../../../assets/images/home/f-twitter.svg";
import Telegram from "../../../assets/images/home/f-telegram.svg";
import Linkedin from "../../../assets/images/home/linkedin.svg";
import Instagram from "../../../assets/images/home/instagram.svg";
import Facebook from "../../../assets/images/home/facebook.svg";
import Medium from "../../../assets/images/home/medium.svg";

import language_contant from "../../../utils/language_contant";
import { useState, useContext } from "react";
import { GlobalContext } from "../../../context/Provider";
import authAction from "./../../../context/actions/authAction";
import ReactPixel from 'react-facebook-pixel';

const Footer = () => {
    const { authState, authDispatch } = useContext(GlobalContext);
    const [googleCookieSlider, setGoogleCookieSlider] = useState(true);
    const [metaCookieSlider, setMetaCookieSlider] = useState(true);
    const changeGoogleCookieSlider = () => {
        setGoogleCookieSlider(!googleCookieSlider)
    };
    const changeMetaCookieSlider = () => {
        setMetaCookieSlider(!metaCookieSlider)
    };
    //console.log(authState.areCookiesAccepted)
    const lang = authState.lang;
    language_contant.setLang(authState.lang);
    const cookieAction = (action) => {
        if (action === true) {
            if (googleCookieSlider === true) {
                window.gtag('consent', 'update', {
                    'ad_storage': 'granted',
                    'analytics_storage': 'granted'
                });
                window.gtag('event', 'page_view');
            }
            if (metaCookieSlider === true) {
                ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID);
                ReactPixel.pageView()
            }
        }
        const cookieParams = {
            areCookiesAccepted: action,
            googleSliderState: googleCookieSlider,
            metaSliderState: metaCookieSlider
        }
        authAction.setCookie(cookieParams)(authDispatch);
    };
    return (
        <>
            <footer className="section-spacing footer" id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-md-none mb-5">
                            <div className="footer-content">
                                <h6 className="footer-title fs-18 ff-atyp">
                                    <a href="https://lyber.com/" target="_blank" className="text-light">
                                        Lyber
                                    </a>
                                </h6>
                                <p className="text-light-gray fs-18">{language_contant.Footer.lyberPara()} </p>
                                <div className="mt-4 pt-2">
                                    <p className="mb-0 text-light-gray fs-18">15 rue des Halles</p>
                                    <p className="mb-0 text-light-gray fs-18">75001 Paris, France</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-xs-4 col-sm-4">
                                    <h6 className="footer-title fs-18 ff-atyp">
                                        {language_contant.Footer.aboutfooter()}
                                    </h6>
                                    <p className="mb-2">
                                        <a href="/lyb" className="text-light-gray fs-18 text-decoration-none fw-light">
                                            {language_contant.Footer.lybToken()}
                                        </a>{" "}
                                    </p>
                                    <p className="mb-2">
                                        <a href="/faq" className="text-light-gray fs-18 text-decoration-none fw-light">
                                            FAQ
                                        </a>{" "}
                                    </p>
                                    {/* <p className="mb-2"><a href="" className="text-light-gray fs-18 text-decoration-none fw-light">{language_contant.Footer.contact()}</a> </p> */}
                                    <p className="mb-2">
                                        <a
                                            href="/privacy"
                                            className="text-light-gray fs-18 text-decoration-none fw-light"
                                        >
                                            {language_contant.Footer.privacy()}
                                        </a>{" "}
                                    </p>
                                    <p className="mb-2">
                                        <a
                                            href="/terms-conditions"
                                            className="text-light-gray fs-18 text-decoration-none fw-light"
                                        >
                                            {language_contant.Footer.termconditionofsale()}
                                        </a>{" "}
                                    </p>
                                    <p className="mb-2">
                                        <a
                                            href="/legal"
                                            className="text-light-gray fs-18 text-decoration-none fw-light"
                                        >
                                            {language_contant.Footer.legalnotices()}
                                        </a>{" "}
                                    </p>
                                </div>
                                <div className="col-xs-4 col-sm-4">
                                    <h6 className="footer-title fs-18 ff-atyp">
                                        {language_contant.Footer.joinFollow()}
                                    </h6>
                                    <ul className="list-unstyled social-list">
                                        <li>
                                            <a
                                                href="https://twitter.com/LyberOfficial"
                                                target="_blank"
                                                className="text-light-gray fs-18 text-decoration-none fw-light d-flex align-items-center gap-2 flex-wrap"
                                            >
                                                <div className="social-icon">
                                                    <img src={Twitter} />
                                                </div>
                                                <span>Twitter</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://t.me/LyberOfficial"
                                                target="_blank"
                                                className="text-light-gray fs-18 text-decoration-none fw-light d-flex align-items-center gap-2 flex-wrap"
                                            >
                                                <div className="social-icon">
                                                    <img src={Telegram} />
                                                </div>
                                                <span> Telegram</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.linkedin.com/company/lyberfx/?viewAsMember=true "
                                                target="_blank"
                                                className="text-light-gray fs-18 text-decoration-none fw-light d-flex align-items-center gap-2 flex-wrap"
                                            >
                                                <div className="social-icon">
                                                    <img src={Linkedin} />
                                                </div>
                                                <span>Linkedin</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/lyberofficial/"
                                                target="_blank"
                                                className="text-light-gray fs-18 text-decoration-none fw-light d-flex align-items-center gap-2 flex-wrap"
                                            >
                                                <span className="social-icon">
                                                    <img src={Instagram} />
                                                </span>
                                                <span className="ms-1">Instagram</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/LyberOfficial/"
                                                target="_blank"
                                                className="text-light-gray fs-18 text-decoration-none fw-light d-flex align-items-center gap-2 flex-wrap"
                                            >
                                                <div className="social-icon">
                                                    <img src={Facebook} />
                                                </div>
                                                <span className="ms-1">Facebook</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://medium.com/@LyberOfficial"
                                                target="_blank"
                                                className="text-light-gray fs-18 text-decoration-none fw-light d-flex align-items-center gap-2 flex-wrap"
                                            >
                                                <div className="social-icon">
                                                    <img src={Medium} />
                                                </div>
                                                <span className="MS-1">Medium</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xs-4 col-sm-4">
                                    <h6 className="footer-title fs-18 ff-atyp">{language_contant.Footer.contact()}</h6>
                                    <ul className="list-unstyled social-list">
                                        {/* <li>
                                            <a
                                                href="/contact"
                                                target="_blank"
                                                className="text-light-gray fs-18 text-decoration-none fw-light d-flex align-items-center gap-2 flex-wrap"
                                            >
                                                <span>{language_contant.Footer.byEmail()}</span>
                                            </a>
                                        </li> */}
                                        <li>
                                            <a
                                                href="/contact"
                                                target="_blank"
                                                className="text-light-gray fs-18 text-decoration-none fw-light d-flex align-items-center gap-2 flex-wrap"
                                            >
                                                <span>{language_contant.Footer.contactForm()}</span>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a
                                                href="https://t.me/LyberOfficial"
                                                target="_blank"
                                                className="text-light-gray fs-18 text-decoration-none fw-light d-flex align-items-center gap-2 flex-wrap"
                                            >
                                                <span>Telegram</span>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-5">
                        <div className="col-md-12">
                            <p className="f-bottom-text mb-0">{language_contant.Footer.footertwo()}</p>
                        </div>
                    </div>
                </div>
            </footer>

            {authState.areCookiesAccepted == null && (
                <div className="cookiess py-3">
                    <div className="container">
                        <div className="cookies-peraghraph">
                            <p className="text-center cokies-peraghraph">
                                {lang === "other"
                                    ? "En cliquant sur Accepter, vous acceptez notre "
                                    : "By clicking on Accept, you agree to our "}

                                {/* By continuing to browse this site, you agree to our{" "} */}

                                <a className="me-1 setings" href="/privacy">
                                    {lang === "other" ? "politique de confidentialité," : "privacy policy,"}
                                </a>
                                {lang === "other"
                                    ? "notre "
                                    : "our "}
                                <a className="me-1 setings" href="/cookie">
                                    {lang === "other" ? "politique de gestion des cookies" : "cookie management policy"}
                                </a>
                                {lang === "other"
                                    ? "et notamment l'utilisation de cookies pour recueillir des statistiques d'audience et améliorer votre expérience utilisateur afin de vous proposer des contenus personnalisés . En savoir plus sur les paramètres des cookies ci-dessous."
                                    : "and in particular to the use of cookies to gather audience statistics and improve your user experience to offer you personalised content. Learn more about the settings of Cookies below."}
                                {/* and in particular to the use of cookies to gather audience
                statistics, improve your user experience and offer you
                personalized content. Learn more about the setting of Cookies
                Cookies settings Refuse */}
                            </p>
                            <div className="cookies-button text-center">
                                <a
                                    href="#"
                                    className="me-4 setings"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                >
                                    {lang === "other" ? "Paramètres cookies" : "Cookies settings"}
                                </a>
                                <button
                                    type="submit"
                                    className="me-2 btn btn-primary br-10 py-2 fs-14"
                                    onClick={() => cookieAction(true)}
                                >
                                    {lang === "other" ? "Accepter" : "Accept"}
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-outline-primary br-10 py-2 fs-14"
                                    onClick={() => cookieAction(false)}
                                >
                                    {lang === "other" ? "Refuser" : "Decline"}
                                </button>
                            </div>
                            <div
                                class="modal fade "
                                id="exampleModal"
                                tabindex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div className="text-end pt-2 shadow-none">
                                            <button
                                                type="button"
                                                className="btn-close shadow-none"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            ></button>
                                        </div>
                                        <div class="modal-header border-0 pb-0 pt-0">
                                            <h6 class="modal-title" id="exampleModalLabel">
                                                Privacy Overview
                                            </h6>
                                        </div>
                                        <div class="modal-body pt-2">
                                            <p>
                                                By pursuing your navigation on our site, you accept the use of "cookies"
                                                intended to improve the performance of this site, to adapt its
                                                functionalities.
                                            </p>
                                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                                <div class="accordion-item rounded mb-2">
                                                    <h2 class="accordion-header " id="flush-headingTwo">
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <button
                                                                class="accordion-button collapsed shadow-none mb-0 ms-4"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#flush-collapseTwo"
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapseTwo"
                                                            >
                                                                Google analytics cookies
                                                            </button>

                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <p className="mb-0 left-content me-3"> {
                                                                    googleCookieSlider == true ? "Enabled" : "Disabled"
                                                                }</p>
                                                                <label class="custom-switch">
                                                                    <input type="checkbox" onClick={changeGoogleCookieSlider} checked={googleCookieSlider} />
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </h2>
                                                    <div
                                                        id="flush-collapseTwo"
                                                        class="accordion-collapse collapse"
                                                        aria-labelledby="flush-headingTwo"
                                                        data-bs-parent="#accordionFlushExample"
                                                    >
                                                        <div class="accordion-body mb-2 bg-white">
                                                            <p>
                                                                Google analytics cookies are used for analytics help collect data
                                                                that allows Lyber to understand how you interact with our service.
                                                                These insights allow services to both improve content and build
                                                                better features that enhance your experience.{" "}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="accordion accordion-flush" id="accordionFlushExample2">
                                                <div class="accordion-item rounded mb-2">
                                                    <h2 class="accordion-header " id="flush-headingThree">
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <button
                                                                class="accordion-button collapsed shadow-none mb-0 ms-4"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#flush-collapseThree"
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapseThree"
                                                            >
                                                                Meta analytics cookies
                                                            </button>

                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <p className="mb-0 left-content me-3"> {
                                                                    metaCookieSlider == true ? "Enabled" : "Disabled"
                                                                }</p>
                                                                <label class="custom-switch">
                                                                    <input type="checkbox" onClick={changeMetaCookieSlider} checked={metaCookieSlider} />
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </h2>
                                                    <div
                                                        id="flush-collapseThree"
                                                        class="accordion-collapse collapse"
                                                        aria-labelledby="flush-headingThree"
                                                        data-bs-parent="#accordionFlushExample2"
                                                    >
                                                        <div class="accordion-body mb-2 bg-white">
                                                            <p>
                                                                Meta analytics cookies are used for analytics help collect data
                                                                that allows Lyber to understand how you interact with our service.
                                                                These insights allow services to both improve content and build
                                                                better features that enhance your experience.{" "}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer border-0 pb-4 pt-0">
                                            {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                                            <button
                                                type="button"
                                                class="btn btn-primary br-10 fs-14"
                                                data-bs-dismiss="modal"
                                                onClick={() => cookieAction(true)}
                                            >
                                                Save & Accept
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Footer;
