import { Table } from "react-bootstrap"

export const CookiePolicyFR = () => {
    return (
        <>
            <section className="privacy-policy pb-5 mb-3">
                <div className="container">
                    <h2 className="term-heading text-center mt-lg-4 mt-2 text-capitalize">
                        POLITIQUE RELATIVE AUX COOKIES DE LYBER
                    </h2>
                    <br />
                    <br />
                    <br />

                    <p>
                        LYBER est soucieux de vous apporter le meilleur service possible et nous souhaitions améliorer de
                        façon continue votre expérience utilisateur en améliorant votre expérience de navigation et
                        d’améliorer le site pour qu’il soit plus convivial et attractif.
                    </p>
                    <p>
                        Notre site peut utiliser et autoriser des tiers à placer sur notre site des cookies qui peuvent recueillir
                        et conserver certaines informations vous concernant.
                    </p>
                    <p>
                        Certains de ces cookies sont nécessaires pour fournir, sécuriser et maintenir les fonctions de base du
                        site, telles que vous garder connecté pendant votre visite sur notre site et surtout des cookies qui
                        sont utilisés pour vous offrir une meilleure expérience utilisateur.
                    </p>
                    <p>
                        En effet, les cookies ou bien appelés communément « les traceurs » sont des fichiers déposés au
                        niveau du navigateur d’ordinateur (internet explorer, firefox, google chrome, etc.), de tablette et de
                        smartphone. Néanmoins, l’application de LYBER n’utilise pas de cookies.
                    </p>
                    <br />
                    <br />
                    <br />
                    <h3 className="mt-lg-4 mt-2 text-capitalize">
                        DÉFINITION
                    </h3>
                    <br />

                    <p>
                        La CNIL « Commission Nationale de l’Informatique et des Libertés » définit un cookie comme « un
                        petit fichier stocké par un serveur dans le terminal (ordinateur, téléphone, etc.) d’un utilisateur et
                        associé à un domaine web (c’est à dire dans la majorité des cas à l’ensemble des pages d’un même
                        site web). Ce fichier est automatiquement renvoyé lors de contacts ultérieurs avec le même
                        domaine ».
                    </p>
                    <br />
                    <br />
                    <br />
                    <h3 className="mt-lg-4 mt-2 text-capitalize">
                        LES TYPES DE COOKIES UTILISÉS PAR LE SITE LYBER
                    </h3>
                    <br />
                    <ul>
                        <li>
                            <span style={{ textDecoration: "underline" }}>Cookies strictement nécessaires:</span> « Il s'agit des traceurs strictement nécessaires à la fourniture
                            d'un service de communication en ligne expressément demandé par l'utilisateur ou bien les
                            traceurs qui visent à permettre ou faciliter la transmission de la communication par voie
                            électronique » ;
                        </li>
                        <br />
                        <li>
                            <span style={{ textDecoration: "underline" }}>Cookies de performance:</span> « recueillent des informations sur la façon dont le site est utilisé afin
                            que nous puissions l'améliorer » ;
                        </li>
                        <br />
                        <li>
                            <span style={{ textDecoration: "underline" }}>Cookies de fonctionnalité:</span> « nous permettent de nous souvenir des choix que vous faites (tels
                            que votre langue préférée, votre pays ou d'autres paramètres en ligne) et de vous proposer
                            les fonctionnalités personnalisées ou améliorées de votre choix » ;
                        </li>
                        <br />
                        <li>
                            <span style={{ textDecoration: "underline" }}>Cookies de réseaux sociaux:</span> « fournissent des modules de partage sur les réseaux sociaux ».
                        </li>
                    </ul>
                    <br />
                    <br />
                    <br />
                    <Table hover striped className="roundedTable mb-5">
                        <thead>
                            <tr>
                                <th width="25%" className="purple-light">Nom</th>
                                <th width="25%" className="purple-light">Finalité</th>
                                <th width="25%" className="purple-light">Utilisation</th>
                                <th width="25%" className="purple-light">Durée de conservation</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            <tr>
                                <td className="light-black"><b>_gcl_au</b></td>
                                <td className="light-black">Suivi de l'activité des
                                    utilisateurs sur le site Web</td>
                                <td className="light-black">Ce cookie est utilisé pour distinguer les
                                    utilisateurs uniques en attribuant un
                                    identifiant unique à chaque visiteur. Il
                                    permet de suivre les interactions des
                                    utilisateurs sur le site Web.</td>
                                <td className="light-black">90 jours</td>
                            </tr>
                            <tr>
                                <td className="light-black"><b>Appsflyer SDK (not a cookie)</b></td>
                                <td className="light-black">Suivi de l'activité des
                                    utilisateurs sur l’application.</td>
                                <td className="light-black">Ce SDK est utilisé pour différencier les
                                    utilisateurs uniques en attribuant un
                                    identifiant unique à chaque visiteur. Il
                                    permet de suivre les interactions des
                                    utilisateurs sur l’application mobile et
                                    de créer des rapports d'analyse.</td>
                                <td className="light-black">-</td>
                            </tr>
                            <tr>
                                <td className="light-black"><b>_fbp</b></td>
                                <td className="light-black">Suivi de l'activité des
                                    utilisateurs sur le site Web.</td>
                                <td className="light-black">Ce cookie est également utilisé pour
                                    différencier les utilisateurs uniques en
                                    attribuant un identifiant unique à
                                    chaque visiteur. Il permet de suivre les
                                    interactions des utilisateurs sur le site
                                    Web et de créer des rapports
                                    d'analyse.</td>
                                <td className="light-black">3 mois</td>
                            </tr>
                            <tr>
                                <td className="light-black"><b>cf_clearance</b></td>
                                <td className="light-black">Cookies strictement
                                    nécessaires</td>
                                <td className="light-black">Le cookie Clearance stocke la preuve
                                    que le défi a été relevé. Il est utilisé
                                    pour ne plus émettre de défi s'il y en a
                                    un. Il est nécessaire pour atteindre un
                                    serveur d'origine.</td>
                                <td className="light-black">12 mois</td>
                            </tr>
                        </tbody>
                    </Table>
                    <br />
                    <br />
                    <br />
                    <h3 className="mt-lg-4 mt-2 text-capitalize">
                        CONSENTEMENT
                    </h3>
                    <br />
                    <p>Bien entendu vous avez la possibilité d’accepter ou de refuser la présente politique de gestion des
                        cookies de LYBER via le paramétrage de gestion des cookies et à tout moment de votre navigation sur
                        le Site de lyber.com</p>
                    <p>De façon générale, au moment de la première visite sur notre site internet, un bandeau informatif
                        vous propose « d’accepter » ou de « refuser » ou de « retirer » l’utilisation de certains cookies.</p>
                    <p>En refusant certains cookies, nous ne pouvons garantir la meilleure expérience utilisateur possible.</p>
                    <br />
                    <br />
                    <br />
                    <h3 className="mt-lg-4 mt-2 text-capitalize">
                        COMMENT RETIRER OU GÉRER CERTAINS COOKIES
                    </h3>
                    <br />
                    <p>Il est possible de retirer ou de gérer les cookies via le paramétrage de navigateur. Toutefois, il est à
                        noter qu’il est possible que certaines fonctionnalités ne puissent fonctionner de manière optimale.</p>
                    <p>A titre d’information, la configuration de chaque navigateur est différente :</p>
                    <ul>
                        <li>Safari: <a href="https://support.apple.com/fr-fr/safari">https://support.apple.com/fr-fr/safari</a></li>
                        <li>Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en">https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en</a></li>
                        <li>Mozilla: <a href="https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent">https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent</a></li>
                        <li>Microsoft Edge: <a href="https://support.microsoft.com/fr-fr/topic/afficher-les-cookies-dans-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879">https://support.microsoft.com/fr-fr/topic/afficher-les-cookies-dans-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879</a></li>
                        <li>Opera: <a href="https://help.opera.com/en/latest/web-preferences/#cookies">https://help.opera.com/en/latest/web-preferences/#cookies</a></li>
                        <li>Brave: <a href="https://brave.com/fr/privacy-updates/21-blocking-cookie-notices/">https://brave.com/fr/privacy-updates/21-blocking-cookie-notices/</a></li>
                    </ul>
                    <br />
                    <br />
                    <br />
                    <h3 className="mt-lg-4 mt-2 text-capitalize">
                        Pour aller plus loin...
                    </h3>
                    <br />
                    <p>Nous vous invitons à prendre connaissance des conseils de la CNIL pour maîtriser votre navigateur en <a href="https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur">cliquant ici.</a></p>
                    <p>Par ailleurs, nous vous invitons également à prendre connaissance de vos droits relatifs à la
                        protection de vos données dans notre <a href="https://www.lyber.com/privacy"></a>politique de confidentialité.</p>
                    <p>Enfin, LYBER SAS a nommé un DPO, si vous avez des questions relatives à l'utilisation par LYBER de
                        vos données personnelles tel que décrit dans cette politique de confidentialité, ou si vous souhaitez
                        présenter une requête au titre de vos droits ou sur la gestion des cookies, veuillez contacter notre
                        DPO.</p>
                    <p>Adresse e-mail: <a href="mailto:dpo@lyber.com">dpo@lyber.com</a></p>
                    <br />
                    <br />
                    <br />
                    <h3 className="mt-lg-4 mt-2 text-capitalize">
                        MODIFICATION DES CONDITIONS DE LA PRÉSENTE POLITIQUE DE GESTION DES COOKIES
                    </h3>
                    <br />
                    <p>Ces Conditions remplacent toutes informations précédemment fournies au sujet de nos pratiques sur
                        la gestion des cookies. Elles n'ont pas vocation à remplacer tout autre accord ou conditions que vous
                        pourriez avoir avec nous dans le cadre d'un accord particulier.</p>
                    <p>Nous nous réservons le droit de modifier ces Conditions à tout moment.</p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                </div>
            </section>
        </>
    );
};
