import { Table } from "react-bootstrap"

export const PrivacyPolicyEN = () => {
    return (
        <>
            <section className="privacy-policy pb-5 mb-3">
                <div className="container">
                    <h2 className="term-heading text-center mt-lg-4 mt-2 text-capitalize">
                        Privacy Policy
                    </h2>
                    <br />
                    <br />
                    <br />

                    <p>
                        LYBER SAS undertakes, in its capacity as data controller, to respect the confidentiality of your
                        personal data in accordance with the French Data Protection Act (Loi Informatique et Liberté - LIL)
                        dated January 6, 1978, as amended by the Act of June 21, 2018, and Regulation (EU) 2016/679 of the
                        European Parliament and of the Council of April 27, 2016 (" <b>GDPR</b> ") and more broadly with all legal
                        provisions in force (hereinafter collectively the " <b>Applicable Law</b> ").

                    </p>
                    <p>
                        These terms and conditions "(the Terms)" explain:<br />
                        How LYBER SAS as data controller, hereinafter "LYBER" collects and uses your personal data in its online
                        services. Our online services include our mobile applications (referenced on this website), the websites
                        published by LYBER.COM, including this website, our social network accounts, as well as any other online
                        service (collectively the " <b>Site</b> "). Your personal information is collected when you create an online account
                        on our Site, when you sign up to receive newsletters from us or when you participate in our ACADEMY training
                        or events organized by LYBER.
                    </p>
                    <p>You will find our contact information in the legal notice section.</p>
                    <p>
                        Note: The Site is intended for the general public and is not designed or intended to target minors
                        under the age of 18. We do not knowingly target or collect personal information from anyone under
                        the age of 18.
                    </p>

                    <br />
                    <h3>1. Collection and purposes of personal data processing by Lyber</h3>
                    <br />

                    <p>
                        LYBER provides a platform that allows users ("You") to buy, sell, trade and preserve crypto-assets
                        (the "Services"). When You use the Services, through any terminal, application or device (your
                        "Equipment"), LYBER collects certain information that identifies You, whether directly or indirectly
                        ("Personal Data" or "Data"). Details of the Data collected by LYBER are set out below.
                    </p>
                    <p>
                        LYBER firmly believes that trust is the key to its relationship with You. In this respect, the protection
                        of Your Data and Your privacy is LYBER's top priority. This is why LYBER places great importance on collecting
                        and processing Your Data with the utmost care and in compliance with the applicable legal framework.
                    </p>
                    <p>
                        You are never obliged to provide the requested Data. Nevertheless, LYBER draws Your attention to the
                        fact that, in such a case, access to the Services may be altered or even impossible.
                    </p>
                    <p>
                        In order to inform You in a transparent manner, LYBER has established this privacy policy
                        (the "Privacy Policy"), which explains in detail why and how Your Data is collected and processed when
                        You use the Services.
                    </p>
                    <p>
                        The Privacy Policy is available at all times on the LYBER platform and supersedes all other
                        documents and previous versions. The Privacy Policy is subject to change. The current version is the
                        one that is available on the platform when You use the Services.
                    </p>
                    <p>
                        Based on our legitimate interests, we implement processing for the following purposes:
                    </p>
                    <ul>
                        <li>The management of your requests for information and complaints;</li>
                        <li>The prevention and fight against fraud;</li>
                        <li>The establishment of any means of proof necessary for the defense of our rights;</li>
                        <li>The management of cookies not subject to consent;</li>
                        <li>
                            To carry out statistics and analyses in order to improve our services and
                            products, as well as our Sites.
                        </li>
                    </ul>

                    <p>
                        If we need to process your personal data for purposes other than those listed in this tab, we will
                        inform you at the time of implementation of the processing concerned and will proceed with the necessary steps.
                    </p>

                    <br />
                    <h3>2. Collected Data</h3>
                    <br />

                    <p>
                        When You use the Services, LYBER collects and processes Data for particular purposes, each of
                        which is duly legitimized by a valid legal basis.
                    </p>
                    <p>
                        <u>Browsing Information:</u> You are informed that information may be transmitted to your
                        browser or Equipment by the Service when using the platform ("Cookies"). LYBER wishes to
                        understand how You interact with its Services. To do so, LYBER processes various navigation
                        information on its platform, such as the pages You have visited, the date and time of your
                        connection and Your IP address. This navigation information results from Cookies.
                    </p>
                    <p>
                        LYBER collects and processes this information for analytical purposes related to Your use
                        of the Services. In this regard, the legal basis for LYBER is its legitimate interest in
                        (i) understanding how its platform is browsed by users and visitors (including You); and
                        (ii) improving the platform if necessary.
                    </p>
                    <p>
                        LYBER informs You that Cookies can be set in the help menu of your browser, at the following
                        URLs: Google; Mozilla Firefox; Safari; Edge and Opera. Please note that setting or refusing
                        certain Cookies may affect your ability to use the Services and your navigation of the Platform,
                        whenever these Cookies are necessary for the performance of LYBER.
                    </p>
                    <p>
                        <u>Discussions with or comments on LYBER:</u> You may want or need to interact with LYBER,
                        to ask questions, submit requests or comments. To manage, respond to and follow up on any
                        of your questions, LYBER will collect and process Data such as your identification (i.e.,
                        information you provide to LYBER such as your name and email address) and the date, time
                        and content of your question. Please note that LYBER will only collect this Data when You
                        interact directly to LYBER's email or postal mail. Otherwise, Your Personal Data will not
                        be processed for this purpose.
                    </p>
                    <p>
                        You are under no obligation to provide this information, but please note that if You do not,
                        LYBER will not be able to respond to Your questions.
                    </p>
                    <p>
                        LYBER collects and processes this Data for its legitimate interest, which is to properly
                        manage its relationship with You.
                    </p>
                    <p>
                        <u>Account Management:</u> If You wish to access and use the Services, You must create an
                        account on the Platform (in order to know how to create an account, please consult LYBER's
                        Terms and Conditions). Creating such an account requires You to go through an extensive
                        know-your-customer ("KYC") process. To create and manage Your account, LYBER collects
                        and processes the following Data:
                    </p>
                    <ul>
                        <li>
                            For individuals: title; first names; surnames; date and place of birth of the person concerned;
                            nationality; home address; valid ID; e-mail address; IP address; telephone number; business sector
                            address; IP address; telephone number; business sector; login and password to access the
                            account; and if necessary, any personal data included in the explanation of the purpose of the
                            business relationship, proof of address, proof of annual income and proof of assets (e.g., bank accounts).
                            proof of assets (movable and/or real estate) and tax assessment.
                        </li>
                        <li>
                            For companies: legal form; business name; RCS registration number; registered office address;
                            Kbis (or foreign equivalent); articles of association; first names, surnames, date and
                            place of birth of the beneficial owners and the legal representative; valid identification
                            of the beneficial owners and the representative; e-mail address of the legal representative;
                            telephone number of the legal representative; sector of activity; and if required, the
                            personal data included in the explanation of the purpose of the business relationship,
                            the power of attorney of the representative if he/she is not a corporate officer,
                            the average annual turnover for the last three financial years or the amount of equity
                            and quasi-equity for new companies; proof of domicile, proof of the amount of turnover or,
                            for new companies, proof of equity and quasi-equity, the last tax return or, for new companies,
                            an intermediary statement of account.
                        </li>
                    </ul>
                    <p>
                        Moreover, for legal entities (other than companies), additional information may be requested.
                    </p>
                    <p>
                        It should be noted that all American nationals must declare themselves to LYBER at the
                        time of their subscription, and a form may be requested. (See our general conditions in the
                        section " creation of a customer account ").
                    </p>
                    <p>
                        LYBER also collects images such as a photo or a video during the process of getting to know the client.
                    </p>
                    <p>
                        Finally, all information relating to communications between LYBER and the user such as email
                        messages, chat and online forms, (etc.) will be collected and kept.
                    </p>
                    <p>
                        LYBER collects and processes this Data because it is necessary (i) for the performance of a
                        contract to which You are a party and (ii) to comply with LYBER's obligation to combat money
                        laundering and the financing of terrorism (AML/CFT).
                    </p>
                    <p>
                        <u>Order Processing:</u> As part of your use of the Services, you may initiate certain orders.
                        LYBER will collect and process the following Data to manage these orders and their billing:
                    </p>
                    <ul>
                        <li>
                            For individuals: account number, first and last name, email address, IP address, digital
                            asset portfolio information.
                        </li>
                        <li>
                            For legal entities: account number, business name, registration number, email address,
                            IP address, digital asset portfolio information.
                        </li>
                    </ul>
                    <p>
                        LYBER collects and processes this Data because it is necessary for the performance of a
                        contract to which You are a party.
                    </p>
                    <p>
                        <u>Payment and Withdrawal Management:</u> Payments and withdrawals in euros are managed
                        by external service providers, as described below (please see section 4, "Data Recipients").
                        The Data collected by these external service providers is necessary for Your use of the Services.
                    </p>
                    <p>
                        The service providers collect this Data because it is necessary (i) to perform a contract
                        to which You are a party and (ii) to comply with their obligations regarding card fraud.
                    </p>
                    <p>
                        Failure to provide certain requested information may delay or make impossible the processing
                        of your request, the response to your questions and/or the provision of products and/or services,
                        and/or the formalization of a contract between you and LYBER. In this respect, certain data are
                        mandatory (indicated by an * in the forms), and you are informed that in their absence, LYBER
                        and its service provider (EMI) will not be able to process your request.
                    </p>
                    <p>
                        We recommend that you provide only the data requested or necessary for your request. LYBER does
                        not request or process sensitive data such as data revealing racial or ethnic origin, political
                        opinions, data concerning sexual life or sexual orientation, religious or philosophical convictions,
                        as well as health data (except in very specific cases for which there is a legal obligation
                        (labour law) or after obtaining your express agreement and the implementation of appropriate measures).
                    </p>

                    <br />
                    <h3>3. Retention of personal data</h3>
                    <br />

                    <p>
                        LYBER stores Personal Data for a limited period of time, not exceeding the fulfillment of the
                        purposes described in section 2 of this Privacy Policy.
                    </p>

                    <p>
                        Please note that if LYBER considers that it does not need to keep Your Personal Data in its
                        active database, it will archive it and ensure that access to it is limited to a restricted
                        number of persons with a real need to access Your Data.
                    </p>

                    <p>
                        <u>Browsing Information:</u> Cookies are kept for a maximum of thirteen (13) months
                        months from the time they are placed on your browser or Equipment. At the end of this period,
                        Your consent will again be required.
                    </p>
                    <p>
                        <u>Discussions or comments on LYBER:</u> With respect to the management of your questions,
                        requests or comments, LYBER will not retain Your Personal Data for more than five (5) years
                        after the last contact at Your initiative.
                    </p>
                    <p>
                        <u>Account Management:</u> LYBER will retain Data until your account is closed. However,
                        if LYBER is required to retain Data beyond the closure of Your account for evidentiary purposes,
                        the maximum retention period will be in accordance with the statutory limitation period.
                    </p>
                    <p>
                        <u>Order management:</u> LYBER will keep Your Data for the entire duration of the commercial
                        relationship and for ten (10) years beyond that date in order to comply with accounting obligations.
                    </p>
                    <p>
                        Concerning the conservation of any Data constituting a means of proof to face possible claims,
                        Your Data will be kept for thirteen (13) months, after the debit date. This Data must be stored
                        in an intermediate backup and can only be used in the event of a dispute regarding the transaction.
                    </p>

                    <br />
                    <h3>4. Recipients of personal data</h3>
                    <br />

                    <p>
                        LYBER may share Your Personal Data with third party service providers and suppliers who
                        assist LYBER in fulfilling the purposes specified in this Privacy Policy. Service providers
                        and suppliers may have access to Personal Data for the sole and exclusive purpose of carrying
                        out the tasks entrusted to them. LYBER ensures that the service providers and suppliers present
                        sufficient guarantees for the execution of the mission and comply with the applicable laws and regulations.
                    </p>
                    <p>
                        LYBER may share your Personal Data. Your Personal Data
                        may be shared in order to comply with legal and regulatory obligations, to comply with LYBER's
                        AML-CFT policy, for legitimate interests such as fraud prevention and/or business conduct.
                    </p>
                    <p>
                        LYBER may share Your Personal Data with service providers and subcontractors for the provision
                        of payment processes, for identity verification, for the compliance of Your information when
                        creating Your account, for the provision of technical infrastructure and system hosting, for
                        customer relationship management, for the provision of a support service to You, for the performance
                        of surveys and studies. LYBER ensures that these service providers present sufficient guarantees
                        for the performance of the mission and comply with the laws and regulations in force.
                    </p>
                    <p>
                        In addition, where applicable, LYBER may share Your Personal Data with the competent
                        courts and any other duly authorized governmental and/or public authority requesting access to
                        Your Data, to the extent legally permitted.
                    </p>
                    <p>
                        Where applicable, for example in the event of legal action, LYBER may share Your
                        Personal Data with practitioners of regulated professions such as lawyers, notaries and
                        auditors requesting access to Your Data, to the extent legally permitted.
                    </p>
                    <p>
                        In any event, LYBER will disclose Your Personal Data to the above-mentioned recipients
                        on a strict need-to-know basis and only to the extent necessary to fulfill the duly identified processing purposes.
                    </p>

                    <br />
                    <h3>5. Security</h3>
                    <br />

                    <p>
                        We ensure that we take all necessary technical and organizational security measures to
                        prevent and minimize the risks associated with the provision of your personal data.
                        These measures include, where appropriate, the use of firewalls, the installation of
                        secure servers, the implementation of appropriate access rights management systems and
                        processes, careful selection of tools and any other measures necessary to ensure appropriate
                        protection of your personal data against unauthorized use or disclosure. In the case of online
                        transactions, we will use standard security measures, such as those available through
                        Secure Sockets Layer (SSL), to protect the privacy and security of online transactions.
                    </p>
                    <p>
                        Although the LYBER Site is secure, like any site, security breaches and errors can occur.
                        You should be aware that phishing is a common practice, as is website hacking or the
                        existence of fake websites, for which we cannot be held responsible.
                    </p>

                    <br />
                    <h3>6. Transfer of data outside the EU</h3>
                    <br />

                    <p>
                        Personal Data may be processed outside the territory of the European Union (EU).
                        In this situation, LYBER takes all necessary precautions and alternatively or cumulatively
                        ensures that (i) an adequacy decision has been taken by the European Commission regarding
                        the country of destination with regard to Article 45 of the GDPR; (ii) contractual clauses
                        adopted by the European Commission or the supervisory authority have been signed with the
                        recipient; (iii) the recipient adheres to an approved code of conduct or certification mechanism.
                    </p>

                    <br />
                    <h3>7. Your rights</h3>
                    <br />

                    <p>
                        As a data subject, You have various rights regarding the processing of Your Personal Data.
                        These are as follows:
                    </p>

                    <Table hover striped className="roundedTable mb-5">
                        <thead>
                            <tr>
                                <th className="purple-light text-center" colSpan="3">Your rights</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            <tr>
                                <td className="light-black">
                                    <b>Right of rectification</b>
                                </td>
                                <td className="light-black">
                                    You have the right to rectify Your Data when it is inaccurate or incomplete.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-black">
                                    <b>Access right</b>
                                </td>
                                <td className="light-black">
                                    You have the right to request from LYBER a copy of the Data associated with Your account.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-black">
                                    <b>Right to erasure</b>
                                </td>
                                <td className="light-black">
                                    You can obtain the deletion of Your Data by closing Your account. However, this
                                    deletion is not possible if LYBER is required to keep the Data in order to comply with
                                    its legal obligations (AML-CFT) or if the Data is necessary for the establishment,
                                    exercise or defense of legal claims.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-black">
                                    <b>Right to portability</b>
                                </td>
                                <td className="light-black">
                                    You may request a copy of Your Data in order to transfer it to another service provider.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-black">
                                    <b>Right to restriction / limitation of processing</b>
                                </td>
                                <td className="light-black">
                                    You may request LYBER to restrict / limit the processing of your Data. You can
                                    also give instructions concerning the use of your Data after your death.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-black">
                                    <b>Right to object</b>
                                </td>
                                <td className="light-black">
                                    You may object to the processing of your Data by LYBER and withdraw your consent.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-black">
                                    <b>Complaint</b>
                                </td>
                                <td className="light-black">
                                    You have the right to file a complaint with the Commission Nationale de
                                    l'Informatique et des Libertés (CNIL), which is the competent control authority.
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    <p>
                        To exercise your rights or for any question on the protection of Personal Data,
                        You must send a request accompanied by proof of identity by e-mail to LYBER - 15 rue des Halles 75001 Paris,
                        France or by e-mail to {" "}
                        <a href="mailto:dpo@lyber.com">dpo@lyber.com</a>.
                    </p>
                    <p>
                        LYBER will endeavor to respond without undue delay and at the latest within one (1)
                        month of receiving the request. LYBER reserves the right to extend this period to three (3)
                        months in the case of a complex request.
                    </p>
                    <p>
                        LYBER is committed to protecting your Personal Data and to respecting the legal framework
                        applicable to data protection.
                    </p>
                    <p>
                        This is the reason why LYBER requires your assistance to this end. Thus, You undertake
                        to inform LYBER if the Personal Data You have shared becomes obsolete or inaccurate.
                    </p>
                    <p>
                        In addition, in the event that You provide LYBER with information that directly or indirectly
                        identifies any other natural person (for example; You have sent crypto currency to a third party
                        whose account is not known to LYBER), You represent and warrant that prior to sharing the
                        information with LYBER, such other persons have been made aware of this Privacy Policy and
                        have consented to the processing of their Personal Data.
                    </p>

                    <br />
                    <h3>8. Contact details data protection officer (DPO)</h3>
                    <br />

                    <p>
                        LYBER SAS has appointed a DPO, if you have any questions about LYBER's use of your personal
                        data as described in this privacy policy, or if you wish to make a request under your rights
                        defined above, please contact our DPO.
                    </p>
                    <p>
                        E-mail address: <a href="mailto:dpo@lyber.com">dpo@lyber.com</a>
                    </p>
                    <p>Postal address: 15 rue des Halles, 75001 PARIS.</p>
                    <p>
                        If you are dissatisfied with the way we handle your personal data for any reason,
                        we encourage you to contact our DPO to raise your concern. Our DPO will ensure that
                        your concern is addressed in a timely manner by the relevant parties. You also have
                        the right to lodge a complaint with the local data protection authority or a competent
                        supervisory authority.
                    </p>

                    <br />
                    <h3>9. Changes in conditions</h3>
                    <br />

                    <p>
                        These Terms supersede any information previously provided about our personal data
                        practices. They are not intended to replace any other agreement or terms you may have
                        with us under any particular agreement.
                    </p>
                    <p>We reserve the right to change these Terms at any time.</p>
                </div>
            </section>
        </>
    );
};
