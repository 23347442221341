// import superagentPromise from "superagent-promise";
// import _superagent from "superagent";

// const superagent = superagentPromise(_superagent, global.Promise);

// const API_ROOT = 'https://landing-api.lyber.com:3001/'; //staging
export const API_ROOT = "https://landing-api.lyber.com"; //live


// const API_FILE_ROOT_MEDIUM = "https://sportybooking.s3.amazonaws.com/Uploads/Images/Medium/";
// const API_FILE_ROOT_ORIGINAL = "https://sportybooking.s3.amazonaws.com/Uploads/Images/Original/";

// const encode = encodeURIComponent;
// const responseBody = (res) => res.body;

// let token = null;
// const tokenPlugin = (req) => {
//     if (token) {
//         req.set("authorization", `${token}`);
//     }
// };

// const requests = {
//     del: (url) => superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
//     get: (url) => superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
//     put: (url, body) => superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
//     patch: (url, body) => superagent.patch(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
//     post: (url, body) => superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
// };
// const user = {
//     article: () => requests.get(`user/news_articles?type=Article`),
//     cryptoUpdate: () => requests.get(`user/news_articles?type=Crypto%20Updates`),
//     lyberUpdate: () => requests.get(`user/news_articles?type=Lyber%20Updates`),
// };

// const Auth = {
//     login: (info) => requests.post("user/login", info),
//     signup: (info) => requests.post("signup", info),
//     forgotPassword: (info) => requests.post("forgot-password", info),
//     checkOtp: (info) => requests.post("check-email-otp", info),
//     resetPassword: (token, password) => requests.post("User/password/reset", { token, password }),
//     verifyEmail: (token) => requests.post("user/verify/email", { token }),
//     preRegister: (items) => requests.post("user/pre-register", items),
// };
// const Unsubscribe = {
//     preRegisterDelete: (email, language) => requests.del(`user/pre-register?email=${email}&language=${language}`),
// };
// const Dashboard = {
//     get: () => requests.get(`admin/dashboard`),
// };
// const Activity = {
//     create: (info) => requests.post("nft", info),
//     facilityActivity: (id) => requests.get(`facility/activity?room_type_id=${id}`),
//     getDetails: (id) => requests.get(`nft/${id}`),
// };

// const Search = {
//     pagination: (
//         left_lat,
//         left_long,
//         right_lat,
//         right_long,
//         start_date,
//         end_date,
//         start_time,
//         end_time,
//         low_price,
//         high_price,
//         room_type_id,
//         activity_type_id,
//         weekends,
//         after_four,
//         user_time,
//         limit,
//         page,
//     ) =>
//         requests.get(
//             `facility/search?left_lat=${left_lat}&left_long=${left_long}&right_lat=${right_lat}&right_long=${right_long}&start_date=${start_date}&end_date=${end_date}&start_time=${
//                 start_time ? start_time : ""
//             }&end_time=${end_time ? end_time : ""}&low_price=${low_price ? low_price : ""}&high_price=${
//                 high_price ? high_price : ""
//             }&room_type_id=${room_type_id ? room_type_id : ""}&activity_id=${
//                 activity_type_id ? activity_type_id : []
//             }&weekends=${weekends ? weekends : ""}&after_four=${
//                 after_four ? after_four : ""
//             }&user_time=${user_time}&limit=${limit}&page=${page}`,
//         ),
//     reels: (user_id, amount) => requests.get(`reels/getReels?user_id=${user_id}&amount=${amount ? amount : 10}`),
//     getById: (id) => requests.get(`profile?id=${id}`),
// };
// const Common = {
//     category: () => requests.get(`categories`),
// };
// 
// export default {
//     Unsubscribe,
//     token,
//     Auth,
//     Common,
//     Search,
//     user,
//     Dashboard,
//     Activity,
//     API_ROOT,
//     API_FILE_ROOT_MEDIUM,
//     API_FILE_ROOT_ORIGINAL,
//     setToken: (_token) => {
//         token = _token;
//     },
// };
