import { Table } from "react-bootstrap"

export const PrivacyPolicyFR = () => {
    return (
        <>
            <section className="privacy-policy pb-5 mb-3">
                <div className="container">
                    <h2 className="term-heading text-center mt-lg-4 mt-2 text-capitalize">
                        Politique de confidentialité
                    </h2>
                    <br />
                    <br />
                    <br />

                    <p>
                        LYBER SAS s'engage, en sa qualité de responsable de traitement, à respecter la confidentialité
                        de vos données à caractère personnel conformément à la Loi Informatique et Liberté (LIL) en date
                        du 6 Janvier 1978 modifiée par la loi du 21 Juin 2018, et au Règlement (UE) 2016/679 du
                        Parlement européen et du Conseil du 27 avril 2016 (« <b>RGPD</b> ») et plus largement avec
                        toutes les dispositions légales en vigueur (ci-après collectivement la « <b>Loi Applicable</b> »).
                    </p>
                    <p>
                        Les présentes conditions “(les Conditions)” expliquent :<br />
                        Comment la société LYBER SAS en sa qualité de responsable de traitement, ci-après « LYBER »
                        collecte et utilise vos données à caractère personnel dans ses services en lignes. Nos services
                        en ligne incluent nos applications mobiles (référencées sur le présent site internet), les sites
                        internet édités par LYBER.COM, dont le présent site, nos comptes sur les réseaux sociaux, ainsi
                        que tout autre service en ligne (collectivement le « <b>Site</b> »). Vos données à caractère
                        personnel sont collectées notamment lorsque vous créez un compte en ligne sur notre Site,
                        lorsque vous vous inscrivez pour recevoir des newsletters de notre part ou lorsque vous
                        participez à notre formation ACADEMY ou événements organisés par LYBER.
                    </p>
                    <p>Vous trouverez nos coordonnées dans la rubrique mention légales.</p>
                    <p>
                        Remarque : Le Site est destiné au grand public et n'est pas conçu ou destiné à cibler les
                        mineurs de moins de 18 ans. Nous ne ciblons pas, sciemment, les personnes de moins de 18 ans et
                        ne recueillons pas d'informations personnelles à leur sujet.
                    </p>

                    <br />
                    <h3>1. Collecte et finalités des traitements de données personnelles par lyber</h3>
                    <br />

                    <p>
                        LYBER fournit une plateforme qui permet aux utilisateurs ("Vous") d'acheter, de vendre,
                        d'échanger et de préserver des crypto-actifs (les "Services"). Lorsque Vous utilisez les
                        Services, par l'intermédiaire de tout terminal, application ou appareil (votre "Équipement"),
                        LYBER collecte certaines informations permettant de Vous identifier, que ce soit directement ou
                        indirectement ("Données personnelles" ou "Données"). Le détail des Données collectées par LYBER
                        est exposé ci-dessous.
                    </p>
                    <p>
                        LYBER croit fermement que la confiance est la clé de ses relations avec Vous. A cet égard, la
                        protection de Vos Données et de Votre vie privée est la priorité absolue de LYBER. C'est
                        pourquoi LYBER accorde une grande importance à la collecte et au traitement de vos Données avec
                        le plus grand soin et dans le respect du cadre légal applicable.
                    </p>
                    <p>
                        Vous n'êtes jamais obligé de fournir les Données demandées. Néanmoins, LYBER attire Votre
                        attention sur le fait que, dans un tel cas, l'accès aux Services peut être altéré, voire
                        impossible.
                    </p>
                    <p>
                        Pour Vous informer de manière transparente, LYBER a mis en place la présente politique de
                        confidentialité (la " Politique de confidentialité "), qui explique en détail pourquoi et
                        comment vos Données sont collectées et traitées lorsque Vous utilisez les Services.
                    </p>
                    <p>
                        La Politique de confidentialité est disponible à tout moment sur la plateforme de LYBER et
                        prévaut sur tout autre document et version antérieure. La Politique de confidentialité est
                        susceptible d'évoluer. La version à jour est celle qui est disponible sur la plateforme lorsque
                        Vous utilisez les Services.
                    </p>
                    <p>
                        En se fondant sur nos intérêts légitimes, nous mettons en œuvre des traitements poursuivant les
                        finalités suivantes :
                    </p>
                    <ul>
                        <li>La gestion de vos demandes d'information et réclamations ;</li>
                        <li>La prévention et la lutte contre la fraude ;</li>
                        <li>L'établissement de tout moyen de preuve nécessaire à la défense de nos droits ;</li>
                        <li>La gestion des cookies non soumis à consentement ;</li>
                        <li>
                            La réalisation de statistiques et analyses en vue d'améliorer nos services et produits,
                            ainsi que nos Sites.
                        </li>
                    </ul>

                    <p>
                        Si nous sommes amenés à traiter vos données à caractère personnel pour des finalités autres que
                        celles listées dans le présent onglet, nous vous en informerons au moment de la mise en œuvre du
                        traitement concerné et procéderons aux démarches qui s'imposent.
                    </p>

                    <br />
                    <h3>2. Données collectées</h3>
                    <br />

                    <p>
                        Lorsque Vous utilisez les Services, LYBER collecte et traite les Données pour des finalités
                        particulières, chacune d'entre elles étant dûment légitimée par une base légale valable.
                    </p>
                    <p>
                        <u>Informations de navigation :</u> Vous êtes informé que des informations peuvent être
                        transmises à votre navigateur ou Équipement par le Service lors de l'utilisation de la
                        plateforme (" Cookies "). LYBER souhaite comprendre comment Vous interagissez avec ses Services.
                        Pour ce faire, LYBER traite diverses informations de navigation sur sa plateforme, telles que
                        les pages que Vous avez visitées, la date et l'heure de votre connexion et Votre adresse IP. Ces
                        informations de navigation résultent des Cookies.
                    </p>
                    <p>
                        LYBER collecte et traite ces informations pour la réalisation d'opérations analytiques liées à
                        Votre utilisation des Services. A cet égard, la base juridique sur laquelle LYBER se fonde est
                        son intérêt légitime qui consiste à (i) comprendre la manière dont sa plateforme est parcourue
                        par les utilisateurs et les visiteurs (dont Vous) ; et (ii) améliorer la plateforme si
                        nécessaire.
                    </p>
                    <p>
                        LYBER Vous informe que les Cookies peuvent être configurés dans le menu d'aide de votre
                        navigateur, aux URL suivantes : Google ; Mozilla Firefox ; Safari ; Edge et Opera. Veuillez
                        noter que le paramétrage ou le refus de certains Cookies peut affecter votre capacité à utiliser
                        les Services et votre navigation sur la plateforme, chaque fois que ces Cookies sont nécessaires
                        à l'exécution de LYBER.
                    </p>
                    <p>
                        <u>Discussions avec ou commentaires sur LYBER :</u> Vous pouvez vouloir ou devoir interagir avec
                        LYBER, pour poser des questions, soumettre des demandes ou des commentaires. Pour gérer,
                        répondre et assurer le suivi de l'une de vos questions, LYBER collectera et traitera des Données
                        telles que votre identification (c'est-à-dire les informations que vous fournissez à LYBER
                        telles que votre nom et votre adresse électronique) et la date, l'heure et le contenu de votre
                        question. Veuillez noter que LYBER ne collectera ces Données que lorsque Vous interagissez
                        directement à l'email de LYBER ou par courrier postal. Dans le cas contraire, Vos Données
                        personnelles ne seront pas traitées à cette fin.
                    </p>
                    <p>
                        Vous n'avez aucune obligation de fournir ces informations, mais veuillez noter que si Vous ne le
                        faites pas, LYBER ne sera pas en mesure de répondre à Vos questions.
                    </p>
                    <p>
                        LYBER collecte et traite ces Données pour son intérêt légitime, qui consiste à gérer dûment ses
                        relations avec Vous.
                    </p>
                    <p>
                        <u>Gestion des comptes :</u> Si Vous souhaitez accéder et utiliser les Services, Vous devez
                        créer un compte sur la Plateforme (afin de savoir comment créer un compte, veuillez consulter
                        les Conditions générales de LYBER). La création d'un tel compte nécessite que Vous passiez par
                        un important processus de connaissance du client (" KYC "). Pour créer et gérer Votre compte,
                        LYBER collecte et traite les Données suivantes :
                    </p>
                    <ul>
                        <li>
                            Pour les personnes physiques : la civilité ; les prénoms ; les noms ; la date et le lieu de naissance
                            de la personne concernée ; la nationalité ; l’adresse du domicile ; une pièce d'identité valide ; l'adresse
                            e-mail ; l'adresse IP ; le numéro de téléphone ; le secteur d'activité ; le login et le mot de passe pour
                            accéder au compte ; et si nécessaire, toute donnée personnelle incluse dans l'explication de l'objet de la
                            relation d'affaires, le justificatif de domicile, le justificatif du montant des revenus annuels et les
                            justificatifs d’un patrimoine (mobilier et / ou immobilier) ainsi que l'avis d'imposition.
                        </li>
                        <li>
                            Pour les sociétés : forme juridique ; nom commercial ; numéro d'enregistrement RCS ; adresse
                            du siège social ; Kbis (ou équivalent étranger) ; statuts ; prénoms, noms, date et lieu de
                            naissance des bénéficiaires effectifs et du représentant légal ; pièce d'identité valide des
                            bénéficiaires effectifs et du représentant ; adresse électronique du représentant légal ;
                            numéro de téléphone du représentant légal ; secteur d'activité ; et si requis, les données
                            personnelles incluses dans l'explication de l'objet de la relation d'affaires, le pouvoir du
                            représentant s'il n'est pas un mandataire social, le chiffre d'affaires annuel moyen des
                            trois derniers exercices ou le montant des fonds propres et quasi-fonds propres pour les
                            nouvelles entreprises ; le justificatif de domicile, le justificatif du montant du chiffre
                            d'affaires ou, pour les nouvelles entreprises, le justificatif des fonds propres et
                            quasi-fonds propres, la dernière déclaration fiscale ou, pour les nouvelles entreprises, un
                            relevé de compte intermédiaire.
                        </li>
                    </ul>
                    <p>
                        Par ailleurs, pour les personnes morales (autre que les sociétés), des informations
                        complémentaires pourront être demandées.
                    </p>
                    <p>
                        Il est à noter que toutes personnes de nationalité américaine doivent se déclarer auprès de
                        LYBER au moment de sa souscription ainsi un formulaire pourrait lui être demandé. (Voir nos
                        conditions générales dans la partie « création d'un compte client »).
                    </p>
                    <p>
                        LYBER collecte également des images telles qu'une photo ou bien une vidéo lors du processus de
                        connaissance du client.
                    </p>
                    <p>
                        Enfin, toutes informations relatives aux communications entre LYBER et l'utilisateur tel que
                        message email, chabot et formulaire en ligne, (etc.) seront collectées et conservées.
                    </p>
                    <p>
                        LYBER collecte et traite ces Données car elles sont nécessaires (i) à l'exécution d'un contrat
                        auquel Vous êtes partie et (ii) au respect de l'obligation de LYBER en matière de lutte contre
                        le blanchiment d'argent et le financement du terrorisme (LCB/FT).
                    </p>
                    <p>
                        <u>Gestion des commandes :</u> Dans le cadre de votre utilisation des Services, vous pourriez
                        initier certaines commandes. LYBER collectera et traitera les Données suivantes pour gérer ces
                        commandes et leur facturation :
                    </p>
                    <ul>
                        <li>
                            Pour les personnes physiques : numéro de compte, nom et prénom, adresse email, adresse IP,
                            informations du portefeuille d'actifs numériques.
                        </li>
                        <li>
                            Pour les personnes morales : numéro de compte, nom commercial, numéro d'enregistrement,
                            adresse électronique, adresse IP, informations du portefeuille d'actifs numériques.
                        </li>
                    </ul>
                    <p>
                        LYBER collecte et traite ces Données car elles sont nécessaires à l'exécution d'un contrat
                        auquel Vous êtes partie.
                    </p>
                    <p>
                        <u>Gestion des paiements et des retraits :</u> Les paiements et retraits en euros sont gérés par
                        des prestataires externes, dans les conditions prévues ci-après (veuillez-vous reporter à la
                        section 4, " Destinataires des données "). Les Données collectées par ces prestataires externes
                        sont nécessaires à Votre utilisation des Services.
                    </p>
                    <p>
                        Les prestataires collectent ces Données car elles sont nécessaires (i) à l'exécution d'un
                        contrat auquel Vous êtes partie et (ii) au respect de leurs obligations en matière de fraude à
                        la carte.
                    </p>
                    <p>
                        La non-fourniture de certaines informations demandées peut retarder ou rendre impossible le
                        traitement de votre demande, la réponse à vos questions et/ou la fourniture des produits et/ou
                        services, et/ou la formalisation d'un contrat entre vous et LYBER. A ce titre, certaines données
                        sont obligatoires (précisées par un * dans les formulaires), et vous êtes informés qu'en leur
                        absence, LYBER ne sera pas en mesure de traiter votre demande.
                    </p>
                    <p>
                        Nous vous recommandons de ne fournir que les données demandées ou nécessaires à votre requête.
                        LYBER ne demande pas ni ne traite de données sensibles tel que les données révélant l'origine
                        raciale ou ethnique, les opinions politiques, les données concernant la vie sexuelle ou
                        l'orientation sexuelle, les convictions religieuses ou philosophiques, ainsi que les données de
                        santé (sauf cas très spécifiques pour lesquels il existe une obligation légale (droit du
                        travail) ou après obtention de votre accord express et mise en place de mesures adaptées).
                    </p>

                    <br />
                    <h3>3. Conservation des données personnelles</h3>
                    <br />

                    <p>
                        LYBER stocke les Données Personnelles pour une durée limitée, n'excédant pas l'accomplissement
                        des objectifs décrits dans la section 2 de la présente Politique de Confidentialité.
                    </p>

                    <p>
                        Veuillez noter que si LYBER considère qu'elle n'a pas besoin de conserver Vos Données
                        Personnelles dans sa base de données actives, elle les archivera et veillera à ce que leur accès
                        soit limité à un nombre restreint de personnes ayant un besoin réel d'accéder à Vos Données.
                    </p>

                    <p>
                        <u>Informations de navigation :</u> La durée maximale de conservation des Cookies est de treize (13)
                        mois à partir du moment où ils sont placés sur votre navigateur ou Équipement. A l'issue de cette période,
                        Votre consentement sera à nouveau requis.
                    </p>
                    <p>
                        <u>Discussions ou commentaires sur LYBER :</u> Concernant la gestion de vos questions, demandes
                        ou commentaires, LYBER ne conservera pas vos Données Personnelles plus de cinq (5) ans après le
                        dernier contact à votre initiative.
                    </p>
                    <p>
                        <u>Gestion des comptes :</u> LYBER conservera les Données jusqu'à la clôture de votre compte.
                        Toutefois, si LYBER est tenu de conserver les Données au-delà de la clôture de Votre compte à
                        des fins de preuve, la durée maximale de conservation sera conforme au délai de prescription
                        légal.
                    </p>
                    <p>
                        <u>Gestion des commandes :</u> LYBER conservera Vos Données pendant toute la durée de la
                        relation commerciale et pendant dix (10) ans au-delà de cette date afin de respecter les
                        obligations comptables.
                    </p>
                    <p>
                        Concernant la conservation de toute Donnée constituant un moyen de preuve pour faire face à
                        d'éventuelles réclamations, Vos Données seront conservées pendant treize (13) mois, après la
                        date de débit. Ces Données doivent être stockées dans une sauvegarde intermédiaire et ne peuvent
                        être utilisées qu'en cas de litige concernant la transaction.
                    </p>

                    <br />
                    <h3>4. Destinataires des données personnelles</h3>
                    <br />

                    <p>
                        LYBER peut partager Vos Données Personnelles avec des prestataires de services et fournisseurs
                        tiers qui aident LYBER à atteindre les objectifs spécifiés dans la présente Politique de
                        confidentialité. Les prestataires et fournisseurs peuvent avoir accès aux Données Personnelles
                        dans le seul et unique but de mener à bien les missions qui leur sont confiées. LYBER s'assure
                        que les prestataires et fournisseurs présentent des garanties suffisantes pour l'exécution de
                        la mission et respectent les lois et règlements applicables.
                    </p>
                    <p>
                        LYBER peut partager vos Données
                        Personnelles. Vos Données Personnelles peuvent être partagées afin de se conformer aux
                        obligations légales et réglementaires, de respecter la politique LCB-FT de LYBER, pour des
                        intérêts légitimes tels que la prévention de la fraude et / ou la conduite d'activité.
                    </p>
                    <p>
                        LYBER peut partager Vos Données Personnelles avec des prestataires et sous-traitants pour la
                        fourniture de processus de paiement, pour la vérification de l'identité, pour la conformité de
                        Vos informations lors de la création de Votre compte, pour la fourniture d'infrastructures
                        techniques et l'hébergement de systèmes, pour la gestion de la relation client, pour la
                        fourniture d'un service d'assistance à Vous, pour la réalisation d'enquêtes et d'études. LYBER
                        s'assure que ces prestataires présentent des garanties suffisantes pour l'exécution de la
                        mission et respectent les lois et règlements en vigueur.
                    </p>
                    <p>
                        En outre, le cas échéant, LYBER peut partager Vos Données Personnelles avec les
                        tribunaux compétents et toute autre autorité gouvernementale et/ou publique dûment habilitée
                        demandant l'accès à Vos Données, dans la mesure où cela est légalement autorisé.
                    </p>
                    <p>
                        Le cas échéant, par exemple en cas d'action en justice, LYBER peut partager Vos
                        Données Personnelles avec des praticiens de professions réglementées tels que des avocats, des
                        notaires et des auditeurs demandant l'accès à Vos Données, dans la mesure où cela est légalement
                        autorisé.
                    </p>
                    <p>
                        En tout état de cause, LYBER communiquera vos Données Personnelles aux destinataires
                        susmentionnés sur la base du strict besoin d'en connaître et uniquement dans la mesure
                        nécessaire à la réalisation des finalités de traitement dûment identifiées.
                    </p>

                    <br />
                    <h3>5. Sécurité</h3>
                    <br />

                    <p>
                        Nous veillons à prendre toutes les mesures techniques et d'organisation de sécurité nécessaires
                        afin de prévenir et de minimiser les risques liés à la fourniture de vos données personnelles. Ces
                        mesures comprennent, le cas échéant, l'utilisation de pare-feu, l'installation de serveur
                        sécurisé, la mise en œuvre de systèmes et de processus de gestion des droits d'accès appropriés,
                        une sélection rigoureuse des outils et tout autre mesure nécessaire pour assurer une protection
                        appropriée de vos données personnelles contre toute utilisation non autorisée ou divulgation. En
                        cas de transactions en ligne, nous utiliserons les mesures de sécurité standards, tels que celle
                        disponible via Secure Sockets Layer (SSL), pour protéger la confidentialité et la sécurité des
                        transactions en ligne.
                    </p>
                    <p>
                        Bien que le Site LYBER soit sécurisé, cependant comme tout site, des failles et des erreurs de
                        sécurité peuvent se produire. Vous devez rester vigilant et prendre en compte que le "Phishing"
                        ou hameçonnage est une pratique courante tout comme le piratage de site web ou l'existence de
                        faux sites web, sans que nous puissions en être responsables.
                    </p>

                    <br />
                    <h3>6. Transfert des données hors ue</h3>
                    <br />

                    <p>
                        Les Données à caractère personnel peuvent être traitées en dehors du territoire de l'Union
                        européenne (UE). Dans cette situation, LYBER prend toutes les précautions nécessaires et
                        s'assure alternativement ou cumulativement que (i) une décision d'adéquation a été prise par la
                        Commission européenne concernant le pays de destination au regard de l'article 45 du RGPD ; (ii)
                        des clauses contractuelles adoptées par la Commission européenne ou l'autorité de contrôle ont
                        été signées avec le destinataire ; (iii) le destinataire adhère à un code de conduite ou à un
                        mécanisme de certification approuvé.
                    </p>

                    <br />
                    <h3>7. Vos droits</h3>
                    <br />

                    <p>
                        En tant que personne concernée, Vous bénéficiez de différents droits concernant le traitement de
                        vos Données Personnelles. Ceux-ci sont les suivants :
                    </p>

                    <Table hover striped className="roundedTable mb-5">
                        <thead>
                            <tr>
                                <th className="purple-light text-center" colSpan="3">Vos droits</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            <tr>
                                <td className="light-black">
                                    <b>Droit de rectification</b>
                                </td>
                                <td className="light-black">
                                    Vous avez le droit de rectifier Vos Données lorsqu'elles sont inexactes ou incomplètes.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-black">
                                    <b>Droit d'accès</b>
                                </td>
                                <td className="light-black">
                                    Vous avez le droit de demander à LYBER une copie des Données associées à Votre compte.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-black">
                                    <b>Droit à l'effacement</b>
                                </td>
                                <td className="light-black">
                                    Vous pouvez obtenir l'effacement de Vos Données en clôturant Votre compte. Toutefois,
                                    cet effacement n'est pas possible si LYBER est tenu de conserver les Données pour se
                                    conformer à ses obligations légales (LCB-FT) ni si les Données sont nécessaires à la
                                    constatation, l'exercice ou la défense de droits en justice.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-black">
                                    <b>Droit à la portabilité</b>
                                </td>
                                <td className="light-black">
                                    Vous pouvez demander une copie de Vos Données afin de les transmettre à un autre
                                    prestataire de services.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-black">
                                    <b>Droit à la restriction / limitation du traitement</b>
                                </td>
                                <td className="light-black">
                                    Vous pouvez demander à LYBER la restriction / limitation du traitement de vos données.
                                    Vous pouvez également donner des directives concernant l'utilisation de vos Données
                                    après votre décès.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-black">
                                    <b>Droit d'opposition</b>
                                </td>
                                <td className="light-black">
                                    Vous pouvez vous opposer au traitement de vos Données par LYBER et retirer votre
                                    consentement.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-black">
                                    <b>Plainte</b>
                                </td>
                                <td className="light-black">
                                    Vous avez le droit de déposer une plainte auprès de la Commission nationale de
                                    l'informatique et des libertés (CNIL), qui est l'autorité de contrôle compétente.
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    <p>
                        Pour exercer vos droits ou pour toute question sur la protection des Données Personnelles, Vous
                        devez faire une demande accompagnée d'un justificatif d'identité par courrier électronique
                        adressé à LYBER - 15 rue des Halles 75001 Paris, France ou par courriel à{" "}
                        <a href="mailto:dpo@lyber.com">dpo@lyber.com</a>.
                    </p>
                    <p>
                        LYBER s'efforcera de répondre sans retard excessif et au plus tard dans un délai d'un (1) mois
                        après la réception de la demande. LYBER se réserve le droit d'étendre ce délai à trois (3) mois
                        dans le cas d'une demande complexe.
                    </p>
                    <p>
                        LYBER s'engage à protéger vos Données Personnelles et à respecter le cadre légal applicable en
                        matière de protection des données.
                    </p>
                    <p>
                        C'est la raison pour laquelle LYBER requiert votre assistance à cette fin. Ainsi, Vous vous
                        engagez à informer LYBER si les Données personnelles que Vous avez partagées deviennent
                        obsolètes ou inexactes.
                    </p>
                    <p>
                        En outre, dans le cas où Vous fournissez à LYBER des informations permettant d'identifier
                        directement ou indirectement toute autre personne physique (a titre d'exemple; vous avez envoyé
                        de la crypto monnaie à une personne tierce dont le compte n'est pas connu par LYBER), vous
                        déclarez et garantissez avant de partager les informations avec LYBER, que ces autres personnes
                        ont eu la connaissance de la présente politique de confidentialité et ont consenti aux
                        traitements de leurs données personnelles.
                    </p>

                    <br />
                    <h3>8. Détails de contact delegue protection des données (DPO)</h3>
                    <br />

                    <p>
                        LYBER SAS a nommé un DPO, si vous avez des questions relatives à l'utilisation par LYBER de vos
                        données personnelles tel que décrit dans cette politique de confidentialité, ou si vous
                        souhaitez présenter une requête au titre de vos droits définis ci-dessus, veuillez contacter
                        notre DPO.
                    </p>
                    <p>
                        Adresse e-mail : <a href="mailto:dpo@lyber.com">dpo@lyber.com</a>
                    </p>
                    <p>Adresse postale : 15 rue des Halles, 75001 PARIS.</p>
                    <p>
                        Si vous n'êtes pas satisfait de la manière dont nous traitons vos données personnelles pour
                        quelque raison que ce soit, nous vous encourageons à contacter notre DPO pour évoquer votre
                        préoccupation. Notre DPO s'assurera que votre question est traitée dans les meilleurs délais par
                        les parties concernées. Vous avez également le droit de présenter une réclamation à l'autorité
                        locale de protection des données ou à une autorité de contrôle compétente.
                    </p>

                    <br />
                    <h3>9. Modifications des conditions</h3>
                    <br />

                    <p>
                        Ces Conditions remplacent toutes informations précédemment fournies au sujet de nos pratiques
                        sur les données personnelles. Elles n'ont pas vocation à remplacer tout autre accord ou
                        conditions que vous pourriez avoir avec nous dans le cadre d'un accord particulier.
                    </p>
                    <p>Nous nous réservons le droit de modifier ces Conditions à tout moment.</p>
                </div>
            </section>
        </>
    );
};
