import { Link, NavLink, useLocation } from "react-router-dom";
import Bars from "../../../assets/images/home/toggle-new.svg";
import Logo from "../../../assets/images/home/logo.svg";
import Cross from "../../../assets/images/home/cross-new.svg";
import TwitterBlack from "../../../assets/images/home/twitter_black.svg";
import TelegramBlack from "../../../assets/images/home/telegram_black.svg";
import England from "../../../assets/images/home/england.png";
import France from "../../../assets/images/home/france.png";
import { useContext, useState, useEffect, useRef } from "react";
import { GlobalContext } from "../../../context/Provider";
import language_contant from "../../../utils/language_contant";
import loginSuccess from "../../../context/actions/auth/loginSuccess";
import QRCode from 'qrcode.react';
import { Button, Modal } from "react-bootstrap";

// import { toast } from "react-toastify";
const Header = () => {
    const location = useLocation();
    const { authDispatch, authState } = useContext(GlobalContext);
    language_contant.setLang(authState.lang);
    const lang = authState.lang;
    const handleLanguageChange = (lang) => {
        loginSuccess({ lang })(authDispatch);
    };

    const [showQRCode, setShowQRCode] = useState(false);

    const getMobileType = () => {
        if (/Android/i.test(navigator.userAgent)) {
            return 'Android';
        }
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            return 'iOS';
        }
        return null;
    };

    const handleDownloadButtonClick = () => {
        const mobileType = getMobileType();
        if (mobileType === 'Android') {
            // Redirect to Google Play Store for Android users
            window.location.href = "https://play.google.com/store/apps/details?id=com.Lyber";
            //alert(language_contant.Home.comingsoon())
        } else if (mobileType === 'iOS') {
            // Redirect to Apple App Store for iOS users
            window.location.href = "https://apps.apple.com/fr/app/lyber/id1609050369";
        } else {
            // Show QR Code for desktop users or other devices
            setShowQRCode(true);
        }
    };

    const handleCloseQRcode = () => {
        setShowQRCode(false)
    }

    return (
        <header className={`header ${location.pathname === "/lyb" ? "transparent" : ""}`}>
            <div className="container">
                <nav className="navbar navbar-expand-lg layber-heading">
                    <a className="navbar-brand navbar-logo" href="/">
                        <img src={Logo} alt="logo" />
                    </a>
                    <div className="d-flex align-items-center">
                        <button
                            className="navbar-toggler togle-wraper btn-icon"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <img src={Bars} className="bars" />
                            <img src={Cross} className="cross" />
                        </button>
                        <div className="nav-item d-lg-none ms-3">
                            <button className="btn-icon me-3">
                                <img
                                    src={France}
                                    onClick={() => handleLanguageChange("other")}
                                    className="img-fluid flag"
                                />
                            </button>
                            <button className="btn-icon">
                                <img
                                    src={England}
                                    onClick={() => handleLanguageChange("ENGLISH")}
                                    className="img-fluid flag"
                                />
                            </button>
                        </div>
                    </div>
                    <div className="collapse navbar-collapse heder-li" id="navbarSupportedContent">
                        <ul className="navbar-nav header-menu ms-auto mb-2 mb-lg-0 align-items-lg-center">
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === "/faq" ? "active" : ""}`} to="/faq">
                                    <b>{language_contant.Home.haveQuestion()}</b>
                                </Link>
                            </li>
                            <li className="nav-item ms-3 d-lg-block d-none">
                                <a href="/article">
                                    <button className="btn btn-border light-voilet">
                                        <b>{language_contant.Header.news()}</b>
                                    </button>
                                </a>
                            </li>
                            <li className="nav-item ms-3 d-lg-block d-none">
                                <button className="btn btn-primary" onClick={handleDownloadButtonClick}>
                                    <b>{language_contant.Home.download()}</b>
                                </button>
                            </li>

                            {/* <li className="nav-item ms-3 d-lg-block d-none">
                <div class="dropdown lyber-options">
                  <button
                    className="btn btn-primary"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {location.pathname === "/article" ? "Article" : location.pathname === "/newsletter" ? "News letter" : "Lyber updates"}
                    <i class="fa-solid fa-chevron-down ms-3"></i>
                  </button>
                  <ul class="dropdown-menu py-0 border-0 shadow-lg mt-2 rounded-6">
                    <li>
                      <Link to="/article" class="dropdown-item py-2">
                        Articles
                      </Link>
                    </li>
                    <li>
                      <Link to="/newsletter" class="dropdown-item py-2">
                        Newsletters
                      </Link>
                    </li>
                  </ul>
                </div>
              </li> */}
                            <li className={`${location.pathname === "/article" ? "d-none" : "nav-item ms-4 ps-3 d-lg-block d-none"}`}>
                                <button className="btn-icon me-3">
                                    <img
                                        src={France}
                                        onClick={() => handleLanguageChange("other")}
                                        className="img-fluid flag"
                                    />
                                </button>
                                <button className="btn-icon">
                                    <img
                                        src={England}
                                        onClick={() => handleLanguageChange("ENGLISH")}
                                        className="img-fluid flag"
                                    />
                                </button>
                            </li>

                            <div className="d-lg-none mobile-text">
                                <p className="text-dark-purple fw-semi-bold">{lang === "other" ? "Visitez notre FAQ" : "Visit our FAQ page"}</p>
                                <a href="/article">
                                    <button className="btn btn-border light-voilet w-100 mb-2">
                                        <b>{language_contant.Header.news()}</b>
                                    </button>
                                </a>
                                <button className="btn btn-primary w-100 mb-2" onClick={handleDownloadButtonClick}>
                                    {language_contant.Home.download()}
                                </button>
                                <div className="form-group mb-2">
                                    <label className="mb-1">{lang === "other" ? "Je m’inscris" : "Subscribe"}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={lang === "other" ? "Email" : "Enter your email"}
                                    />
                                </div>
                                <div className="form-group ">
                                    <button className="btn btn-primary w-100 ">
                                        <b>{lang === "other" ? "Je m’inscris" : "Subscribe"}</b>
                                    </button>
                                </div>
                                <p className="or-content my-3 text-center gray">
                                    <span className="text-or">{lang === "other" ? "OU" : "OR"}</span>
                                </p>
                                <div className="">
                                    <a href="https://twitter.com/LyberOfficial" target="_blank" className="w-100">
                                        <button className="btn btn-gray w-100">
                                            <img src={TwitterBlack} className="me-2" />
                                            {lang == "other" ? "Twitter" : "Follow us on Twitter"}
                                        </button>
                                    </a>
                                    <a href="https://t.me/LyberOfficial" target="_blank" className="w-100 mt-2">
                                        <button className="btn btn-gray w-100 mt-2">
                                            <img src={TelegramBlack} className="me-2" />
                                            {lang === "other" ? "Telegram" : "Join Telegram"}
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </ul>
                    </div>
                </nav>
            </div>
            <Modal
                show={showQRCode}
                onHide={handleCloseQRcode}
                backdrop="static"
                keyboard={false}
                centered
                className="confirm-box-modal"
            >
                <Modal.Header className="d-flex justify-content-center">
                    <Modal.Title>{language_contant.Home.download()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center gap-5">
                        <div className="my-3 text-center">
                            <div className="d-flex flex-column gap-2">
                                <QRCode value="https://apps.apple.com/fr/app/lyber/id1609050369" />
                                <div>
                                    <span>iOS</span>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-apple" >
                                            <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3 text-center d-flex align-items-end">
                            <div className="d-flex flex-column gap-2">
                                <QRCode value="https://play.google.com/store/apps/details?id=com.Lyber" />
                                {/* <span style={{ marginBottom: "50px" }}>{language_contant.Home.comingsoon()}</span> */}
                                <div>
                                    <span>Android</span>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-android2" viewBox="0 0 16 16">
                                            <path d="m10.213 1.471.691-1.26q.069-.124-.048-.192-.128-.057-.195.058l-.7 1.27A4.8 4.8 0 0 0 8.005.941q-1.032 0-1.956.404l-.7-1.27Q5.281-.037 5.154.02q-.117.069-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.7 3.7 0 0 0 3.5 5.02h9q0-1.125-.623-2.072a4.27 4.27 0 0 0-1.664-1.476ZM6.22 3.303a.37.37 0 0 1-.267.11.35.35 0 0 1-.263-.11.37.37 0 0 1-.107-.264.37.37 0 0 1 .107-.265.35.35 0 0 1 .263-.11q.155 0 .267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264m4.101 0a.35.35 0 0 1-.262.11.37.37 0 0 1-.268-.11.36.36 0 0 1-.112-.264q0-.154.112-.265a.37.37 0 0 1 .268-.11q.155 0 .262.11a.37.37 0 0 1 .107.265q0 .153-.107.264M3.5 11.77q0 .441.311.75.311.306.76.307h.758l.01 2.182q0 .414.292.703a.96.96 0 0 0 .7.288.97.97 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182q0 .414.292.703a.97.97 0 0 0 .71.288.97.97 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76q.436 0 .749-.308.31-.307.311-.75V5.365h-9zm10.495-6.587a.98.98 0 0 0-.702.278.9.9 0 0 0-.293.685v4.063q0 .406.293.69a.97.97 0 0 0 .702.284q.42 0 .712-.284a.92.92 0 0 0 .293-.69V6.146a.9.9 0 0 0-.293-.685 1 1 0 0 0-.712-.278m-12.702.283a1 1 0 0 1 .712-.283q.41 0 .702.283a.9.9 0 0 1 .293.68v4.063a.93.93 0 0 1-.288.69.97.97 0 0 1-.707.284 1 1 0 0 1-.712-.284.92.92 0 0 1-.293-.69V6.146q0-.396.293-.68" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button onClick={handleCloseQRcode} className="px-6 confirm-btn">
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </header>
    );
};

export default Header;
