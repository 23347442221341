import Telegram from "../../assets/images/home/telegram.svg";
import Twitter from "../../assets/images/home/twitter.svg";
import { useState, useContext, useEffect } from "react";
import { validateEmail } from "../../utils/validation_regex";
import { API_ROOT } from "../../utils/henceforthApi";
import { GlobalContext } from "../../context/Provider";
import language_contant from "../../utils/language_contant";
import { Button, Modal, Table } from "react-bootstrap";
import QuestionMark from "../../assets/images/Subtract.svg";
import { Spinner } from "../BootstrapCompo";
import TickImage from "../../assets/images/successful.svg";
import axios from "axios";
import ReactPixel from 'react-facebook-pixel';
import { PricingPolicyFR } from "../pricingPolicy/index.fr";
import { PricingPolicyEN } from "../pricingPolicy/index.en";

const Faq = () => {
    const { authState, authDispatch } = useContext(GlobalContext);

    if (authState.areCookiesAccepted === true) {
        if (authState.metaSliderState === true)
            ReactPixel.pageView()
        if (authState.googleSliderState === true)
            window.gtag('event', 'page_view');
    }

    language_contant.setLang(authState.lang);
    const lang = authState.lang;
    const [inputState, setInputState] = useState("");
    const [language, setLanguage] = useState({
        data: [],
    });
    const [appTabData, setAppTabData] = useState({
        data: [],
    });
    const [tokenTab, setTokenTab] = useState({
        data: [],
    });

    //  ---------------------------modal-------------------------//

    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showSuccessful, setShowSuccessful] = useState(false);
    const [showInvalid, setShowInvalid] = useState(false);
    const [showAlreadyId, setShowAlreadyId] = useState(false);

    const handleClose = () => {
        setShowConfirm(false);
        setShowSuccessful(false);
    };
    const handleCloseAdreadyId = () => setShowAlreadyId(false);
    const handleCloseInvalid = () => setShowInvalid(false);
    const handleShow = () => setShowConfirm(true);

    // ---------------------modal------------------

    const [state, setState] = useState({
        email: "",
        language: "ENGLISH",
        loading: false,
    });

    const checkEmail = async () => {
        const { email, loading } = state;
        if (validateEmail(email)) {
            handleShow(true);
        } else {
            setShowInvalid(true);
        }
    };

    const handleConfirm = async () => {
        handleShow(true);
        await preRegister();
    };

    // ----------------------subscribe-------------------///////

    const preRegister = async () => {
        const { email, loading } = state;
        if (validateEmail(email)) {
            setState({
                ...state,
                loading: true,
            });
            try {
                setLoading(true);
                let items = {
                    email: state.email,
                    language: authState.lang === "other" ? "FRENCH" : "ENGLISH",
                };
                // debugger;
                let res = await axios.post(`${API_ROOT}/api/email`, { email: state.email });
                setState({
                    ...state,
                    loading: false,
                    email: "",
                    language: "",
                    res,
                });
                setShowSuccessful(true);
                // alert(res.data.message);
            } catch (error) {
                setShowConfirm(false);
                setShowAlreadyId(true);
                setState({
                    ...state,
                    loading: false,
                    // alert(error.response.body.message);
                });
            } finally {
                setLoading(false);
            }
        } else {
            setShowInvalid(true);
            // alert("Invalid email ID");
        }
    };
    const faqList = [
        {
            q: "What is Lyber?",
            a: (
                <p className="mb-0">
                    Lyber is the first French Web 3.0 App specializing in digital assets, allowing its users to invest,
                    send and spend their assets easily.
                </p>
            ),
        },
        {
            q: "Is Lyber regulated?",
            a: <p className="mb-0">Lyber complies with the laws and regulations applicable to crypto-currencies on French territory.</p>,
        },
        {
            q: "Why should I use Lyber?",
            a: (
                <p className="mb-0">
                    Lyber is the easiest way to acquire digital assets in France. Regulated by the competent French authorities, Lyber offers you the highest level of security on the market.
                </p>
            ),
        },

        {
            q: "What are the supported locations?",
            a: (
                <p className="mb-0">
                    We currently support countries from the European Union and worldwide.
                    However, Lyber does not accept or provide services to personal accounts
                    of residents of countries under restrictive measures.
                </p>
            ),
        },
        {
            q: "What differentiates Lyber from applications like Revolut/Etoro?",
            a: (
                <p className="mb-0">
                    Lyber is a platfom for beginners that specializes in digital asset services. Etoro and Revolut cater to
                    a more experienced audience and their offering is more geared toward traditional banking services.
                </p>
            ),
        },
        {
            q: "What differentiates Lyber from exchanges like Crypto.com/Binance?",
            a: (
                <p className="mb-0">
                    Lyber is not a trading platform, but rather a user-friendly, simple, secure way to acquire digital
                    assets. Crypto.com and Binance are typically reserved for professional traders.
                </p>
            ),
        },
        {
            q: "Why did you decide to launch Lyber in France?",
            a: (
                <p className="mb-0">
                    France provides one of the clearest digital asset regulatory frameworks in Europe.
                </p>
            ),
        },
        {
            q: "Where is Lyber headquartered?",
            a: <p className="mb-0">We are based in Paris, France.</p>,
        },
    ];
    const otherFaqList = [
        {
            q: "Qu'est-ce que Lyber ?",
            a: (
                <p className="mb-0">
                    {" "}
                    Lyber est la première application Web 3.0 française spécialisée dans les actifs numériques,
                    permettant à ses utilisateurs d'investir, d'envoyer et de dépenser leurs actifs facilement.
                </p>
            ),
        },
        {
            q: "Lyber est-elle réglementée ?",
            a: <p className="mb-0">Lyber se conforme aux lois et réglementations applicables sur les crypto-monnaies sur le territoire français.</p>,
        },

        {
            q: "Pourquoi utiliser Lyber ?",
            a: (
                <p className="mb-0">
                    Lyber est le moyen le plus simple d’acquérir des actifs numériques en France. Étant réglementé par les autorités compétentes françaises, Lyber vous propose le niveau de sécurité le plus élevé du marché.
                </p>
            ),
        },
        {
            q: "Quels sont les pays, états et territoires que vous prenez en charge ?",
            a: (
                <p className="mb-0">
                    Nous prenons actuellement en charge les pays de l'Union européenne et du monde entier.
                    Cependant, Lyber n’accepte pas et ne fournit pas de services aux comptes personnels
                    des résidents de pays sous mesures restrictives.{" "}
                </p>
            ),
        },
        // {
        //     q: "Avez-vous une license pour les cryptomonnaies ?",
        //     a: <p className="mb-0">Nous avons demandé la licence Prestataire de Services sur Actifs-Numériques (PSAN) délivrée par l( 'AMF ) (Autorité des Marchés Financiers française).</p>
        // },
        {
            q: "Qu'est-ce qui différencie Lyber des applications telles que Revolut / Etoro ?",
            a: (
                <p className="mb-0">
                    Lyber est une plateforme pour les débutants, spécialisée dans la fourniture de services d'actifs numériques.
                    Etoro et Revolut sont des plateformes s'adressant à un public plus averti et sont spécialisées dans la
                    fourniture de services bancaires classiques.
                </p>
            ),
        },
        {
            q: "Qu'est-ce qui différencie Lyber des plateformes telles que Crypto.com / Binance ?",
            a: (
                <p className="mb-0">
                    Lyber n'est pas une plateforme de trading, mais plutôt un moyen convivial, simple et sécurisé
                    d'acquérir des crypto-actifs. Crypto.com et Binance sont généralement réservés aux traders
                    professionnels.
                </p>
            ),
        },
        {
            q: "Pourquoi avez-vous décidé de lancer Lyber en France ?",
            a: (
                <p className="mb-0">
                    La France offre l'un des cadres réglementaires les plus clairs en matière de crypto-actifs en
                    Europe.
                </p>
            ),
        },
        {
            q: "Où se trouve le siège de Lyber ?",
            a: <p className="mb-0">Nous sommes basés à Paris, en France.</p>,
        },
    ];

    const appTab = [
        {
            q: "Why is Lyber being launched as a mobile application first?",
            a: (
                <p>
                    Society, particularly Millennials, are relying on web applications less and less. Most successful
                    crypto & banking companies are mobile applications, so this was a natural choice for us.
                </p>
            ),
        },
        {
            q: "Where can I download Lyber?",
            a: <p>Lyber will be downloadable from both the Apple Store and Google Play.</p>,
        },
        {
            q: "Can I use Lyber to send crypto to friends?",
            a: (
                <p>
                    Yes. You can send digital assets to your friends directly from the Lyber App. Simply enter an email
                    or a phone number instead of the complex crypto wallet addresses you are used to.
                </p>
            ),
        },
        {
            q: "How much are your fees?",
            a: <p>We charge 1% on each buy&sell transaction.</p>,
        },
        {
            q: "Which cryptocurrencies do you support?",
            a: (
                <p>
                    Bitcoin (BTC), Cardano (ADA), Litecoin (LTC), Ether (ETH), Dogecoin (Doge),
                    Polygon (MATIC), Binance Coin (BNB), Ripple (XRP), Pepe (PEPE), ChainLink (LINK),
                    Polkadot (DOT), Solana (SOL), Fantom (FTM), Shiba (SHIB), Avalanche (AVAX),
                    Atom (ATOM), Stellar (XLM), ApeCoin (APE), MultiversX (EGLD), Tether (USDT), Circle (USDC).
                </p>
            ),
        },
        {
            q: "What chains will you support on Lyber?",
            a: (
                <p>
                    For now we support Ethereum, Solana, Avalanche and Binance Smart Chains. We aim to support
                    additional chains in the coming months.
                </p>
            ),
        },
        {
            q: "Can users custody their digital assets on Lyber?",
            a: <p>Yes. You can custody all digital assets available on the platform.</p>,
        },
    ];
    const otherAppTab = [
        {
            q: "Pourquoi Lyber est-il d'abord lancé en tant qu'application mobile ?",
            a: (
                <p>
                    La société, en particulier les Millennials, se fie de moins en moins aux applications web. La
                    plupart des entreprises crypto et banque qui réussissent sont des applications mobiles, c'était donc
                    un choix naturel pour nous.
                </p>
            ),
        },
        {
            q: "Où télécharger Lyber ?",
            a: <p>Lyber sera téléchargeable à la fois sur l'Apple Store et sur Google Play.</p>,
        },
        {
            q: "Puis-je envoyer des crypto-actifs à mes amis avec Lyber ?",
            a: (
                <p>
                    Oui, vous pouvez envoyer des crypto-actifs à vos amis directement depuis l'application Lyber. Il
                    suffit d'un email ou d’un numéro de téléphone pour transférer vos fonds.
                </p>
            ),
        },
        {
            q: "Quels sont les frais que vous facturez ?",
            a: <p>Nous facturons 1% sur chaque transaction d'achat et de vente.</p>,
        },
        {
            q: "Quelles sont les crypto-monnaies que vous prenez en charge ?",
            a: (
                <p>
                    Bitcoin (BTC), Cardano (ADA), Litecoin (LTC), Ether (ETH), Dogecoin (Doge),
                    Polygon (MATIC), Binance Coin (BNB), Ripple (XRP), Pepe (PEPE), ChainLink (LINK),
                    Polkadot (DOT), Solana (SOL), Fantom (FTM), Shiba (SHIB), Avalanche (AVAX),
                    Atom (ATOM), Stellar (XLM), ApeCoin (APE), MultiversX (EGLD), Tether (USDT), Circle(USDC).
                </p>
            ),
        },
        {
            q: "Quelles blockchains supportez-vous sur Lyber ?",
            a: (
                <p>
                    Pour l'instant, nous prenons en charge les blockchain Ethereum, Solana, Avalanche et Binance. Nous
                    avons l'intention de prendre en charge d'autres blockchain dans les mois à venir.
                </p>
            ),
        },
        {
            q: "Les utilisateurs peuvent-ils conserver leurs actifs numériques sur Lyber ?",
            a: <p>Oui, vous pouvez conserver tous les crypto-actifs disponibles sur la plateforme.</p>,
        },
    ];
    const tokendata = [
        {
            q: "What is the LYB token?",
            a: (
                <p>
                    Please visit our website{" "}
                    <a href="https://lyber.com/lyb" target="_blank">
                        https://lyber.com/lyb
                    </a>
                    .
                </p>
            ),
        },
        {
            q: "Will there be an IDO?",
            a: <p>Yes. We will reveal further details on this later.</p>,
        },
        {
            q: "When is the IDO?",
            a: <p>Q4 2023.</p>,
        },
        {
            q: "When is the TGE?",
            a: <p>Shortly after the IDO, in Q4 2023.</p>,
        },
        {
            q: "What is the Market Cap at TGE?",
            a: <p>$680,000. Please note that no tokens will be unlocked during the first 6 months after TGE.</p>,
        },
    ];
    const otherTokenTabData = [
        {
            q: "Qu'est-ce que le token LYB ?",
            a: (
                <p>
                    Veuillez consulter notre site web{" "}
                    <a href="https://lyber.com/lyb">
                        https://lyber.com/lyb
                    </a>
                    .
                </p>
            ),
        },
        {
            q: "Y-aura-t-il une IDO ?",
            a: <p>Oui. Nous vous révèlerons plus de détails là dessus plus tard.</p>,
        },
        {
            q: "Quand aura lieu l’IDO ?",
            a: <p>Q4 2023.</p>,
        },
        {
            q: "Quand aura lieu le TGE ?",
            a: <p>Juste après l'IDO, en Q4 2023.</p>,
        },
        {
            q: "Quelle sera la capitalisation boursière au TGE ?",
            a: (
                <p>
                    $680,000. Veuillez noter qu'aucun token ne sera débloqué au cours des 6 premiers mois après le TGE.
                </p>
            ),
        },
    ];
    useEffect(() => {
        let data;
        if (inputState) {
            lang === "other"
                ? (data = otherFaqList.filter((res) =>
                    String(res.q).toLocaleLowerCase().includes(inputState.toLocaleLowerCase()),
                ))
                : (data = faqList.filter((res) =>
                    String(res.q).toLocaleLowerCase().includes(inputState.toLocaleLowerCase()),
                ));
            setLanguage({
                ...language,
                data,
            });
        } else {
            lang === "other"
                ? setLanguage({
                    ...language,
                    data: otherFaqList,
                })
                : setLanguage({
                    ...language,
                    data: faqList,
                });
        }
    }, [inputState, lang]);
    useEffect(() => {
        let data;
        if (inputState) {
            lang === "other"
                ? (data = otherFaqList.filter((res) =>
                    String(res.q).toLocaleLowerCase().includes(inputState.toLocaleLowerCase()),
                ))
                : (data = faqList.filter((res) =>
                    String(res.q).toLocaleLowerCase().includes(inputState.toLocaleLowerCase()),
                ));
            setAppTabData({
                ...appTabData,
                data,
            });
        } else {
            lang == "other"
                ? setAppTabData({
                    ...appTabData,
                    data: otherAppTab,
                })
                : setAppTabData({
                    ...appTabData,
                    data: appTab,
                });
        }
    }, [inputState, lang]);
    useEffect(() => {
        let data;
        if (inputState) {
            lang == "other"
                ? (data = otherFaqList.filter((res) =>
                    String(res.q).toLocaleLowerCase().includes(inputState.toLocaleLowerCase()),
                ))
                : (data = faqList.filter((res) =>
                    String(res.q).toLocaleLowerCase().includes(inputState.toLocaleLowerCase()),
                ));
            setTokenTab({
                ...tokenTab,
                data,
            });
        } else {
            lang == "other"
                ? setTokenTab({
                    ...tokenTab,
                    data: otherTokenTabData,
                })
                : setTokenTab({
                    ...tokenTab,
                    data: tokendata,
                });
        }
    }, [inputState, lang]);
    return (
        <>
            <div className="faq-wrapper pt-md-5">
                <div className="container new">
                    <div className="row">
                        <div className="col-md-3">
                            <h2 className="main-title d-md-none">
                                {language_contant.Home.haveQuestion()}
                                <br /> <span className="text-gray">{language_contant.FaqPage.fullFaq()}</span>
                            </h2>

                            <ul
                                class="nav nav-pills mb-3 faq-sidebar scrollbar flex-nowrap"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li class="nav-item p-0" role="presentation">
                                    <button
                                        class="nav-link active text-nowrap"
                                        id="pills-product-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-product"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-product"
                                        aria-selected="true"
                                    >
                                        {lang === "other" ? "Produit" : "The product"}
                                    </button>
                                </li>
                                <li class="nav-item p-0" role="presentation">
                                    <button
                                        class="nav-link text-nowrap"
                                        id="pills-team-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-team"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-team"
                                        aria-selected="false"
                                    >
                                        {lang === "other" ? "L’équipe" : "The team"}
                                    </button>
                                </li>
                                <li class="nav-item p-0" role="presentation">
                                    <button
                                        class="nav-link text-nowrap"
                                        id="pills-license-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-license"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-license"
                                        aria-selected="false"
                                    >
                                        {lang === "other" ? "Nos licences" : "The licenses"}
                                    </button>
                                </li>
                                <li class="nav-item p-0" role="presentation">
                                    <button
                                        class="nav-link text-nowrap"
                                        id="pills-app-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-app"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-app"
                                        aria-selected="false"
                                    >
                                        {lang === "other" ? "App" : "The App"}
                                    </button>
                                </li>
                                <li class="nav-item p-0" role="presentation">
                                    <button
                                        class="nav-link text-nowrap"
                                        id="pills-token-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-token"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-token"
                                        aria-selected="false"
                                    >
                                        {lang === "other" ? "Token" : "The Token"}
                                    </button>
                                </li>
                                <li class="nav-item p-0" role="presentation">
                                    <button
                                        class="nav-link text-nowrap"
                                        id="pills-fees-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-fees"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-fees"
                                        aria-selected="false"
                                    >
                                        {lang === "other" ? "Frais" : "Fees"}
                                    </button>
                                </li>
                                <li class="nav-item p-0" role="presentation">
                                    <button
                                        class="nav-link text-nowrap"
                                        id="pills-others-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-others"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-others"
                                        aria-selected="false"
                                    >
                                        {lang === "other" ? "Autres" : "Others"}
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-9">
                            <h2 className="main-title d-md-block d-none">
                                {language_contant.Home.haveQuestion()}
                                <br /> <span className="text-gray">{language_contant.FaqPage.fullFaq()}</span>
                            </h2>
                            <div class="tab-content" id="pills-tabContent">
                                <div
                                    class="tab-pane fade show active my-md-5 py-md-4 faq-content"
                                    id="pills-product"
                                    role="tabpanel"
                                    aria-labelledby="pills-product-tab"
                                >
                                    <div className="">
                                        {language.data.map((res, index) => {
                                            return (
                                                <div className="mb-5">
                                                    <h3 className="ff-atyp fw-semi-bold">{res.q}</h3>
                                                    <p>{res.a}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade my-md-5 py-md-4 faq-content"
                                    id="pills-team"
                                    role="tabpanel"
                                    aria-labelledby="pills-team-tab"
                                >
                                    <div className="">
                                        <div className="mb-5">
                                            <h3 className="ff-atyp fw-semi-bold">
                                                {lang === "other"
                                                    ? "Qu'est-ce qui fait le succès de l'équipe de Lyber ?"
                                                    : "What makes the team of Lyber successful?"}
                                            </h3>
                                            <p>
                                                {lang === "other"
                                                    ? "Nous avons compilé un éventail d'expériences et d'expertises provenant d'institutions financières traditionnelles, de sociétés web3 et de marques connues. Les membres de notre équipe viennent de la Banque de France, Barclays, Luxembourg Stock Exchange, Ledger et Kering."
                                                    : "We have compiled an array of experiences and expertise from traditional finance institutions, web3 companies, and well known brands. Members of our team come from the Bank of France, Barclays, Luxembourg Stock Exchange, Ledger, and Kering."}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade my-md-5 py-md-4 faq-content"
                                    id="pills-license"
                                    role="tabpanel"
                                    aria-labelledby="pills-license-tab"
                                >
                                    <div className="">
                                        <div className="mb-5">
                                            <h3 className="ff-atyp fw-semi-bold">
                                                {lang === "other" ? "Lyber est-elle réglementé ?" : "Is Lyber regulated?"}
                                            </h3>
                                            <p>
                                                {lang === "other"
                                                    ? "Lyber se conforme aux lois et réglementations applicables sur les crypto-monnaies sur le territoire français."
                                                    : "Lyber complies with the laws and regulations applicable to crypto-currencies on French territory."}
                                            </p>
                                        </div>
                                        <div className="mb-5">
                                            <h3 className="ff-atyp fw-semi-bold">
                                                {lang === "other"
                                                    ? "Possédez-vous une licence pour les crypto-monnaies ?"
                                                    : "Have you acquired a crypto license in France?"}
                                            </h3>
                                            {lang === "other" ? (
                                                <p>
                                                    Oui. Nous avons obtenu la licence de{" "}
                                                    <a
                                                        href="https://www.amf-france.org/en/professionals/fintech/my-relations-amf/obtain-dasp-authorisation"
                                                        target="_blank"
                                                    >
                                                        Prestataires de Services sur Actifs Numériques
                                                    </a>{" "}
                                                    (PSAN). Numéro d'enregistrement{" "}
                                                    <a
                                                        href="https://www.amf-france.org/en/professionals/fintech/my-relations-amf/obtain-dasp-authorisation"
                                                        target="_blank"
                                                    >
                                                        #E2022-039.
                                                    </a>
                                                </p>
                                            ) : (
                                                <p>
                                                    Yes. We have obtained the{" "}
                                                    <a
                                                        href="https://www.amf-france.org/en/professionals/fintech/my-relations-amf/obtain-dasp-authorisation"
                                                        target="_blank"
                                                    >
                                                        Digital Asset Service Provider (DASP)
                                                    </a>{" "}
                                                    license. Registration number{" "}
                                                    <a
                                                        href="https://www.amf-france.org/en/professionals/fintech/my-relations-amf/obtain-dasp-authorisation"
                                                        target="_blank"
                                                    >
                                                        #E2022-039.
                                                    </a>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade my-md-5 py-md-4 faq-content"
                                    id="pills-app"
                                    role="tabpanel"
                                    aria-labelledby="pills-app-tab"
                                >
                                    <div className="">
                                        {appTabData.data.map((res, index) => {
                                            return (
                                                <div className="mb-5">
                                                    <h3 className="ff-atyp fw-semi-bold">{res.q}</h3>
                                                    <p>{res.a}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade my-md-5 py-md-4 faq-content"
                                    id="pills-token"
                                    role="tabpanel"
                                    aria-labelledby="pills-token-tab"
                                >
                                    <div className="">
                                        {tokenTab.data.map((res, index) => {
                                            return (
                                                <div className="mb-5">
                                                    <h3 className="ff-atyp fw-semi-bold">{res.q}</h3>
                                                    <p>{res.a}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade my-md-5 py-md-4 faq-content"
                                    id="pills-fees"
                                    role="tabpanel"
                                    aria-labelledby="pills-fees-tab"
                                >
                                    {lang === "other" ? (
                                        <div>
                                            <PricingPolicyFR />
                                        </div>
                                    ) : (
                                        <div>
                                            <PricingPolicyEN />
                                        </div>
                                    )}
                                </div>
                                <div
                                    class="tab-pane fade my-md-5 py-md-4 faq-content"
                                    id="pills-others"
                                    role="tabpanel"
                                    aria-labelledby="pills-others-tab"
                                >
                                    <div className="">
                                        <div className="mb-5">
                                            <h3 className="ff-atyp fw-semi-bold">
                                                {lang === "other"
                                                    ? "Je veux investir dans Lyber. Qui dois-je contacter ?"
                                                    : "I want to invest in Lyber. Who do I contact?"}
                                            </h3>
                                            {lang === "other" ? (
                                                <p>
                                                    Contactez-nous à l'adresse e-mail suivante{" "}
                                                    <a href="mailto:12K" target="_blank">
                                                        contact@lyber.com.
                                                    </a>
                                                </p>
                                            ) : (
                                                <p>
                                                    Please e-mail us at{" "}
                                                    <a href="mailto:contact@lyber.com" target="_blank">
                                                        contact@lyber.com.
                                                    </a>
                                                </p>
                                            )}
                                        </div>
                                        <div className="mb-5">
                                            <h3 className="ff-atyp fw-semi-bold" style={{ whiteSpace: "nowrap" }}>
                                                {lang === "other"
                                                    ? "Y a-t-il des offres d'emploi disponibles chez Lyber ?"
                                                    : "Are there any available job opportunities at Lyber?"}
                                            </h3>
                                            {lang === "other" ? (
                                                <p>
                                                    {/* Oui. Veuillez nous contacter à l'adresse{" "} */}
                                                    Contactez-nous à l'adresse e-mail suivante
                                                    <a href="mailto:contact@lyber.com"> contact@lyber.com.</a>
                                                </p>
                                            ) : (
                                                <p>
                                                    Please e-mail us at{" "}
                                                    <a href="mailto:contact@lyber.com">contact@lyber.com.</a>
                                                </p>
                                            )}
                                        </div>
                                        <div className="mb-5">
                                            <h3 className="ff-atyp fw-semi-bold">
                                                {lang === "other"
                                                    ? "Comment puis-je vous contacter ?"
                                                    : "How can I get in touch with you?"}
                                            </h3>
                                            {lang === "other" ? (
                                                // Veuillez envoyer un courriel à contact@lyber.com ou envoyez nous un message sur Telegram.
                                                <p>
                                                    {/* Veuillez envoyer un courriel à {" "} */}
                                                    Contactez-nous à l'adresse e-mail suivante{" "}
                                                    <a href="mailto:contact@lyber.com">contact@lyber.com.</a>{" "}
                                                    {/* ou envoyez nous un message sur{" "}
                          <a href="https://t.me/LyberOfficial " target="_blank">
                            Telegram
                          </a>
                          . */}
                                                </p>
                                            ) : (
                                                <p>
                                                    {/* Send us an email on contact@lyber.com or send us a message on our Telegram account. */}
                                                    Please e-mail us at{" "}
                                                    <a href="mailto:contact@lyber.com">contact@lyber.com.</a>{" "}
                                                    {/* or send us a message on our{" "} */}
                                                    {/* <a href="https://t.me/LyberOfficial " target="_blank">
                            Telegram{" "}
                          </a>
                          account. */}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="section-spacing faq-features pb-md-0" id="pre-register">
                {/* ------------------------------------------------- footer----------------------------------------      */}

                <div className="container">
                    <div className="newsletter newsletter-bg">
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 text-center-991px">{language_contant.Home.startToInvest()}</div>
                            <div className="col-lg-6 col-xl-6 d-flex align-items-center justify-center-991px">
                                <div className="pre-register-content">
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            checkEmail();
                                        }}
                                    >
                                        <div className="form-group d-flex align-items-center register-input">
                                            <input
                                                type=""
                                                className="form-control me-sm-2"
                                                placeholder={lang === "other" ? "Email" : "Enter your email"}
                                                onChange={(e) => setState({ ...state, email: e.target.value })}
                                                name="email"
                                                value={state.email}
                                                disabled={state.loading}
                                                required
                                            />
                                            <button className="btn btn-secondary text-nowrap">
                                                {language_contant.Home.preRegister()}
                                            </button>
                                        </div>
                                    </form>

                                    {/* <p className="or-content my-2 text-center">
                                        <span className="text-or">{lang === "other" ? "OU" : "OR"}</span>
                                    </p>
                                    <div className="d-flex align-items-center flex-sm-row flex-column socials">
                                        <a
                                            href="https://twitter.com/LyberOfficial"
                                            target="_blank"
                                            className="me-sm-1 w-50"
                                        >
                                            <button className="btn btn-light w-100">
                                                <img src={Twitter} className="icon" />
                                                {lang === "other" ? "Twitter" : "Follow us on Twitter"}
                                            </button>
                                        </a>
                                        <a
                                            href="https://t.me/LyberOfficial"
                                            // without rel="noreferrer"
                                            target="_blank"
                                            className="ms-sm-1 w-50 mt-sm-0 mt-2"
                                        >
                                            <button className="btn btn-light w-100">
                                                <img src={Telegram} className="icon" />
                                                {lang === "other" ? "Telegram" : "Join Telegram"}
                                            </button>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div>
                            {/* Confimed And Success Modal */}
                            <Modal
                                show={showConfirm}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}
                                centered
                                className="confirm-box-modal"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {showSuccessful
                                            ? lang === "other"
                                                ? "Merci de nous avoir rejoint !"
                                                : "Welcome at Lyber!"
                                            : lang === "other"
                                                ? "Confirmez votre inscription"
                                                : "Confirm your subscription"}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="d-flex align-items-center  my-3 px-4">
                                        <div>
                                            {showSuccessful ? (
                                                <img
                                                    style={{ height: "50px", width: "50px" }}
                                                    src={TickImage}
                                                    alt="logo"
                                                />
                                            ) : (
                                                <img
                                                    style={{ height: "50px", width: "50px" }}
                                                    src={QuestionMark}
                                                    alt="logo"
                                                />
                                            )}
                                        </div>
                                        <div>
                                            {showSuccessful ? (
                                                lang === "other" ? (
                                                    <div className="ms-3">
                                                        Vous êtes maintenant inscrit à la newsletter de Lyber. Vous
                                                        allez bientôt recevoir un email de confirmation.
                                                    </div>
                                                ) : (
                                                    <div className="ms-3">
                                                        You are now registered on Lyber’s newsletter. You should receive
                                                        a confirmation email soon.
                                                    </div>
                                                )
                                            ) : lang === "other" ? (
                                                <div className="ms-3">
                                                    En cliquant sur
                                                    <span className="text-theme"> “Oui” </span> vous acceptez de
                                                    recevoir des communiqués de la part de Lyber.
                                                </div>
                                            ) : (
                                                <div className="ms-3">
                                                    By clicking on <span className="text-theme">“Yes”</span> you agree
                                                    to receive communications from Lyber.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    {showSuccessful ? (
                                        <div>
                                            <Button onClick={handleClose} className="px-6 confirm-btn">
                                                Ok
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <Button onClick={handleClose} className="px-6 reject-btn">
                                                {lang === "other" ? "Non" : "No"}
                                            </Button>
                                            <Button
                                                onClick={handleConfirm}
                                                className="px-6 confirm-btn ms-3"
                                                disabled={loading}
                                            >
                                                {loading ? <Spinner /> : lang === "other" ? "Oui" : "Yes"}
                                            </Button>
                                        </div>
                                    )}
                                </Modal.Footer>
                            </Modal>
                            {/* Already Registerd Modal */}
                            <Modal
                                show={showAlreadyId}
                                onHide={handleCloseAdreadyId}
                                backdrop="static"
                                keyboard={false}
                                centered
                                className="confirm-box-modal"
                            >
                                <Modal.Header>
                                    <Modal.Title>
                                        {lang === "other" ? "Vous nous suivez déjà !" : "You’re already registered!"}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="text-center my-3 px-4">
                                        <div>
                                            {lang === "other"
                                                ? "L'adresse e-mail est déjà enregistrée. Veuillez utiliser une autre adresse e-mail."
                                                : "The email address is already registered. Please use another email address."}
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={handleCloseAdreadyId} className="px-6 confirm-btn">
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            {/* Invalid Modal */}
                            <Modal
                                show={showInvalid}
                                onHide={handleCloseAdreadyId}
                                backdrop="static"
                                keyboard={false}
                                centered
                                className="confirm-box-modal"
                            >
                                <Modal.Header>
                                    <Modal.Title> {lang === "other" ? "Non valide" : "Invalid"} </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="my-3 text-center">
                                        <div>
                                            {lang === "other"
                                                ? "Identifiant de messagerie invalide"
                                                : "Invalid Email Id."}
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={handleCloseInvalid} className="px-6 confirm-btn">
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Faq;
