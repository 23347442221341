import React, { useRef, useEffect, useState, useContext } from 'react';
import language_contant from "../../utils/language_contant";
import { validateEmail } from "../../utils/validation_regex";
import { GlobalContext } from "../../context/Provider";

import Telegram from "../../assets/images/home/telegram.svg";
import Twitter from "../../assets/images/home/twitter.svg";
import TickImage from "../../assets/images/successful.svg";
import { Spinner } from "../BootstrapCompo";
import QuestionMark from "../../assets/images/Subtract.svg";

import { Button, Modal } from "react-bootstrap";

import { API_ROOT } from "../../utils/henceforthApi";
import axios from "axios"

const Popup = ({ show, onClose }) => {
    const popupRef = useRef();
    const { authState, authDispatch } = useContext(GlobalContext);
    language_contant.setLang(authState.lang);
    const lang = authState.lang;



    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showSuccessful, setShowSuccessful] = useState(false);
    const [showInvalid, setShowInvalid] = useState(false);
    const [showAlreadyId, setShowAlreadyId] = useState(false);
    const [errorMessageFoot, setErrorMessageFoot] = useState("");

    const handleShow = () => setShowConfirm(true);
    const handleCloseAdreadyId = () => setShowAlreadyId(false);
    const handleCloseInvalid = () => setShowInvalid(false);

    const handleConfirm = async () => {
        handleShow(true);
        await preRegister();
    };
    const preRegister = async () => {
        const { email, loading } = state;
        if (validateEmail(email)) {
            setState({
                ...state,
                loading: true,
            });
            try {
                setLoading(true);
                let items = {
                    email: state.email,
                    language: authState.lang === "other" ? "FRENCH" : "ENGLISH",
                };
                // debugger;
                let res = await axios.post(`${API_ROOT}/api/email`, { email: state.email });
                setState({
                    ...state,
                    loading: false,
                    email: "",
                    language: "",
                    res,
                });
                setShowSuccessful(true);
                // alert(res.data.message);
            } catch (error) {
                setShowConfirm(false);
                setShowAlreadyId(true);

                // alert(error.response.body.message);
                setState({
                    ...state,
                    loading: false,
                });
            } finally {
                setLoading(false);
            }
        } else {
            setShowInvalid(true);
            // alert("Invalid email ID");
        }
    };

    const handleClose = () => {
        setShowConfirm(false);
        setShowSuccessful(false);
    };

    const [state, setState] = useState({
        email: "",
        language: "FRENCH",
        loading: false,
    });

    const handleClickOutside = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            onClose()
        }
    };

    const checkEmail = async () => {
        const { email, loading } = state;
        if (validateEmail(email)) {
            handleShow(true);
        } else {
            setShowInvalid(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!show && !showInvalid && !showConfirm && !showAlreadyId && !showSuccessful) {
        return null;
    }

    return (
        <div className="popup-overlay">
            <div className="popup-content" ref={popupRef}>
                <div className="close-btn" onClick={onClose}>
                    &times;
                </div>
                <div>
                    {/* Confimed And Success Modal */}
                    <Modal
                        show={showConfirm}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {showSuccessful
                                    ? lang == "other"
                                        ? "Merci de nous avoir rejoint !"
                                        : "Welcome at Lyber!"
                                    : lang == "other"
                                        ? "Confirmez votre inscription"
                                        : "Confirm your subscription"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="d-flex align-items-center  my-3 px-4">
                                <div>
                                    {showSuccessful ? (
                                        <img style={{ height: "50px", width: "50px" }} src={TickImage} alt="logo" />
                                    ) : (
                                        <img style={{ height: "50px", width: "50px" }} src={QuestionMark} alt="logo" />
                                    )}
                                </div>
                                <div>
                                    {showSuccessful ? (
                                        lang == "other" ? (
                                            <div className="ms-3">
                                                Vous êtes maintenant inscrit à la newsletter de Lyber. Vous allez
                                                bientôt recevoir un email de confirmation.
                                            </div>
                                        ) : (
                                            <div className="ms-3">
                                                You are now registered on Lyber’s newsletter. You should receive a
                                                confirmation email soon.
                                            </div>
                                        )
                                    ) : lang == "other" ? (
                                        <div className="ms-3">
                                            En cliquant sur
                                            <span className="text-theme"> “Oui” </span> vous acceptez de recevoir des
                                            communiqués de la part de Lyber.
                                        </div>
                                    ) : (
                                        <div className="ms-3">
                                            By clicking on <span className="text-theme">“Yes”</span> you agree to
                                            receive communications from Lyber.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {showSuccessful ? (
                                <div>
                                    <Button onClick={handleClose} className="px-6 confirm-btn">
                                        Ok
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <Button onClick={handleClose} className="px-6 reject-btn">
                                        {lang == "other" ? "Non" : "No"}
                                    </Button>
                                    <Button
                                        onClick={handleConfirm}
                                        className="px-6 confirm-btn ms-3"
                                        disabled={loading}
                                    >
                                        {loading ? <Spinner /> : lang == "other" ? "Oui" : "Yes"}
                                    </Button>
                                </div>
                            )}
                        </Modal.Footer>
                    </Modal>
                    {/* Already Registerd Modal */}
                    <Modal
                        show={showAlreadyId}
                        onHide={handleCloseAdreadyId}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {lang == "other" ? "Vous nous suivez déjà !" : "You’re already registered!"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="text-center my-3 px-4">
                                <div>
                                    {lang == "other"
                                        ? "L'adresse e-mail est déjà enregistrée. Veuillez utiliser une autre adresse e-mail."
                                        : "The email address is already registered. Please use another email address."}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseAdreadyId} className="px-6 confirm-btn">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* Invalid Modal */}
                    <Modal
                        show={showInvalid}
                        onHide={handleCloseAdreadyId}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="confirm-box-modal"
                    >
                        <Modal.Header>
                            <Modal.Title> {lang == "other" ? "Non valide" : "Invalid"} </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-3 text-center">
                                <div>
                                    {lang == "other" ? "Identifiant de messagerie invalide" : "Invalid Email Id."}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleCloseInvalid} className="px-6 confirm-btn">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <section
                    className="section-spacing features-wrapper"
                    style={{ padding: "81px 0px 81px 0px" }}
                    id="pre-register"
                >
                    <div className="container">
                        <div className="newsletter newsletter-bg">
                            <div className="row">
                                <div className="col-lg-6 col-xl-6 text-center-991px">
                                    <h2 className="white-title newsletter-text">
                                        {language_contant.Home.startToInvest()}{" "}
                                    </h2>
                                    {/* <h2 className="white-title newsletter-text">Start to invest with Lyber. <span className="text-light-purple">Pre-register or join us on socials</span> </h2> */}
                                </div>
                                <div className="col-lg-6 col-xl-6 d-flex align-items-center justify-center-991px" >
                                    <div className="pre-register-content">
                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                if (!state.email) {
                                                    setErrorMessageFoot(language_contant.Home.emailIsRequired());
                                                } else {
                                                    setErrorMessageFoot("");
                                                    checkEmail();
                                                }
                                            }}
                                        >
                                            <div className="form-group d-flex align-items-center register-input">
                                                <input
                                                    type=""
                                                    className="form-control me-sm-2"
                                                    placeholder={lang == "other" ? "Email" : "Enter your email"}
                                                    onChange={(e) => setState({ ...state, email: e.target.value })}
                                                    value={state.email}
                                                    disabled={state.loading}

                                                />
                                                <button className="btn btn-secondary text-nowrap newclass">
                                                    {language_contant.Home.preRegister()}
                                                </button>
                                            </div>
                                            {errorMessageFoot && <div className="error-message-foot">{errorMessageFoot}</div>}
                                        </form>

                                        {/* <p className="or-content my-2 text-center">
                                            <span className="text-or">{lang == "other" ? "OU" : "OR"}</span>
                                        </p>
                                        <div className="d-flex align-items-center flex-sm-row flex-column socials">
                                            <a
                                                href="https://twitter.com/LyberOfficial"
                                                target="_blank"
                                                className="me-sm-1 w-50"
                                            >
                                                <button className="btn btn-light w-100">
                                                    <img src={Twitter} className="icon" />
                                                    {lang == "other" ? "Twitter" : "Follow us on Twitter"}
                                                </button>
                                            </a>
                                            <a
                                                href="https://t.me/LyberOfficial"
                                                target="_blank"
                                                className="ms-sm-1 w-50 mt-sm-0 mt-2"
                                            >
                                                <button className="btn btn-light w-100">
                                                    <img src={Telegram} className="icon" />
                                                    {lang == "other" ? "Telegram" : "Join Telegram"}
                                                </button>
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div >
    );
};

export default Popup;